import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";

import { getAssessmentList } from "./../../actions/assessmentActions";
import { saveSelectedSubjects, clearErrors } from './../../actions/subjectActions';

import Loader from "../Loader";

const Assessment = ({ history }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [assessmentType, setAssessmentType] = useState("");
    const [assessmentName, setAssessmentName] = useState("");
    const [currenExamId, setCurrenExamId] = useState("");
    const [examType, setExamType] = useState("");
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [userId, setUserId] = useState("");

    const { user } = useSelector((state) => state.auth);
    const { assessments } = useSelector((state) => state.assessmentsList);
    const { eloading } = useSelector((state) => state.commonExams);
    const { error } = useSelector((state) => state.courseSubjects);

    useEffect(() => {
        if (localStorage.getItem("subjectMsg")) {
            alert.success(localStorage.getItem("subjectMsg"));
            localStorage.removeItem("subjectMsg");
        }
        dispatch(getAssessmentList());
        setAssessmentType(localStorage.getItem("assessmentId"));
        setAssessmentName(localStorage.getItem("assessmentName"));
        setExamType(localStorage.getItem("examType"));
        setSelectedSubjects(localStorage.getItem("subjects")?.split(","));
        setUserId(user._id);
    }, [dispatch, alert, user]);

    useEffect(() => {
        if (error) {
            dispatch(clearErrors());
            console.log(error.message || error.toString()); // Convert error to string
        }
    }, [dispatch, alert, error]);


    const onChange = (e) => {
        const assessmentSlug = e.target.dataset.slug;
        setAssessmentType(e.target.value);
        setAssessmentName(e.target.dataset.name);
        localStorage.setItem("assessmentName", assessmentSlug);
    };

    const submitHandler = (e) => {
        e.preventDefault();

        if (assessmentType) {
            localStorage.setItem("assessmentId", assessmentType);
            setCurrenExamId(localStorage.getItem("examId"));

            const examSlug = localStorage.getItem("examSlug");
            if (["post-utme", "jsce-bece", "wassce-waec-ssce"].includes(examSlug)) {
                dispatch(saveSelectedSubjects(
                    userId,
                    localStorage.getItem("examId"),
                    examType,
                    localStorage.getItem("categoryId"),
                    localStorage.getItem("courseId"),
                    assessmentType,
                    selectedSubjects
                ));
                history.push(`/registered-subjects`);
            } else {
                const slug = localStorage.getItem("assessmentName");
                if (slug === "mock") {
                    history.push(`/instructions`);
                } else {
                    dispatch(saveSelectedSubjects(
                        userId,
                        localStorage.getItem("examId"),
                        examType,
                        localStorage.getItem("categoryId"),
                        localStorage.getItem("courseId"),
                        assessmentType,
                        selectedSubjects
                    ));
                    history.push(`/registered-subjects`);
                }
            }
        } else {
            alert.error("Please select mode of assessment to proceed.");
        }
    };

    const goToPreviousPage = (e) => {
        e.preventDefault();
        history.push(`/course-subjects`);
    };

    return (
        <Fragment>
            <section
                className="page-banner-sec text-center"
                style={{
                    backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
                }}
            >
                <div className="container">
                    <ul className="clearfix paginations-cust">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to={`/course-subjects`}>Subjects</Link>
                        </li>
                    </ul>
                    <h1>Mode of Assessment</h1>
                </div>
            </section>

            <section className="subscription-page">
                <div className="container">
                    <div className="end-plan">
                        <h1>
                            Select {localStorage.getItem("examName")} Mode of Assessment
                        </h1>
                    </div>
                    <div className="custom-radios-plan examtype-spaner">
                        {eloading ? (
                            <Loader />
                        ) : (
                            <div className="wrapper clearfix">
                                {assessments?.length > 0 ? (
                                    assessments.map((item, key) => {
                                        const indexId = key + 1;
                                        return (
                                            <Fragment key={indexId}>
                                                <input
                                                    type="radio"
                                                    name="select"
                                                    id={`option-${indexId}`}
                                                    data-name={item.title}
                                                    value={item._id}
                                                    data-slug={item.slug}
                                                    checked={assessmentType === item._id}
                                                    onChange={onChange}
                                                />
                                                <label
                                                    htmlFor={`option-${indexId}`}
                                                    className={`option option-${indexId}`}
                                                >
                                                    <div className="dot"></div>
                                                    <div className="extype">
                                                        <img
                                                            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/assessments/${item.logo}`}
                                                            alt="exam-logo"
                                                        />
                                                    </div>
                                                    <span>{item.title}</span>
                                                </label>
                                            </Fragment>
                                        );
                                    })
                                ) : (
                                    ""
                                )}
                            </div>
                        )}
                    </div>
                    <div className="next-prev-steps text-center">
                        <button onClick={goToPreviousPage} className="chose-more">
                            <i className="fas fa-arrow-left"></i> Prev
                        </button>{" "}
                        <button
                            type="submit"
                            className="chose-more"
                            onClick={submitHandler}
                        >
                            Next <i className="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Assessment;
