import React, { Fragment } from "react";
import { Switch } from "react-router-dom";

import RootRouter from "./Router/RootRouter";
import AppLayout from "./Layout/AppLayout";
import ExamLayout from "./Layout/ExamLayout";
import AuthLayout from "./Layout/AuthLayout";
import Home from "./components/Home/Home";
import Register from "./components/Auth/Register";
import ActivateAccount from "./components/ActivateAccount";
import Login from "./components/Auth/Login";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";

import Calculator from "./components/Calculator/Calculator";

import CmsPage from "./components/cms/CmsPage";

import AdminLayout from "./Layout/Admin/AdminLayout";
import AdminLogin from "./components/Admin/Auth/AdminLogin";
import AdminForgotPassword from "./components/Admin/Auth/AdminForgotPassword";
import AdminResetPassword from "./components/Admin/Auth/AdminResetPassword";

import HomeSetting from "./components/Admin/HomeSettings/HomeSetting";

import ListBanners from "./components/Admin/Banners/ListBanners";
import AddBanner from "./components/Admin/Banners/AddBanner";
import EditBanner from "./components/Admin/Banners/EditBanner";

import AdminDashboard from "./components/Admin/Dashboard/AdminDashboard";
import AdminLoginLayout from "./Layout/Admin/AdminLoginLayout";
import Dashboard from "./components/Dashboard/Dashboard";
import UserLayout from "./Layout/UserLayout";
import Profile from "./components/User/Profile";
import EditProfile from "./components/User/EditProfile";
import UpdatePassword from "./components/User/UpdatePassword";
import ListUser from "./components/Admin/Users/AdminUsers/ListUser";
import EditAdminUser from "./components/Admin/Users/AdminUsers/EditAdminUser";
import AddAdminUser from "./components/Admin/Users/AdminUsers/AddAdminUser";

import ListStudents from "./components/Admin/Users/Students/ListStudents";
import EditStudent from "./components/Admin/Users/Students/EditStudent";
import AddStudent from "./components/Admin/Users/Students/AddStudent";

import ListCms from "./components/Admin/cms/ListCms";
import EditCms from "./components/Admin/cms/EditCms";

import ListEmailTemplates from "./components/Admin/EmailTemplates/ListEmailTemplates";
import EditEmailTemplate from "./components/Admin/EmailTemplates/EditEmailTemplate";

import ListCategories from "./components/Admin/Categories/ListCategories";
import AddCategory from "./components/Admin/Categories/AddCategory";
import EditCategory from "./components/Admin/Categories/EditCategory";

import ListExams from "./components/Admin/Exams/ListExams";
import AddExam from "./components/Admin/Exams/AddExam";
import EditExam from "./components/Admin/Exams/EditExam";


import ListUniversity from "./components/Admin/University/ListUniversity";
import AddUniversity from "./components/Admin/University/AddUniversity";
import EditUniversity from "./components/Admin/University/EditUniversity";

import ListSubscriptions from "./components/Admin/Subscriptions/ListSubscriptions";
import AddSubscription from "./components/Admin/Subscriptions/AddSubscription";
import EditSubscription from "./components/Admin/Subscriptions/EditSubscription";

import ListAssessments from "./components/Admin/Assessments/ListAssessments";
import AddAssessment from "./components/Admin/Assessments/AddAssessment";
import EditAssessment from "./components/Admin/Assessments/EditAssessment";

import ListCourses from "./components/Admin/Courses/ListCourses";
import AddCourse from "./components/Admin/Courses/AddCourse";
import EditCourse from "./components/Admin/Courses/EditCourse";

import ListSubjects from "./components/Admin/Subjects/ListSubjects";
import AddSubject from "./components/Admin/Subjects/AddSubject";
import EditSubject from "./components/Admin/Subjects/EditSubject";

import ListTopics from "./components/Admin/Topics/ListTopics";
import AddTopic from "./components/Admin/Topics/AddTopic";
import EditTopic from "./components/Admin/Topics/EditTopic";

import ListQuestionSubjects from "./components/Admin/Questions/ListQuestionSubjects";
import AddQuestionSubject from "./components/Admin/Questions/AddQuestionSubject";
import ListSubjectYears from "./components/Admin/Questions/ListSubjectYears";
import EditSubjectYear from "./components/Admin/Questions/EditSubjectYear";

import ListTopicSections from "./components/Admin/TopicSections/ListTopicSections";
import AddTopicSection from "./components/Admin/TopicSections/AddTopicSection";
import EditTopicSection from "./components/Admin/TopicSections/EditTopicSection";
import ListQuestionTopicSections from "./components/Admin/Questions/ListQuestionTopicSections";

import ListQuestions from "./components/Admin/Questions/ListQuestions";
import AddQuestion from "./components/Admin/Questions/AddQuestion";
import EditQuestion from "./components/Admin/Questions/EditQuestion";
import ImportQuestions from "./components/Admin/Questions/ImportQuestions";

import ListTheories from "./components/Admin/Theories/ListTheories";
import AddTheory from "./components/Admin/Theories/AddTheory";
import EditTheory from "./components/Admin/Theories/EditTheory";

import ListNews from "./components/Admin/News/ListNews";
import AddNews from "./components/Admin/News/AddNews";
import EditNews from "./components/Admin/News/EditNews";

import Page404 from "./components/Page404";
import Exams from "./components/Exam/Exams";
import ExamSubscriptions from "./components/Subscription/ExamSubscriptions";
import Categories from "./components/Categories/Categories";
import CategoryCourses from "./components/CategoryCourses/CategoryCourses";
import CourseSubjects from "./components/CourseSubjects/CourseSubjects";
import Assessment from "./components/Assessment/Assessment";
import Instruction from "./components/Mock/Instruction";

import News from "./components/News/News";
import NewsDetail from "./components/News/NewsDetail";
import UserSubscriptions from "./components/Subscription/UserSubscriptions";
import RegisteredSubjects from "./components/RegisteredSubjects/RegisteredSubjects";
import AdminRegSubjects from "./components/Admin/RegisteredSubjects/RegisteredSubjects";
import SelectSubjectTopic from "./components/RegisteredSubjects/SelectSubjectTopic";
import YearQuestions from "./components/Questions/YearQuestions";
import Results from "./components/Results/Results";
import ListResults from "./components/Results/ListResults";
import ResultView from "./components/Results/ResultView";
import ListUserSubscriptions from "./components/Admin/UserSubscriptions/ListUserSubscriptions";
import ListAdvices from "./components/Admin/Advices/ListAdvices";
import AddAdvice from "./components/Admin/Advices/AddAdvice";
import EditAdvice from "./components/Admin/Advices/EditAdvice";

import ListInstructions from "./components/Admin/Instructions/ListInstructions";
import AddInstruction from "./components/Admin/Instructions/AddInstruction";
import EditInstruction from "./components/Admin/Instructions/EditInstruction";

import ListContactus from "./components/Admin/Contactus/ListContactus";
import ContactusDetail from "./components/Admin/Contactus/ContactusDetail";
import ContactUsFront from "./components/Contactus/ContactUsFront";

import ListSubscribeNewsletter from "./components/Admin/SubscribeNewsletter/ListSubscribeNewsletter";
import ListUserTheories from "./components/Admin/UserTheories/ListUserTheories";
import StartMock from "./components/Mock/StartMock";
import MockExam from "./components/Mock/MockExam";
import StartExam from "./components/Mock/StartExam";
import SavedSubjects from "./components/SavedSubjects/SavedSubjects";
import ExamType from "./components/ExamTypes/ExamType";
import TheoriesQuestions from "./components/Questions/TheoriesQuestions";
import ObjectiveQuestions from "./components/Questions/ObjectiveQuestions";
import ObjectiveExam from "./components/Mock/ObjectiveExam";
import TheoriesExam from "./components/Mock/TheoriesExam";
import TheoriesGrade from "./components/Results/TheoriesGrade";
import TheoriesResults from "./components/Results/TheoriesResults";
import TheoriesResultView from "./components/Results/TheoriesResultView";
import ListTestimonials from "./components/Admin/Testimonials/ListTestimonials";
import AddTestimonial from "./components/Admin/Testimonials/AddTestimonial";
import EditTestimonial from "./components/Admin/Testimonials/EditTestimonial";
import PerformanceTracker from "./components/Results/PerformanceTracker";
import SavedExamSubjects from "./components/SavedSubjects/SavedExamSubjects";
import UpdateSubjects from "./components/Admin/RegisteredSubjects/UpdateSubjects";
import UpdateExamSubjects from "./components/SavedSubjects/UpdateExamSubjects";
import University from "./components/University/University";
import PlanSubscriptions from "./components/Subscription/PlanSubscriptions";

const AppRouter = () => {
  return (
    <Fragment>
      <Switch>
        <RootRouter
          exact
          path="/"
          layout={AppLayout}
          component={Home}
          protectedUrl={false}
        />
        <RootRouter
          exact
          path="/exams"
          layout={AppLayout}
          component={Exams}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/university"
          layout={AppLayout}
          component={University}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/exam-type"
          layout={AppLayout}
          component={ExamType}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/categories"
          layout={AppLayout}
          component={Categories}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/category-courses"
          layout={AppLayout}
          component={CategoryCourses}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/subscription"
          layout={AppLayout}
          component={ExamSubscriptions}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/subscription-plans"
          layout={AppLayout}
          component={PlanSubscriptions}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/course-subjects"
          layout={AppLayout}
          component={CourseSubjects}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/mode-of-assessment"
          layout={AppLayout}
          component={Assessment}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/registered-subjects"
          layout={AppLayout}
          component={RegisteredSubjects}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/select-subject-topic"
          layout={AppLayout}
          component={SelectSubjectTopic}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/instructions"
          layout={AppLayout}
          component={Instruction}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/start-exam"
          layout={AppLayout}
          component={StartExam}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/start-mock/:id"
          layout={AppLayout}
          component={StartMock}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/mock-exam/:id"
          layout={ExamLayout}
          component={MockExam}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/news"
          layout={AppLayout}
          component={News}
          protectedUrl={false}
        />
        <RootRouter
          exact
          path="/news/:id"
          layout={AppLayout}
          component={NewsDetail}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/register"
          layout={AuthLayout}
          component={Register}
          protectedUrl={false}
        />
        <RootRouter
          exact
          path="/login"
          layout={AuthLayout}
          component={Login}
          protectedUrl={false}
        />
        <RootRouter
          exact
          path="/forgot-password"
          layout={AuthLayout}
          component={ForgotPassword}
          protectedUrl={false}
        />
        <RootRouter
          exact
          path="/password/reset/:token"
          layout={AuthLayout}
          component={ResetPassword}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/contact-us"
          layout={AuthLayout}
          component={ContactUsFront}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/pages/:page_url"
          layout={AppLayout}
          component={CmsPage}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/dashboard"
          layout={UserLayout}
          component={Dashboard}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/profile"
          layout={UserLayout}
          component={Profile}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/profile/edit"
          layout={UserLayout}
          component={EditProfile}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/account-setting"
          layout={UserLayout}
          component={UpdatePassword}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/list-questions"
          layout={ExamLayout}
          component={YearQuestions}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/objective-questions"
          layout={ExamLayout}
          component={ObjectiveQuestions}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/theories-questions"
          layout={ExamLayout}
          component={TheoriesQuestions}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/objective-exam/:id"
          layout={ExamLayout}
          component={ObjectiveExam}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/theories-grade/:id"
          layout={ExamLayout}
          component={TheoriesGrade}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/theories-exam/:id"
          layout={ExamLayout}
          component={TheoriesExam}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/calculator"
          layout={UserLayout}
          component={Calculator}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/my-subscriptions"
          layout={UserLayout}
          component={UserSubscriptions}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/saved-exams"
          layout={UserLayout}
          component={SavedSubjects}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/saved-subjects"
          layout={UserLayout}
          component={SavedExamSubjects}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/saved-subjects-update"
          layout={UserLayout}
          component={UpdateExamSubjects}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/results"
          layout={UserLayout}
          component={ListResults}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/mock-score"
          layout={UserLayout}
          // component={MockScoreAnalytics}
          component={PerformanceTracker}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/practice-results/:quizId"
          layout={UserLayout}
          component={Results}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/theories-results/:quizId"
          layout={UserLayout}
          component={TheoriesResults}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/practice-results-view/:quizId"
          layout={ExamLayout}
          component={ResultView}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/theories-results-view/:quizId"
          layout={ExamLayout}
          component={TheoriesResultView}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin"
          layout={AdminLayout}
          component={AdminDashboard}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/users"
          layout={AdminLayout}
          component={ListUser}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/users/edit/:id"
          layout={AdminLayout}
          component={EditAdminUser}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/users/add"
          layout={AdminLayout}
          component={AddAdminUser}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/students"
          layout={AdminLayout}
          component={ListStudents}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/students/edit/:id"
          layout={AdminLayout}
          component={EditStudent}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/students/add"
          layout={AdminLayout}
          component={AddStudent}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/home-setting"
          layout={AdminLayout}
          component={HomeSetting}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/banners"
          layout={AdminLayout}
          component={ListBanners}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/banners/add"
          layout={AdminLayout}
          component={AddBanner}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/banners/edit/:id"
          layout={AdminLayout}
          component={EditBanner}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/pages"
          layout={AdminLayout}
          component={ListCms}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/pages/edit/:id"
          layout={AdminLayout}
          component={EditCms}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/emailtemplates"
          layout={AdminLayout}
          component={ListEmailTemplates}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/emailtemplates/edit/:id"
          layout={AdminLayout}
          component={EditEmailTemplate}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/categories"
          layout={AdminLayout}
          component={ListCategories}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/categories/add"
          layout={AdminLayout}
          component={AddCategory}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/categories/edit/:id"
          layout={AdminLayout}
          component={EditCategory}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/exams"
          layout={AdminLayout}
          component={ListExams}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/exams/add"
          layout={AdminLayout}
          component={AddExam}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/exams/edit/:id"
          layout={AdminLayout}
          component={EditExam}
          protectedUrl={true}
        />


        <RootRouter
          exact
          path="/admin/university"
          layout={AdminLayout}
          component={ListUniversity}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/university/add"
          layout={AdminLayout}
          component={AddUniversity}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/university/edit/:id"
          layout={AdminLayout}
          component={EditUniversity}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/assessments"
          layout={AdminLayout}
          component={ListAssessments}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/assessments/add"
          layout={AdminLayout}
          component={AddAssessment}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/assessments/edit/:id"
          layout={AdminLayout}
          component={EditAssessment}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/courses"
          layout={AdminLayout}
          component={ListCourses}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/courses/add"
          layout={AdminLayout}
          component={AddCourse}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/courses/edit/:id"
          layout={AdminLayout}
          component={EditCourse}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/subjects"
          layout={AdminLayout}
          component={ListSubjects}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/subjects/add"
          layout={AdminLayout}
          component={AddSubject}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/subjects/edit/:id"
          layout={AdminLayout}
          component={EditSubject}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/topics/:subjectId"
          layout={AdminLayout}
          component={ListTopics}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/topics/:subjectId/add"
          layout={AdminLayout}
          component={AddTopic}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/topics/:subjectId/edit/:id"
          layout={AdminLayout}
          component={EditTopic}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/topic-sections/:topicId"
          layout={AdminLayout}
          component={ListTopicSections}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/topic-sections/:topicId/add"
          layout={AdminLayout}
          component={AddTopicSection}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/topic-sections/edit/:sectionId"
          layout={AdminLayout}
          component={EditTopicSection}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/subscriptions"
          layout={AdminLayout}
          component={ListSubscriptions}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/subscriptions/add"
          layout={AdminLayout}
          component={AddSubscription}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/subscriptions/edit/:id"
          layout={AdminLayout}
          component={EditSubscription}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/student-subscriptions"
          layout={AdminLayout}
          component={ListUserSubscriptions}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/student-registered-subjects"
          layout={AdminLayout}
          component={AdminRegSubjects}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/student-registered-subjects/update"
          layout={AdminLayout}
          component={UpdateSubjects}
          protectedUrl={true}
        />


        student-registered-subjects

        <RootRouter
          exact
          path="/admin/question-subjects"
          layout={AdminLayout}
          component={ListQuestionSubjects}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/question-subjects/add"
          layout={AdminLayout}
          component={AddQuestionSubject}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/subject-years/:subjectId"
          layout={AdminLayout}
          component={ListSubjectYears}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/subject-years/edit/:examId/:subId"
          layout={AdminLayout}
          component={EditSubjectYear}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/topicsection/:subjectId/:id"
          layout={AdminLayout}
          component={ListQuestionTopicSections}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/questions/:sectionId/:subjectId/:yearId/:topicId"
          layout={AdminLayout}
          component={ListQuestions}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/questions/import/:sectionId/:subjectId/:yearId/:topicId"
          layout={AdminLayout}
          component={ImportQuestions}
          // component={ExcelUploadRenderer}
          protectedUrl={true}
        />

        <RootRouter
          exact
          // path="/admin/questions/add/:subjectId/:yearId/:sectionId"
          path="/admin/questions/add/:subjectId/:yearId/:sectionId/:topicId"
          layout={AdminLayout}
          component={AddQuestion}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/questions/edit/:subjectId/:yearId/:id/:tId"
          layout={AdminLayout}
          component={EditQuestion}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/theories/:sectionId/:subjectId/:yearId"
          layout={AdminLayout}
          component={ListTheories}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/theories/:subjectId/:yearId/:sectionId/add"
          layout={AdminLayout}
          component={AddTheory}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/theories/edit/:subjectId/:yearId/:id"
          layout={AdminLayout}
          component={EditTheory}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/news-list"
          layout={AdminLayout}
          component={ListNews}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/news-list/add"
          layout={AdminLayout}
          component={AddNews}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/news-list/edit/:id"
          layout={AdminLayout}
          component={EditNews}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/advice-list"
          layout={AdminLayout}
          component={ListAdvices}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/advice-list/add"
          layout={AdminLayout}
          component={AddAdvice}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/advice-list/:id/edit"
          layout={AdminLayout}
          component={EditAdvice}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/exam-instructions"
          layout={AdminLayout}
          component={ListInstructions}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/exam-instructions/add"
          layout={AdminLayout}
          component={AddInstruction}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/exam-instructions/edit/:id"
          layout={AdminLayout}
          component={EditInstruction}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/contactus"
          layout={AdminLayout}
          component={ListContactus}
          protectedUrl={true}
        />
        <RootRouter
          exact
          path="/admin/contactus/detail/:id"
          layout={AdminLayout}
          component={ContactusDetail}
          protectedUrl={true}
        />

        <RootRouter
          exact
          path="/admin/subscribenewsletter"
          layout={AdminLayout}
          component={ListSubscribeNewsletter}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/admin/testimonials"
          layout={AdminLayout}
          component={ListTestimonials}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/admin/testimonials/add"
          layout={AdminLayout}
          component={AddTestimonial}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/admin/testimonials/:id/edit"
          layout={AdminLayout}
          component={EditTestimonial}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/admin/user-theories-exam"
          layout={AdminLayout}
          component={ListUserTheories}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/activate-account/:id"
          layout={AppLayout}
          component={ActivateAccount}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="/admin/login"
          layout={AdminLoginLayout}
          component={AdminLogin}
          protectedUrl={false}
        />
        <RootRouter
          exact
          path="/admin/forgot-password"
          layout={AdminLoginLayout}
          component={AdminForgotPassword}
          protectedUrl={false}
        />
        <RootRouter
          exact
          path="/admin/reset-password/:token"
          layout={AdminLoginLayout}
          component={AdminResetPassword}
          protectedUrl={false}
        />

        <RootRouter
          exact
          path="*"
          layout={AppLayout}
          component={Page404}
          protectedUrl={false}
        />
      </Switch>
    </Fragment>
  );
};

export default AppRouter;
