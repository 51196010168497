import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";

import { getAllCategories } from "./../../actions/commonActions";
import Loader from "../Loader";

const Categories = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");

  const { allCategories, loading } = useSelector(
    (state) => state.commonCategories
  );

  useEffect(() => {
    // Fetch categories and initialize values from localStorage
    dispatch(getAllCategories());
    const storedCategoryId = localStorage.getItem("categoryId");
    const storedCategoryName = localStorage.getItem("categoryName");
    if (storedCategoryId) setCategoryId(storedCategoryId);
    if (storedCategoryName) setCategoryName(storedCategoryName);

    // Scroll to the top on component mount
    window.scrollTo(0, 0);
  }, [dispatch]);

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    const selectedCategoryName = e.target.dataset.name;
    setCategoryId(selectedCategoryId);
    setCategoryName(selectedCategoryName);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!categoryId) {
      alert.error("Please select a category to proceed.");
      return;
    }

    localStorage.setItem("categoryId", categoryId);
    localStorage.setItem("categoryName", categoryName);
    history.push(`/category-courses`);
  };

  return (
    <Fragment>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <ul className="clearfix paginations-cust">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/exams">Exams</Link>
            </li>
          </ul>
          <h1>Intended Courses Types</h1>
        </div>
      </section>

      <section className="subscription-page">
        <div className="container">
          <div className="end-plan">
            <h1>Select Intended Courses Types</h1>
          </div>
          <div className="custom-radios-plan examtype-spaner">
            {loading ? (
              <Loader />
            ) : (
              <div className="wrapper clearfix">
                {allCategories && allCategories.length > 0 ? (
                  allCategories.map((item, index) => (
                    <Fragment key={index}>
                      <input
                        type="radio"
                        name="select"
                        id={`option-${index + 1}`}
                        value={item._id}
                        data-name={item.title}
                        checked={item._id === categoryId}
                        onChange={handleCategoryChange}
                      />
                      <label
                        htmlFor={`option-${index + 1}`}
                        className={`option option-${index + 1}`}
                      >
                        <div className="dot"></div>
                        <div className="extype">
                          <img
                            src={
                              `${process.env.REACT_APP_PUBLIC_URL}/assets/img/categories/${item.image}`
                            }
                            alt="exam-logo"
                          />
                        </div>
                        <span>{item.title}</span>
                      </label>
                    </Fragment>
                  ))
                ) : (
                  <p>No categories available.</p>
                )}
              </div>
            )}
          </div>
          <div className="next-prev-steps text-center">
            <Link to={`/exams`} className="chose-more">
              <i className="fas fa-arrow-left"></i> Prev
            </Link>{" "}
            <button
              type="submit"
              className="chose-more"
              onClick={submitHandler}
            >
              Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Categories;
