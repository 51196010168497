import React from 'react';
import './styles.css'; // Import the CSS file for styling

const LoaderComponent = () => {
  return (
    <div className="loader-container">
      <div className="bar-graph-loader">
        <div className="bar-loader" style={{ animationDelay: '0s' }}></div>
        <div className="bar-loader" style={{ animationDelay: '0.2s' }}></div>
        <div className="bar-loader" style={{ animationDelay: '0.4s' }}></div>
        <div className="bar-loader" style={{ animationDelay: '0.6s' }}></div>
        <div className="bar-loader" style={{ animationDelay: '0.8s' }}></div>
      </div>
      <div className="loader-text">Please Wait...</div>
    </div>
  );
};

export default LoaderComponent;
