import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { useDispatch, useSelector } from "react-redux";
import { getAllSubjects } from "../../../actions/commonActions";
import {
  addQuestionSubject,
  clearErrors,
} from "../../../actions/adminQuestionActions";
import { ADD_QUESTION_SUBJECT_RESET } from "./../../../constants/adminQuestionConstants";

import Loader from "../../Loader";

import "./Question.css";
import { getExams } from "../../../actions/adminExamActions";
import { GET_SUBJECTS_SUCCESS } from "../../../constants/commonConstants";
import { getUniversityList } from "../../../actions/adminUniversityAction";

const AddQuestion = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [university, setUniversity] = useState([]);
  const [universityLists, setuniversityLists] = useState([]);
  const currentYear = new Date().getFullYear();
  const animatedComponents = makeAnimated();

  // alert.success(currentYear);
  const [question, setQuestion] = useState({
    subjectName: "",
    year: "",
    status: false,
    exam: ""
  });
  const [errorData, setErrorData] = useState({});

  const { subjectName, year, status, exam } = question;
  const { isQSAdded, user_loading, error, errors } = useSelector(
    (state) => state.questionSubjectDetail
  );
  const { exams } = useSelector((state) => state.exams);
  const [examSlug, setExamSlug] = useState("");
  const { universityList, isUniversityDeleted } = useSelector((state) => state.university);


  useEffect(() => {
    dispatch(getUniversityList())
  }, [])

  useEffect(() => {
    if (universityList !== undefined && universityList?.length > 0 && universityList !== null) {
      const tmpArr = [];
      universityList?.map((course) => {
        tmpArr?.push({ label: course?.title, value: course?._id })
      })

      setuniversityLists(tmpArr)
    }
  }, [universityList])
  useEffect(() => {
    dispatch(getExams());

    if (errors) {
      setErrorData(errors);
    }

    if (error) {
      clearErrors();
      return alert.error(error);
    }

    if (isQSAdded === true && isQSAdded != undefined) {
      alert.success("Question Subject successfully added.");
      history.push("/admin/question-subjects");
      dispatch({
        type: ADD_QUESTION_SUBJECT_RESET,
      });
    }
  }, [dispatch, isQSAdded, alert, error, errors]);

  const { allSubjects, loading } = useSelector((state) => state.commonSubjects);

  const onChange = (e) => {
    const { name, value } = e.target
    if (name === "exam" && e.target.value !== '') {
      const matchingSlugs = exams
        .filter((item) => item?._id === e.target.value)
        .map((item) => item.slug);
      setExamSlug(matchingSlugs.join(''))
      dispatch(getAllSubjects(e.target.value))
    }
    else if (name === "exam" && e.target.value === '') {
      dispatch({
        type: GET_SUBJECTS_SUCCESS,
        payload: [],
      });
    }

    if (name === "status") {
      setQuestion({
        ...question,
        status: !status,
      });
      return
    }
    else {
      setQuestion({
        ...question,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitHandler = (e) => {

    let universityItem = [];

    if (university && university.length > 0) {
      universityItem = university.map((member) => {
        return member.value;
      });
    }

    e.preventDefault();
    dispatch(addQuestionSubject(subjectName, year, universityItem, status));
  };


  console.log(examSlug, universityLists);

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Add
                <small>Question Subject</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/question-subjects">
                    <i className="fa fa-users"></i> Question Subject
                  </Link>
                </li>
                <li className="active">Add Question Subject and Year</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Add Question Subject and Year</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-4">
                        <label htmlFor="subjectName">Exam Name</label>
                        <select
                          className="form-control"
                          name="exam"
                          value={exam}
                          onChange={onChange}
                        >
                          <option value="">Select</option>
                          {loading ? (
                            <Loader />
                          ) : (
                            <Fragment>
                              {exams?.length > 0
                                ? exams?.map((e, key) => {
                                  return (
                                    <option key={key} value={e._id}>
                                      {e.title}
                                    </option>
                                  );
                                })
                                : ""}
                            </Fragment>
                          )}
                        </select>
                        {errorData.subject ? (
                          <span className="text-danger">
                            {errorData.subject}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="subjectName">Subject Name</label>
                        <select
                          className="form-control"
                          name="subjectName"
                          value={subjectName}
                          onChange={onChange}
                        >
                          <option value="">Select</option>
                          {loading ? (
                            <Loader />
                          ) : (
                            <Fragment>
                              {allSubjects && allSubjects.length > 0
                                ? allSubjects.map((e, key) => {
                                  return (
                                    <option key={key} value={e._id}>
                                      {e.title}
                                    </option>
                                  );
                                })
                                : ""}
                            </Fragment>
                          )}
                        </select>
                        {errorData.subject ? (
                          <span className="text-danger">
                            {errorData.subject}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {examSlug == 'post-utme' && <div className="form-group row">
                        <div className="col-md-4">
                          <label htmlFor="title">Select University</label>

                          <Select
                            value={university.value}
                            onChange={setUniversity}
                            options={universityLists}
                            isMulti
                            components={animatedComponents}
                            isSearchable={true}
                          />

                        </div>
                      </div>}
                      <div className="col-md-4">
                        <label htmlFor="year">Year</label>
                        <select
                          className="form-control"
                          name="year"
                          value={year}
                          onChange={onChange}
                        >
                          <option value="">Select</option>
                          {Array.from(new Array(40), (v, i) => (
                            <option key={i} value={currentYear - i}>
                              {currentYear - i}
                            </option>
                          ))}
                        </select>
                        {errorData.year ? (
                          <span className="text-danger">{errorData.year}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="status">Status </label>
                    <input
                      style={{ marginRight: '5px' }}
                      type="checkbox"
                      className="form-check-input"
                      checked={status}
                      name="status"
                      onClick={onChange}
                    />
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AddQuestion;
