import React, { Fragment } from "react";
import swal from 'sweetalert';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useDispatch } from "react-redux";
import { addSubscribeNewsletter } from "../../actions/adminSubscribeNewsletterActions";


// form validation rules 
  const validationSchema = yup.object().shape({
    newsletter_email: yup.string().required('Email is required').email('Email is invalid')
});

const SubscribeNewsletter = () => {

  const dispatch = useDispatch();

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, errors } = useForm({
      resolver: yupResolver(validationSchema),
  });


  const submitForm = (data, e) => {   
      
      dispatch(addSubscribeNewsletter(data.newsletter_email));
      e.target.reset();
      swal("Subscribe Newsletter", "Thanks for subscribing our newsletter.", "success");
  }

    return (
        <Fragment>
            <div className="newsletter social-nav clearfix">
            <form onSubmit={handleSubmit(submitForm)}>
                <input
                  ref={register}
                  type="email"
                  className="form-control"
                  id="newsletter_email"
                  name="newsletter_email"
                  placeholder="Email"
                />
                <div className="invalid-feedback">{errors.newsletter_email?.message}</div>
                <button type="submit" className="btn">Subscribe</button>
                </form>
              </div>
        </Fragment>
    )
}
export default SubscribeNewsletter;