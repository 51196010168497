import React, { Fragment, useEffect, useState} from 'react';

import './mock.css';

const StartMock = ({ history, match }) => {
    const examId = match.params.id;
    const [examType, setExamType] = useState('');

	useEffect(() => {
        const storedExamType = localStorage.getItem("examType");
        if (storedExamType) {
            setExamType(storedExamType);
        }
    }, []);

	const proceedToExam = (e) => {
        e.preventDefault();

        const h = window.screen.height;
        const w = window.screen.width;
        const t = window.location.href;
        let r;

        if (examType === "theory") {
            r = t.replace('start-mock', 'theories-exam');
        } else {
            r = t.replace('start-mock', 'mock-exam');
        }

        window.open(r, 'window', `toolbar=no, menubar=no, resizable=no,width=${w},height=${h}`);
        history.push('/dashboard');
    };

	return (
		<Fragment>
            <section className="start-mock-sec text-center">
                <div className="container start-mock">
                    <button type="button" className="btn start-mock-btn" onClick={proceedToExam}>
                        Click to Start &nbsp;<i className="fas fa-arrow-right" />
                    </button>
                </div>
            </section>
        </Fragment>
	);
};

export default StartMock;
