import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import moment from "moment";
import ReactHtmlParser from "react-html-parser"
import Countdown, {
    zeroPad,
    calcTimeDelta,
    formatTimeDelta,
} from "react-countdown";

import Loader from "../Loader";

import { getYearQuestions } from "./../../actions/questionActions";
import { Editor } from "@tinymce/tinymce-react";

import { submitExamResult } from "./../../actions/resultActions";
import { SUBMIT_RESULT_RESET } from "./../../constants/resultConstants";

import "./question.css";
import Modal from "../Modal/Modal";
import Calculator from "../Calculator/Calculator";

const TheoriesQuestions = ({ history }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const { user, loading, error } = useSelector((state) => state.auth);

    const userId = user._id;

    const examId = localStorage.getItem("examId");
    const examType = localStorage.getItem("examType");
    const subjectId = localStorage.getItem("subjectId");
    const subjectIds = localStorage.getItem("checkedSubjects")?.split(",");
    const year = localStorage.getItem("year");
    const shuffleQuestions = localStorage.getItem("shuffleQuestions");
    const shuffleOptions = localStorage.getItem("shuffleOptions");
    const timeDuration = localStorage.getItem("timeDuration");
    // alert.info(timeDuration);
    //var timerData = Date.now() + parseInt(timeDuration) * 60 * 1000;
    const assessmentId = localStorage.getItem("assessmentId");
    const assessmentType = localStorage.getItem("assessmentName");
    const savedSubjectId = localStorage.getItem("savedId");
    const subscriptionId = localStorage.getItem("userSubscription");

    function disableF5(e) {
        if ((e.which || e.keyCode) == 116 || (e.ctrlKey && e.keycode == 82)) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", disableF5);

        window.history.pushState(null, document.title, window.location.href);
        window.history.back();
        window.history.forward();
        window.onpopstate = function () {
            history.go(1);
        };
        const questionCount = localStorage.getItem('questionCount');
        dispatch(
            getYearQuestions(
                examId,
                examType,
                subjectIds,
                year,
                shuffleQuestions,
                shuffleOptions,
                [],
                questionCount,
                [],
                subscriptionId
            )
        );
    }, [dispatch, alert]);

    const { examData, questions, questionCount, qLoading } = useSelector(
        (state) => state.yearQuestions
    );

    const [showCalculator, setShowCalculator] = useState(false);

    // console.log("questions ", questions); return
    const [totalQuestions, setTotalQuestions] = useState(0);

    const [currentSubject, setCurrentSubject] = useState(0);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);

    const [unanswered, setUnanswered] = useState([]);
    const [answered, setAnswered] = useState([]);
    const [markForReviewed, setMarkForReviewed] = useState([]);

    const [detailedAns, setDetailedAns] = useState("");

    const [theoriesAnswered, setTheoriesAnswered] = useState([]);

    const [showResults, setShowResults] = useState(false);
    const [rightAnswer, setRightAnswer] = useState("");
    const [description, setDescription] = useState("123")

    const [totalTime, setTotalTime] = useState(
        Date.now() + parseInt(timeDuration) * 60 * 1000
    );
    const [quizTotalTime, setQuizTotalTime] = useState("");
    const [remainingTime, setRemainingTime] = useState("");
    const [takenTime, setTakenTime] = useState("");

    useEffect(() => {
        if (
            questions !== undefined &&
            questions.length > 0 &&
            questions[0].theories !== undefined &&
            questions[0].theories.length > 0
        ) {
            // var timerData = Date.now() + parseInt(timeDuration) * 60 * 1000;
            // setTotalTime(timerData);

            setTotalQuestions(questionCount);
            setNumberOfQuestions(questions[0].theories.length);
            // alert.success(numberOfQuestions);
        }
    }, [questions, rightAnswer]);

    const [selections, setSelections] = useState([
        Object.keys(questions).map((x) => []),
    ]);

    const handleSubjectType = (e) => {
        e.preventDefault();
        const currentIndex = parseInt(e.target.dataset.id);

        setCurrentSubject(currentIndex);
        setCurrentQuestion(0);
        // alert.success(currentIndex);
        if (
            questions !== undefined &&
            questions.length > 0 &&
            questions[currentIndex].theories !== undefined &&
            questions[currentIndex].theories.length > 0
        ) {
            // alert.success(questions[currentIndex].questions.length);
            setNumberOfQuestions(questions[currentIndex].theories.length);
        }
        setShowResults(false);
    };


    const handleTheoriesAnswer = (event) => {
        const answerData = event.target.value;
        const selectedTheoriesId = event.target.dataset.theoriesid;

        let tAnsweredArray = [
            ...theoriesAnswered,
            {
                theory: selectedTheoriesId,
                answer: event.target.value,
            },
        ];
        //remove duplicate question
        if (theoriesAnswered.some((ques) => ques.theory === selectedTheoriesId)) {
            tAnsweredArray = tAnsweredArray.filter((qItem) => {
                return (
                    qItem.theory !== selectedTheoriesId
                    // qItem.answer !== event.target.value
                );
            });
            tAnsweredArray = [
                ...tAnsweredArray,
                {
                    theory: selectedTheoriesId,
                    answer: event.target.value,
                },
            ];
        }
        // setAnswered(answeredArray);

        setTheoriesAnswered(tAnsweredArray);
    };

    const handleTheoriesEditorAnswer = (id, value) => {
        const selectedTheoriesId = id;


        let tAnsweredArray = [
            ...theoriesAnswered,
            {
                theory: selectedTheoriesId,
                answer: value,
            },
        ];
        //remove duplicate question
        if (theoriesAnswered.some((ques) => ques.theory === selectedTheoriesId)) {
            tAnsweredArray = tAnsweredArray.filter((qItem) => {
                return (
                    qItem.theory !== selectedTheoriesId
                    // qItem.answer !== value
                );
            });
            tAnsweredArray = [
                ...tAnsweredArray,
                {
                    theory: selectedTheoriesId,
                    answer: value,
                },
            ];
        }
        // setAnswered(answeredArray);
        setTheoriesAnswered(tAnsweredArray);
    };


    const handleNextQuestionClick = (e) => {
        e.preventDefault();
        const nextQuestion = parseInt(currentQuestion) + 1;
        setCurrentQuestion(nextQuestion);
        setShowResults(false);
    };
    // console.log(currentQuestion, numberOfQuestions);
    const handlePreviousQuestionClick = (e) => {
        e.preventDefault();
        const nextQuestion = parseInt(currentQuestion) - 1;
        setCurrentQuestion(nextQuestion);
        setShowResults(false);
    };

    const handleViewAnswer = (e) => {
        //alert.success(numberOfQuestions);
        e.preventDefault();
        if (
            questions !== undefined &&
            questions.length > 0 &&
            questions[currentSubject].theories !== undefined &&
            questions[currentSubject].theories.length > 0
        ) {
            setRightAnswer(
                questions[currentSubject].theories[currentQuestion].answer
            );
            setDescription(questions[currentSubject]?.theories[currentQuestion]?.description)
            setDetailedAns(questions[currentSubject]?.theories[currentQuestion]?.detailedAnswer)
        }
        setShowResults(!showResults);
        // alert.success(rightAnswer);
    };

    const handleFilterQuestion = (e) => {
        e.preventDefault();
        const currentQue = e.target.dataset.id;
        // alert.info(currentQue);
        setCurrentQuestion(currentQue);
        setShowResults(false);
    };

    const handleMarkForReview = (event) => {
        let reviewArray = [...markForReviewed, event.target.value];
        if (markForReviewed.includes(event.target.value)) {
            reviewArray = reviewArray.filter((it) => it !== event.target.value);
        }
        setMarkForReviewed(reviewArray);
        // if (event.target.checked === true) {
        //   setDisabled(false);
        // }
        //alert.success("markForReviewed ", markForReviewed);
    };

    const handleRemainigTime = (data) => {
        // totalTime
        var quizHour = Math.floor(timeDuration / 60);
        quizHour = parseInt(quizHour) >= 10 ? quizHour : "0" + quizHour;
        var quizMinutes = timeDuration % 60 === 0 ? "00" : timeDuration % 60;
        var quizSecond = "00";
        const quizTime = quizHour + ":" + quizMinutes + ":" + quizSecond;

        const remainingMinutes =
            data.minutes < 10 ? "0" + data.minutes : data.minutes; // return minutes
        const remainingSeconds =
            data.seconds < 10 ? "0" + data.seconds : data.seconds;

        const remainTime =
            "0" + data.hours + ":" + remainingMinutes + ":" + remainingSeconds;
        var start = moment.duration(quizTime, "HH:mm:ss");
        var end = moment.duration(remainTime, "HH:mm:ss");
        var diff = start.subtract(end);

        const hour = diff.hours(); // return hours
        const minutes = diff.minutes() < 10 ? "0" + diff.minutes() : diff.minutes(); // return minutes
        const seconds = diff.seconds() < 10 ? "0" + diff.seconds() : diff.seconds();
        const usedTime = "0" + hour + ":" + minutes + ":" + seconds;

        setQuizTotalTime(quizTime);
        setRemainingTime(remainTime);
        setTakenTime(usedTime);

        if (remainTime === "00:00:01") {
            alert.info("Time up, your exam is submitting forcefully!!!", {
                onOpen: () => {
                    if (theoriesAnswered.length > 0) {
                        dispatch(
                            submitExamResult(
                                userId,
                                examId,
                                theoriesAnswered,
                                assessmentId,
                                subjectIds,
                                year,
                                totalQuestions,
                                quizTotalTime,
                                remainTime,
                                takenTime,
                                savedSubjectId,
                                examType
                            )
                        );
                    } else {
                        history.push(`/results`);
                    }
                },
            });
        }
    };

    // if (remainingTime === "00:00:01") {
    //   alert.info("Time up, your exam is submitting forcefully!!!", {
    //     onOpen: () => {
    //       dispatch(
    //         submitExamResult(
    //           userId,
    //           examId,
    //           theoriesAnswered,
    //           assessmentId,
    //           subjectIds,
    //           year,
    //           totalQuestions,
    //           quizTotalTime,
    //           remainingTime,
    //           takenTime,
    //           savedSubjectId,
    //           examType
    //         )
    //       );
    //       //history.push(`/results`);
    //     },
    //   });
    // }

    const handleSubmitAnswer = (e) => {
        e.preventDefault();
        if (theoriesAnswered.length > 0) {
            if (
                window.confirm(
                    "Do you really want to submit your answer. We recommend you to review your answer before submitting it."
                )
            ) {
                // console.log("answered ", answered);
                // return;
                dispatch(
                    submitExamResult(
                        userId,
                        examId,
                        theoriesAnswered,
                        assessmentId,
                        subjectIds,
                        year,
                        totalQuestions,
                        quizTotalTime,
                        remainingTime,
                        takenTime,
                        savedSubjectId,
                        examType
                    )
                );
            }
        } else {
            //alert.warning("You have not answered any questions")
            if (
                window.confirm(
                    "You have not answered any questions, your test will be cancelled. do you really want to cancel?"
                )
            ) {
                alert.info("Test has been cancelled.");
                history.push(`/dashboard`);
            }
        }
        return;
    };

    const { rLoading, isSubmitted, result } = useSelector(
        (state) => state.results
    );

    useEffect(() => {
        if (isSubmitted === true && isSubmitted != undefined) {
            /*if (examData.examType === 'theory' || examData.examType === 'both') {
                          alert.info("Your result is under consideration, you'll receive a notification on decleration.");
                      } else {
                          alert.success('Result successfully submitted.');
                      }*/
            alert.success("Result successfully submitted.");
            //history.push(`/practice-results/${result.quizId}`);
            history.push(`/theories-grade/${result.quizId}`);
            dispatch({
                type: SUBMIT_RESULT_RESET,
            });
        }
    }, [dispatch, rLoading, isSubmitted, alert]);

    // function createMarkup() {
    //   return {__html: 'First &middot; Second'};
    // }
    const val = theoriesAnswered
        .filter(
            (tItem) =>
                tItem.theory ===
                questions[currentSubject].theories[
                    currentQuestion
                ]._id
        ).map((item) => item.answer)

    // console.log("filtered", theoriesAnswered?.map((item) => item.theory == questions[currentSubject].theories[currentQuestion]._id) ? item : "")
    
    const editorVal = val?.length > 0 ? val : "";

    return (
        <Fragment>
            <section className="auth-dash-sec fullHt">
                <div className="left-navigations">
                    <div className="user-exam">
                        <div className="user-pros-exam">
                            <img
                                src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/user.png"}
                                alt="user"
                            />
                        </div>
                        <h4>
                            <b>Name</b>: {user.first_name} {user.last_name}
                        </h4>
                        <h4>
                            <i className="fas fa-calculator" onClick={() => setShowCalculator(true)} /> :{" "}
                            {localStorage.getItem("assessmentName")}
                        </h4>
                    </div>
                    <Modal title="Calculator" onClose={() => setShowCalculator(false)} show={showCalculator}>
                        <Calculator />
                    </Modal>
                    <div className="bot-examers">
                        {/*<h2>Subjects</h2>*/}
                        <div className="subject-tab">
                            <ul className="nav nav-tabs" role="tablist">
                                {questions.map((subItem, i) => {
                                    if (subItem.theories.length > 0) {
                                        return (
                                            <Fragment key={i}>
                                                <li
                                                    role="presentation"
                                                    className={currentSubject === i ? "active" : ""}
                                                >
                                                    <a
                                                        href="#"
                                                        // href={`#${subItem.subject.slug}`}
                                                        // aria-controls={`${subItem.subject.slug}`}
                                                        // role="tab"
                                                        // data-toggle="tab"
                                                        data-id={i}
                                                        onClick={handleSubjectType}
                                                    >
                                                        {subItem.subject.title}
                                                    </a>
                                                </li>
                                            </Fragment>
                                        );
                                    }
                                })}
                            </ul>

                            {/*
              <div className="tab-content">
                {questions[currentSubject] !== undefined &&
                  questions[currentSubject].subject !== undefined && (
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id={`${questions[currentSubject].subject.slug}`}
                    >
                      <div className="questions-opt">
                        <h5>Topic</h5>

                        <div className="boxes">
                          <input type="checkbox" id="box-1" />
                          <label for="box-1">Plant</label>

                          <input type="checkbox" id="box-2" checked />
                          <label for="box-2">Cell Biology</label>

                          <input type="checkbox" id="box-3" />
                          <label for="box-3">Other</label>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
                  */}
                        </div>

                        <div className="number-lister">
                            {questions !== undefined &&
                                questions.length > 0 &&
                                questions[currentSubject].theories !== undefined &&
                                questions[currentSubject].theories.length > 0 &&
                                [...Array(questions[currentSubject].theories.length)].map(
                                    (e, i) => {
                                        let className = "";
                                        if (
                                            markForReviewed.indexOf(
                                                questions[currentSubject].theories[i]._id
                                            ) > -1
                                        ) {
                                            className = "markForReviewQuestion";
                                        } else if (
                                            theoriesAnswered.find(
                                                (obj) =>
                                                    obj.theory ==
                                                    questions[currentSubject].theories[i]._id
                                            )
                                        ) {
                                            theoriesAnswered
                                                .filter(
                                                    (tItem) =>
                                                        tItem.theory ===
                                                        questions[currentSubject].theories[currentQuestion]
                                                            ._id
                                                )
                                                .map((filteredAnswer) => {
                                                    // filteredAnswer.answer
                                                    if (filteredAnswer.answer !== "") {
                                                        className = "markForAnsweredQuestion";
                                                    } else {
                                                        className = "isActiveQuestion";
                                                    }
                                                });
                                        } else if (currentQuestion === i) {
                                            className = "isActiveQuestion";
                                        }
                                        return (
                                            <Fragment key={i}>
                                                <a
                                                    className={className}
                                                    href="#"
                                                    data-id={i}
                                                    onClick={handleFilterQuestion}
                                                >
                                                    {i + 1}
                                                </a>{" "}
                                            </Fragment>
                                        );
                                    }
                                )}
                        </div>
                    </div>
                </div>

                <div className="right-auth-landing edit-pros">
                    <div className="main-view-content">
                        {assessmentType !== "study" && (
                            <div className="all-ourt-style timer w-80-cust legends-reviews clearfix">
                                <div className="pull-left legend-label">
                                    <div>Time Remaining</div> &nbsp;&nbsp;
                                    <div>
                                        <Countdown
                                            date={totalTime}
                                            //zeroPadTime={2}
                                            daysInHours={true}
                                            controlled={false}
                                            onTick={handleRemainigTime}
                                        //renderer={renderer}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <Fragment>
                            <div className="all-ourt-style w-80-cust legends-reviews clearfix">
                                <div className="pull-left legend-label">
                                    <p>
                                        <span style={{ background: "white" }} /> Un Answered
                                    </p>
                                    <p>
                                        <span style={{ background: "green" }} /> Answered
                                    </p>
                                    <p>
                                        <span style={{ background: "red" }} /> Marked for Review
                                    </p>
                                </div>
                                {questions !== undefined &&
                                    questions.length > 0 &&
                                    questions[currentSubject].theories !== undefined &&
                                    questions[currentSubject].theories.length > 0 && (
                                        <div className="pull-right">
                                            <div
                                                className="boxes"
                                                style={{ display: "inline-block" }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    name={`review-mark-${questions[currentSubject].theories[currentQuestion]._id}`}
                                                    id={`box-${questions[currentSubject].theories[currentQuestion]._id}`}
                                                    value={
                                                        questions[currentSubject].theories[currentQuestion]
                                                            ._id
                                                    }
                                                    onChange={handleMarkForReview}
                                                    checked={
                                                        markForReviewed.indexOf(
                                                            questions[currentSubject].theories[
                                                                currentQuestion
                                                            ]._id
                                                        ) > -1
                                                    }
                                                />
                                                <label
                                                    htmlFor={`box-${questions[currentSubject].theories[currentQuestion]._id}`}
                                                >
                                                    Mark for Review
                                                </label>
                                            </div>
                                            {/*:<span>(1) Question</span>*/}
                                        </div>
                                    )}
                            </div>

                            {questions !== undefined && questions.length > 0 && (
                                <Fragment>
                                    <div className="all-ourt-style w-80-cust work-info exam-set">
                                        <div className="all-heads clearfix">
                                            <h2>
                                                {" "}
                                                {parseInt(currentQuestion) + 1}.{" "}
                                                {
                                                    ReactHtmlParser(questions[currentSubject].theories[currentQuestion]
                                                        .title)
                                                }
                                                {
                                                    ReactHtmlParser(questions[currentSubject].theories[currentQuestion]
                                                        .question)
                                                }
                                            </h2>
                                        </div>

                                        {questions[currentSubject].theories[
                                            currentQuestion
                                        ].logo !== undefined && questions[currentSubject].theories[
                                            currentQuestion
                                        ].logo !== null && questions[currentSubject].theories[
                                            currentQuestion
                                        ].logo !== "" && (
                                                <Fragment>
                                                    <img
                                                        src={
                                                            process.env.REACT_APP_PUBLIC_URL +
                                                            "/assets/img/questions/" +
                                                            questions[currentSubject].theories[
                                                                currentQuestion
                                                            ].logo
                                                        }
                                                        alt="question-image"
                                                        style={{ height: "200px" }}
                                                    />
                                                </Fragment>

                                            )}

                                        <div className="exam-main-style">
                                            <div className="answer-selection">
                                                {questions[currentSubject].theories !== undefined &&
                                                    questions[currentSubject].theories.length > 0 ? (
                                                    <Fragment>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="answer">
                                                                        Answer <span className="impot-star">*</span>
                                                                    </label>
                                                                    {/* <textarea
                                                                        name="answer"
                                                                        className="form-control"
                                                                        data-theoriesid={
                                                                            questions[currentSubject].theories[
                                                                                currentQuestion
                                                                            ]._id
                                                                        }
                                                                        // name={`option-${questions[currentSubject].theories[currentQuestion]._id}`}
                                                                        id={`option-${questions[currentSubject].theories[currentQuestion]._id}`}
                                                                        placeholder="Enter Answer"
                                                                        required
                                                                        onChange={handleTheoriesAnswer}
                                                                        value={theoriesAnswered
                                                                            .filter(
                                                                                (tItem) =>
                                                                                    tItem.theory ===
                                                                                    questions[currentSubject].theories[
                                                                                        currentQuestion
                                                                                    ]._id
                                                                            )
                                                                            .map(
                                                                                (filteredAnswer) =>
                                                                                    filteredAnswer.answer
                                                                            )}
                                                                    /> */}
                                                                    <Editor
                                                                        id={`option-${questions[currentSubject].theories[currentQuestion]._id}`}
                                                                        apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"

                                                                        init={{
                                                                            height: 200,
                                                                            menubar: false,
                                                                            external_plugins: {
                                                                                tiny_mce_wiris:
                                                                                    "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                                            },
                                                                            plugins: [
                                                                                "advlist autolink lists link image imagetools tiny_mce_wiris charmap print preview anchor",
                                                                                "searchreplace visualblocks code fullscreen",
                                                                                "insertdatetime media table paste code help wordcount",
                                                                            ],
                                                                            external_plugins: {
                                                                                tiny_mce_wiris:
                                                                                    'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
                                                                            },
                                                                            toolbar:
                                                                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                                                "undo redo | formatselect " +
                                                                                "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                                                                "bullist numlist outdent indent | " +
                                                                                "removeformat | help",
                                                                            content_style:
                                                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                                        }}
                                                                        value={theoriesAnswered
                                                                            .filter(
                                                                                (tItem) =>
                                                                                    tItem.theory ==
                                                                                    questions[currentSubject].theories[
                                                                                        currentQuestion
                                                                                    ]._id
                                                                            )
                                                                            .map(
                                                                                (filteredAnswer) =>
                                                                                    filteredAnswer.answer
                                                                            ).join('')}

                                                                        onEditorChange={(value) => {

                                                                            const id = questions[currentSubject].theories[
                                                                                currentQuestion
                                                                            ]._id
                                                                            handleTheoriesEditorAnswer(id, value)
                                                                        }
                                                                        }

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </Fragment>

                        <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                            {assessmentType === "study" && (
                                <div className="pull-left">
                                    <button
                                        type="button"
                                        className="btn view-ans-btn"
                                        onClick={handleViewAnswer}
                                    >
                                        {!showResults ? "View Answer" : "Hide Answer"}
                                    </button>
                                    {rightAnswer !== undefined && showResults && (
                                        <>
                                            <div className="correct-answer">Correct Answer :- {ReactHtmlParser(rightAnswer)}</div>
                                            {/* <p className="correct-answer">Description :- {description}</p> */}
                                            <p className="correct-answer">Detailed Answer :- {ReactHtmlParser(detailedAns)}</p>
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="pull-right">
                                {currentQuestion > 0 && (
                                    <button
                                        type="submit"
                                        className="btn nxt-prv"
                                        onClick={handlePreviousQuestionClick}
                                    >
                                        <i className="fas fa-angle-left" /> Prev
                                    </button>
                                )}

                                {currentQuestion < numberOfQuestions - 1 && (
                                    <button
                                        type="submit"
                                        className="btn nxt-prv"
                                        //disabled={!answer}
                                        onClick={handleNextQuestionClick}
                                    >
                                        Next <i className="fas fa-angle-right" />
                                    </button>
                                )}
                            </div>
                        </div>

                        {assessmentType !== "study" && (
                            <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                                <div className="pull-left" />
                                <div className="pull-right">
                                    <button
                                        type="submit"
                                        className="btn submit-ans-btn"
                                        // disabled={true}
                                        onClick={handleSubmitAnswer}
                                    >
                                        Submit Test
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

// Random component
const Completionist = () => <span>Time Up!</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
            <span>
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    }
};

export default TheoriesQuestions;
