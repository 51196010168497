import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser"

import { useAlert } from "react-alert";
import moment from "moment";
import Countdown, {
    zeroPad,
} from "react-countdown";

import Loader from "../Loader";
import { submitExamResult } from "./../../actions/resultActions";
import { SUBMIT_RESULT_RESET } from "./../../constants/resultConstants";

import "./mock.css";

import { getMockExamQuestions } from "../../actions/mockActions";
import Modal from "../Modal/Modal";
import Calculator from "../Calculator/Calculator";

const ObjectiveExam = ({ history }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false);

    const { user, loading, error } = useSelector((state) => state.auth);
    const userId = user._id;

    const examId = localStorage.getItem("examId");
    const examType = localStorage.getItem("examType");
    const subjects = localStorage.getItem("subjects");
    const year = localStorage.getItem("year");
    const shuffleQuestions = localStorage.getItem("shuffleQuestions");
    const shuffleOptions = localStorage.getItem("shuffleOptions");
    // alert.info(timeDuration);
    //var timerData = Date.now() + parseInt(timeDuration) * 60 * 1000;
    const assessmentId = localStorage.getItem("assessmentId");
    const assessmentType = localStorage.getItem("assessmentSlug");

    useEffect(() => {
        const questionCount = localStorage.getItem('questionCount');
        const subscriptionId = localStorage.getItem('userSubscription');
        dispatch(getMockExamQuestions(examId, examType, subjects,"", questionCount,subscriptionId));
    }, [alert, dispatch, examId, subjects, user]);

    const { mloading, mockQuestions, examData, questionCount } = useSelector(
        (state) => state.mockList
    );

    const [showCalculator, setShowCalculator] = useState(false);

    const [totalQuestions, setTotalQuestions] = useState(0);

    const [currentSubject, setCurrentSubject] = useState(0);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);

    const [answered, setAnswered] = useState([]);
    const [markForReviewed, setMarkForReviewed] = useState([]);

    const [showResults, setShowResults] = useState(false);
    const [rightAnswer, setRightAnswer] = useState("");
    const [description, setDescription] = useState("")
    const timeDuration = localStorage.getItem("timeDuration");

    const [totalTime, setTotalTime] = useState(
        Date.now() + parseInt(timeDuration) * 60 * 1000
    );
    // const [ totalTime, setTotalTime ] = useState(timeDuration);
    const [quizTotalTime, setQuizTotalTime] = useState("");
    const [remainingTime, setRemainingTime] = useState("");
    const [takenTime, setTakenTime] = useState("");
    // console.log("totalTime", totalTime);
    useEffect(() => {
        if (
            mockQuestions !== undefined &&
            mockQuestions.length > 0 &&
            mockQuestions[0].questions !== undefined &&
            mockQuestions[0].questions.length > 0
        ) {
            // var timerData = Date.now() + parseInt(timeDuration) * 60 * 1000;
            // setTotalTime(timerData);

            setTotalQuestions(questionCount);
            setNumberOfQuestions(mockQuestions[0].questions.length);
            // alert.success(numberOfQuestions);
        }
    }, [mockQuestions, currentQuestion, questionCount, rightAnswer]);

    const [selections, setSelections] = useState(
        mockQuestions !== undefined && mockQuestions.length > 0
            ? [Object.keys(mockQuestions).map((x) => [])]
            : []
    );

    const handleSubjectType = (e) => {
        e.preventDefault();
        const currentIndex = parseInt(e.target.dataset.id);

        setCurrentSubject(currentIndex);
        setCurrentQuestion(0);
        // alert.success(currentIndex);
        if (
            mockQuestions !== undefined &&
            mockQuestions.length > 0 &&
            mockQuestions[currentIndex].questions !== undefined &&
            mockQuestions[currentIndex].questions.length > 0
        ) {
            // alert.success(questions[currentIndex].questions.length);
            setNumberOfQuestions(mockQuestions[currentIndex].questions.length);
        }
    };

    // console.log("mockQuestions", mockQuestions);
    const onAnswerOptionChange = (event) => {
        const answerId = event.target.value;
        // alert.success(index);
        const selectedQuestionId = event.target.dataset.questionid;

        if (answered.find((obj) => obj.question === selectedQuestionId)) {
            let tempArr = answered;
            let newAns = tempArr.map((obj) => {
                if (obj.question === selectedQuestionId && obj.answer !== answerId) {
                    return { ...obj, question: selectedQuestionId, answer: answerId };
                }
                else {
                    return obj
                }
            })
            setAnswered(newAns);
        }
        else {
            let answeredArray = [
                ...answered,
                {
                    question: selectedQuestionId,
                    answer: answerId
                },
            ];
            setAnswered(answeredArray);
        }
        // let answeredArray = [
        //     ...answered,
        //     {
        //         question: selectedQuestionId,
        //         answer: event.target.value,
        //     },
        // ];
        // //remove duplicate answer
        // if (answered.some((ans) => ans.answer === event.target.value)) {
        //     answeredArray = answeredArray.filter(
        //         (item) => item.answer !== event.target.value
        //     );
        // }
        // //remove duplicate question
        // if (
        //     answered.some((ques) => ques.question === selectedQuestionId) &&
        //     answered.some((ques1) => ques1.answer !== event.target.value)
        // ) {
        //     answeredArray = answeredArray.filter((qItem) => {
        //         return (
        //             qItem.question !== selectedQuestionId
        //             // qItem.answer !== event.target.value
        //         );
        //     });
        //     answeredArray = [
        //         ...answeredArray,
        //         {
        //             question: selectedQuestionId,
        //             answer: event.target.value,
        //         },
        //     ];
        // }
        // setAnswered(answeredArray);

        if (selections[answerId]) {
            delete selections[answerId]
        }
        // set selection
        let tempState = { ...selections };
        tempState[answerId] = answerId;
        setSelections(tempState);
    };

    const handleNextQuestionClick = (e) => {
        e.preventDefault();
        const nextQuestion = parseInt(currentQuestion) + 1;
        setCurrentQuestion(nextQuestion);
        setShowResults(false);
    };
    // console.log(currentQuestion, numberOfQuestions);
    const handlePreviousQuestionClick = (e) => {
        e.preventDefault();
        const nextQuestion = parseInt(currentQuestion) - 1;
        setCurrentQuestion(nextQuestion);
        setShowResults(false);
    };

    const handleViewAnswer = (e) => {
        //alert.success(numberOfQuestions);
        e.preventDefault();
        if (
            mockQuestions !== undefined &&
            mockQuestions.length > 0 &&
            mockQuestions[currentSubject].questions !== undefined &&
            mockQuestions[currentSubject].questions.length > 0
        ) {
            const currentAns =
                mockQuestions[currentSubject].questions[currentQuestion].correctAns;
            const correctAnswer = mockQuestions[currentSubject].questions[
                currentQuestion
            ].options.filter((opItem) => opItem._id === currentAns);
            // console.log("correctAnswer ", correctAnswer);
            setRightAnswer(correctAnswer[0].option);
            setDescription(mockQuestions[currentSubject]?.questions[currentQuestion]?.description);
        }
        setShowResults(!showResults);
        // alert.success(rightAnswer);
    };

    const handleFilterQuestion = (e) => {
        e.preventDefault();
        const currentQue = e.target.dataset.id;
        // alert.info(currentQue);
        setCurrentQuestion(currentQue);
        setShowResults(false);
    };

    const handleMarkForReview = (event) => {
        let reviewArray = [...markForReviewed, event.target.value];
        if (markForReviewed.includes(event.target.value)) {
            reviewArray = reviewArray.filter((it) => it !== event.target.value);
        }
        setMarkForReviewed(reviewArray);
        // if (event.target.checked === true) {
        //   setDisabled(false);
        // }
        //alert.success("markForReviewed ", markForReviewed);
    };

    const handleRemainigTime = (data) => {
        // totalTime
        var quizHour = Math.floor(timeDuration / 60);
        quizHour = parseInt(quizHour) >= 10 ? quizHour : "0" + quizHour;
        var quizMinutes = timeDuration % 60 === 0 ? "00" : timeDuration % 60;
        var quizSecond = "00";
        const quizTime = quizHour + ":" + quizMinutes + ":" + quizSecond;

        const remainingMinutes =
            data.minutes < 10 ? "0" + data.minutes : data.minutes; // return minutes
        const remainingSeconds =
            data.seconds < 10 ? "0" + data.seconds : data.seconds;

        const remainTime =
            "0" + data.hours + ":" + remainingMinutes + ":" + remainingSeconds;
        var start = moment.duration(quizTime, "HH:mm:ss");
        var end = moment.duration(remainTime, "HH:mm:ss");
        var diff = start.subtract(end);

        const hour = diff.hours(); // return hours
        const minutes = diff.minutes() < 10 ? "0" + diff.minutes() : diff.minutes(); // return minutes
        const seconds = diff.seconds() < 10 ? "0" + diff.seconds() : diff.seconds();
        const usedTime = "0" + hour + ":" + minutes + ":" + seconds;

        setQuizTotalTime(quizTime);
        setRemainingTime(remainTime);
        setTakenTime(usedTime);

        if (remainTime === "00:00:01") {
            alert.info("Time up, your exam is submitting forcefully!!!", {
                onOpen: () => {
                    var arrSubjects = [];
                    mockQuestions.map(function (item) {
                        if (item.questions.length > 0) {
                            //return item.subject;
                            arrSubjects.push(item.subject._id);
                        }
                    });
                    if (answered.length > 0) {
                        dispatch(
                            submitExamResult(
                                userId,
                                examId,
                                answered,
                                assessmentId,
                                arrSubjects,
                                year,
                                totalQuestions,
                                quizTotalTime,
                                remainTime,
                                takenTime,
                                "",
                                examType
                            )
                        );
                    } else {
                        history.push(`/results`);
                    }
                },
            });
        }
    };

    /*if (remainingTime === "00:00:01") {
      alert.info("Time up, your exam is submitting forcefully!!!", {
        onOpen: () => {
          var arrSubjects = [];
          mockQuestions.map(function (item) {
            if (item.questions.length > 0) {
              //return item.subject;
              arrSubjects.push(item.subject._id);
            }
          });

          dispatch(
            submitExamResult(
              userId,
              examId,
              answered,
              assessmentId,
              arrSubjects,
              year,
              totalQuestions,
              quizTotalTime,
              remainingTime,
              takenTime,
              "",
              examType
            )
          );
          // history.push(`/results`);
        },
      });
    }*/

    const handleSubmitAnswer = (e) => {
        e.preventDefault();
        if (answered.length > 0) {
            if (
                window.confirm(
                    "Do you really want to submit your answer. We recommend you to review your answer before submitting it."
                )
            ) {
                var arrSubjects = [];
                mockQuestions.map(function (item) {
                    if (item.questions.length > 0) {
                        //return item.subject;
                        arrSubjects.push(item.subject._id);
                    }
                });

                dispatch(
                    submitExamResult(
                        userId,
                        examId,
                        answered,
                        assessmentId,
                        arrSubjects,
                        year,
                        totalQuestions,
                        quizTotalTime,
                        remainingTime,
                        takenTime,
                        "",
                        examType
                    )
                );
            }
        } else {
            //alert.warning("You have not answered any questions")
            if (
                window.confirm(
                    "You have not answered any questions, your test will be cancelled. do you really want to cancel?"
                )
            ) {
                alert.info("Test has been cancelled.");
                history.push(`/dashboard`);
            }
        }
        return;
    };

    const { rLoading, isSubmitted, result } = useSelector(
        (state) => state.results
    );
    // console.log("result", result);
    useEffect(() => {
        if (isSubmitted === true && isSubmitted != undefined) {
            alert.success("Result successfully submitted.");

            history.push(`/practice-results/${result.quizId}`);
            dispatch({
                type: SUBMIT_RESULT_RESET,
            });
        }
    }, [dispatch, rLoading, isSubmitted, alert]);

    useEffect(() => {
        return () => {
            window.addEventListener("beforeunload", (ev) => {
                ev.preventDefault();
                return (ev.returnValue = "Are you sure you want to close?");
            });
        };
    });

    return (
        <Fragment>
            {mloading ? (
                <Loader />
            ) : (
                <section className="auth-dash-sec fullHt">
                    <div className="left-navigations">
                        <div className="user-exam">
                            <div className="user-pros-exam">
                                <img
                                    src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/user.png"}
                                    alt="user"
                                />
                            </div>
                            <h4>
                                <b>Name</b>: {user.first_name} {user.last_name}
                            </h4>
                            <h4>
                                <i className="fas fa-calculator" onClick={() => setShowCalculator(true)} /> :{" "}
                                {localStorage.getItem("assessmentName")}
                            </h4>
                        </div>
                        <Modal title="Calculator" onClose={() => setShowCalculator(false)} show={showCalculator}>
                            <Calculator />
                        </Modal>
                        <div className="bot-examers">
                            {/*<h2>Subjects</h2>*/}
                            <div className="subject-tab">
                                <ul className="nav nav-tabs" role="tablist">
                                    {mockQuestions !== undefined &&
                                        mockQuestions.length > 0 &&
                                        mockQuestions.map((subItem, i) => {
                                            if (subItem.questions.length > 0) {
                                                return (
                                                    <Fragment key={i}>
                                                        <li
                                                            role="presentation"
                                                            className={currentSubject === i ? "active" : ""}
                                                        >
                                                            <a
                                                                href="#"
                                                                data-id={i}
                                                                onClick={handleSubjectType}
                                                            >
                                                                {subItem.subject.title}
                                                            </a>
                                                        </li>
                                                    </Fragment>
                                                );
                                            }
                                        })}
                                </ul>
                            </div>

                            <div className="number-lister">
                                {mockQuestions !== undefined &&
                                    mockQuestions.length > 0 &&
                                    mockQuestions[currentSubject].questions !== undefined &&
                                    mockQuestions[currentSubject].questions.length > 0 &&
                                    [
                                        ...Array(mockQuestions[currentSubject].questions.length),
                                    ].map((e, i) => {
                                        let className = "";
                                        if (
                                            markForReviewed.indexOf(
                                                mockQuestions[currentSubject].questions[i]._id
                                            ) > -1
                                        ) {
                                            className = "markForReviewQuestion";
                                        } else if (
                                            answered.find(
                                                (obj) =>
                                                    obj.question ==
                                                    mockQuestions[currentSubject].questions[i]._id
                                            )
                                        ) {
                                            className = "markForAnsweredQuestion";
                                        } else if (currentQuestion === i) {
                                            className = "isActiveQuestion";
                                        }
                                        return (
                                            <Fragment key={i}>
                                                <a
                                                    className={className}
                                                    href="#"
                                                    data-id={i}
                                                    onClick={handleFilterQuestion}
                                                >
                                                    {i + 1}
                                                </a>{" "}
                                            </Fragment>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <div className="right-auth-landing edit-pros">
                        <div className="main-view-content">
                            {assessmentType !== "study" && (
                                <div className="all-ourt-style timer w-80-cust legends-reviews clearfix">
                                    <div className="pull-left legend-label">
                                        <div>Time Remaining</div> &nbsp;&nbsp;
                                        <div>
                                            <Countdown
                                                date={totalTime}
                                                //zeroPadTime={2}
                                                daysInHours={true}
                                                controlled={false}
                                                onTick={handleRemainigTime}
                                            //renderer={renderer}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <Fragment>
                                <div className="all-ourt-style w-80-cust legends-reviews clearfix">
                                    <div className="pull-left legend-label">
                                        <p>
                                            <span style={{ background: "white" }} /> Un Answered
                                        </p>
                                        <p>
                                            <span style={{ background: "green" }} /> Answered
                                        </p>
                                        <p>
                                            <span style={{ background: "red" }} /> Marked for Review
                                        </p>
                                    </div>
                                    {mockQuestions !== undefined &&
                                        mockQuestions.length > 0 &&
                                        mockQuestions[currentSubject].questions !== undefined &&
                                        mockQuestions[currentSubject].questions.length > 0 && (
                                            <div className="pull-right">
                                                <div
                                                    className="boxes"
                                                    style={{ display: "inline-block" }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        name={`review-mark-${mockQuestions[currentSubject].questions[currentQuestion]._id}`}
                                                        id={`box-${mockQuestions[currentSubject].questions[currentQuestion]._id}`}
                                                        value={
                                                            mockQuestions[currentSubject].questions[
                                                                currentQuestion
                                                            ]._id
                                                        }
                                                        onChange={handleMarkForReview}
                                                        checked={
                                                            markForReviewed.indexOf(
                                                                mockQuestions[currentSubject].questions[
                                                                    currentQuestion
                                                                ]._id
                                                            ) > -1
                                                        }
                                                    />
                                                    <label
                                                        htmlFor={`box-${mockQuestions[currentSubject].questions[currentQuestion]._id}`}
                                                    >
                                                        Mark for Review
                                                    </label>
                                                </div>
                                                {/*:<span>(1) Question</span>*/}
                                            </div>
                                        )}
                                </div>

                                {mockQuestions !== undefined && mockQuestions.length > 0 && mockQuestions[currentSubject].questions.length > 0 && (
                                    <Fragment>
                                        {mockQuestions[currentSubject].questions.length > 0 && mockQuestions[currentSubject].questions[currentQuestion]
                                            .passage !== undefined && mockQuestions[currentSubject].questions[currentQuestion]
                                                .passage !== "" && (
                                                <div className="all-ourt-style w-80-cust work-info exam-set">
                                                    <div className="all-heads clearfix">
                                                        <h2>
                                                            {
                                                                mockQuestions[currentSubject].questions[
                                                                    currentQuestion
                                                                ].title
                                                            }
                                                        </h2>
                                                    </div>
                                                    <div className="exam-main-style">
                                                        <div className="answer-selection">
                                                            <div className="cust-redio">
                                                                {mockQuestions[currentSubject].questions[
                                                                    currentQuestion
                                                                ].options !== undefined && (
                                                                        <Fragment>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        mockQuestions[currentSubject].questions[
                                                                                            currentQuestion
                                                                                        ].passage,
                                                                                }}
                                                                            />
                                                                        </Fragment>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        <div className="all-ourt-style w-80-cust work-info exam-set">
                                            <div className="all-heads clearfix">
                                                <h2 className="question-title">
                                                    <p>
                                                        {parseInt(currentQuestion) + 1}
                                                        {". "}
                                                    </p>{" "}
                                                    <p
                                                        key={parseInt(currentQuestion)}
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                mockQuestions[currentSubject].questions[
                                                                    currentQuestion
                                                                ].question,
                                                        }}
                                                    />
                                                </h2>
                                            </div>

                                            {mockQuestions[currentSubject].questions[
                                                currentQuestion
                                            ].logo !== undefined && mockQuestions[currentSubject].questions[
                                                currentQuestion
                                            ].logo !== null && (
                                                    <Fragment>
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_PUBLIC_URL +
                                                                "/assets/img/questions/" +
                                                                mockQuestions[currentSubject].questions[
                                                                    currentQuestion
                                                                ].logo
                                                            }
                                                            alt="question-image"
                                                            style={{ height: "200px" }}
                                                        />
                                                    </Fragment>

                                                )}

                                            <div className="exam-main-style">
                                                <div className="answer-selection">
                                                    <div className="cust-redio">
                                                        {mockQuestions[currentSubject].questions[
                                                            currentQuestion
                                                        ].options !== undefined &&
                                                            mockQuestions[currentSubject].questions[
                                                                currentQuestion
                                                            ].options.length > 0
                                                            ? mockQuestions[currentSubject].questions[
                                                                currentQuestion
                                                            ].options.map((answerOption, key) => {
                                                                if (answerOption.option !== "") {
                                                                    const indexId = key + 1;
                                                                    return (
                                                                        <Fragment key={indexId}>
                                                                            <label htmlFor={`option-${indexId}`}>
                                                                                <input
                                                                                    type="radio"
                                                                                    name={`option-${mockQuestions[currentSubject].questions[currentQuestion]._id}`}
                                                                                    id={`option-${indexId}`}
                                                                                    value={answerOption._id}
                                                                                    //value={`${questions[currentQuestion]._id}-${answerOption._id}`}
                                                                                    // onClick={onChange}
                                                                                    onChange={(e) =>
                                                                                        onAnswerOptionChange(e)
                                                                                    }
                                                                                    data-questionid={
                                                                                        mockQuestions[currentSubject]
                                                                                            .questions[currentQuestion]._id
                                                                                    }
                                                                                    checked={
                                                                                        answered && answered?.find((obj) => obj?.question === mockQuestions[currentSubject].questions[currentQuestion]._id)?.answer === answerOption._id
                                                                                        // selections[
                                                                                        // answerOption._id
                                                                                        // ] === answerOption._id
                                                                                    }
                                                                                // checked={
                                                                                //     selections[answerOption._id] ===
                                                                                //     answerOption._id
                                                                                // }
                                                                                />
                                                                                <span>{ReactHtmlParser(answerOption.option)}</span>
                                                                            </label>
                                                                        </Fragment>
                                                                    );
                                                                }
                                                            })
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </Fragment>

                            <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                                {assessmentType === "study" && (
                                    <div className="pull-left">
                                        <button
                                            type="button"
                                            className="btn view-ans-btn"
                                            onClick={handleViewAnswer}
                                        >
                                            {!showResults ? "View Answer" : "Hide Answer"}
                                        </button>
                                        {rightAnswer !== undefined && showResults && (
                                            <>
                                                <div className="correct-answer">{ReactHtmlParser(rightAnswer)}</div>
                                                <p className="correct-answer">{ReactHtmlParser(description)}</p>
                                            </>
                                        )}
                                    </div>
                                )}
                                <div className="pull-right">
                                    {currentQuestion > 0 && (
                                        <button
                                            type="submit"
                                            className="btn nxt-prv"
                                            onClick={handlePreviousQuestionClick}
                                        >
                                            <i className="fas fa-angle-left" /> Prev
                                        </button>
                                    )}

                                    {currentQuestion < numberOfQuestions - 1 && (
                                        <button
                                            type="submit"
                                            className="btn nxt-prv"
                                            //disabled={!answer}
                                            onClick={handleNextQuestionClick}
                                        >
                                            Next <i className="fas fa-angle-right" />
                                        </button>
                                    )}
                                </div>
                            </div>

                            <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                                <div className="pull-left" />
                                <div className="pull-right">
                                    <button
                                        type="submit"
                                        className="btn submit-ans-btn"
                                        // disabled={true}
                                        onClick={handleSubmitAnswer}
                                    >
                                        Submit Test
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    );
};

// Random component
const Completionist = () => <span>Time Up!</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
            <span>
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    }
};

export default ObjectiveExam;
