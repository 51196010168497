import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";

import { Editor } from '@tinymce/tinymce-react';

import { useDispatch, useSelector } from "react-redux";
import {
  getInstructionDetail,
  updateInstruction,
  clearErrors,
} from "../../../actions/adminInstructionActions";
import { UPDATE_INSTRUCTION_RESET } from "./../../../constants/adminInstructionConstants";

import Loader from "../../Loader";

import "./Instruction.css";

const EditInstruction = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [instructionId, setInstructionId] = useState("");
  const [content, setContent] = useState("");
  const [status, setStatus] = useState(false);

  const [errorData, setErrorData] = useState({});

  const { isUpdated, user_loading, error, errors } = useSelector(
    (state) => state.instructionList
  );

  const currentUserId = match.params.id;
  useEffect(() => {
    dispatch(getInstructionDetail(currentUserId));
  }, [dispatch]);

  const { instruction } = useSelector((state) => state.instructionDetail);

  useEffect(() => {
    if (errors) {
      setErrorData(errors);
    }

    if (instruction) {
      setInstructionId(instruction._id);
      setContent(instruction.content);
      setStatus(instruction.status);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Instruction updated successfully");
      dispatch(getInstructionDetail(instruction._id));
      history.push(`/admin/exam-instructions`);
      dispatch({
        type: UPDATE_INSTRUCTION_RESET,
      });
    }
  }, [dispatch, instruction, alert, error, errors, history, isUpdated]);

  const changeEditorText = (content) => {
		setContent(content);
	};

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateInstruction(instructionId, content, status)
    );
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>Instruction</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/instructions">
                    <i className="fa fa-users"></i> Instructions
                  </Link>
                </li>
                <li className="active">Edit Instruction</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Instruction</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>

                <form
                  id="quickForm"
                  onSubmit={submitHandler}
                  encType="multipart/form-data"
                >
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="title">Instruction</label>
                        <Editor
													apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
													init={{
														height: 200,
														menubar: false,
														external_plugins: {},
														plugins: [
															'advlist autolink lists link image imagetools charmap print preview anchor',
															'searchreplace visualblocks code fullscreen',
															'insertdatetime media table paste code help wordcount'
														],
														toolbar:
															'undo redo | link image | formatselect ' +
															'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
															'bullist numlist outdent indent | ' +
															'removeformat | code | help',
														content_style:
															'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
														file_picker_types: 'image'
													}}
													value={content}
													// initialValue={question}
													onEditorChange={(newText) => changeEditorText(newText)}
												/>
                        {/*<ReactSummernote
                          value={content}
                          options={{
                            lang: "en-US",
                            height: 250,
                            dialogsInBody: false,
                            toolbar: [
                              ["style", ["style"]],
                              [
                                "font",
                                ["bold", "italic", "underline", "clear"],
                              ],
                              ["fontname", ["fontname"]],
                              ["color", ["color"]],
                              ["para", ["ul", "ol", "listStyles", "paragraph"]],
                              ["table", ["table"]],
                              ["insert", ["link", "picture", "video"]],
                              ["view", ["fullscreen", "codeview"]],
                            ],
                          }}
                          onChange={onEditorDataChange}
                          onImageUpload={onImageUpload}
                        />*/}
                        {errorData.content ? (
                          <span className="text-danger">{errorData.content}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      
                    </div>

                    <div className="form-group row">
                    <div className="col-md-6">
                    <label htmlFor="status">Status </label>
                      <input
                        style={{ marginRight: "5px" }}
                        type="checkbox"
                        className="form-check-input"
                        id="status"
                        checked={status}
                        value={status}
                        onChange={(e) => setStatus(!status)}
                      />
                  </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditInstruction;
