import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import ReactHtmlParser from "react-html-parser"
import Slider from "react-rangeslider";

import moment from "moment";
import Loader from "../Loader";

import {
  getResultData,
  reviewAndGradeResult,
} from "./../../actions/resultActions";

import { SUBMIT_GRADE_RESULT_RESET } from "./../../constants/resultConstants";

import "./result.css";

const TheoriesResultView = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const assessmentTitle = queryParams.get("assessmentTitle");

  const { user, loading, error } = useSelector((state) => state.auth);
  const userId = user._id;
  const quizId = match.params.quizId;
  useEffect(() => {
    dispatch(getResultData(quizId));
  }, [dispatch, alert]);

  const { rLoading, result } = useSelector((state) => state.results);
  // console.log("result ", result);
  useEffect(() => { }, [result, rLoading]);
  const [currentSubject, setCurrentSubject] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);

  //set grade
  const [grades, setGrades] = useState([]);

  const handleSubjectType = (e) => {
    e.preventDefault();
    const currentIndex = parseInt(e.target.dataset.id);
    setCurrentSubject(currentIndex);
    setCurrentQuestion(0);
    if (
      result !== undefined &&
      result.subjectResults !== undefined &&
      result.subjectResults.length > 0 &&
      result.subjectResults[currentIndex].results !== undefined &&
      result.subjectResults[currentIndex].results.length > 0
    ) {
      setNumberOfQuestions(result.subjectResults[currentIndex].results.length);
    }
  };

  const handleQuestionTick = (e) => {
    e.preventDefault();
    const currentQue = e.target.dataset.id;
    // alert.info(currentQue);
    setCurrentQuestion(currentQue);
  };

 
  const handleChangeGrade = (gradeVal) => {
    if (
      result !== undefined &&
      result.subjectResults !== undefined &&
      result.subjectResults.length > 0 &&
      result.subjectResults[currentSubject].results !== undefined &&
      result.subjectResults[currentSubject].results.length > 0 &&
      result.subjectResults[currentSubject].results[currentQuestion]
        .resultsDetail !== undefined &&
      result.subjectResults[currentSubject].results[currentQuestion]
        .resultsDetail.theoryAnswered
    ) {
      var answerId =
        result.subjectResults[currentSubject].results[currentQuestion]
          .resultsDetail._id;

      let gradeArray = [
        ...grades,
        {
          answerId: answerId,
          ansGrade: gradeVal,
        },
      ];
      //remove duplicate answer
      // if (grades.some((ans) => ans.ansGrade === gradeVal)) {
      // 	gradeArray = gradeArray.filter((item) => item.ansGrade !== gradeVal);
      // }
      //remove duplicate question
      if (
        grades.some((ques) => ques.answerId === answerId) &&
        grades.some((ques1) => ques1.ansGrade !== gradeVal)
      ) {
        gradeArray = gradeArray.filter((qItem) => {
          return (
            qItem.answerId !== answerId
            // qItem.ansGrade !== gradeVal
          );
        });
        gradeArray = [
          ...gradeArray,
          {
            answerId: answerId,
            ansGrade: gradeVal,
          },
        ];
      }
      setGrades(gradeArray);

      // 60e69983875a66203911d6c4
    }
  };

  const verticalLabels = {
    0: "0% No Attempt/Very Poor Attempt",
    25: "25% Appropriate Methodology",
    50: "50% Moderate Work Accuracy",
    75: "75% High Work Accuracy",
    100: "100% Appropriate Methodology + Excellent Work Accuracy + Correct Final Answer",
  };

  const formatPc = (p) => p + "%";

  const handleSubmitGrade = (e) => {
    e.preventDefault();
    // console.log('grades', grades);
    if (grades.length > 0) {
      dispatch(reviewAndGradeResult(userId, quizId, grades));
    } else {
      alert.error("Please review and grade all questions to submit.");
      return false;
    }
  };

  const { isGraded, gradeResult } = useSelector((state) => state.results);

  useEffect(() => {
    if (isGraded === true && isGraded != undefined) {
      alert.success("Result graded successfully.");
      history.push(`/theories-results/${quizId}`);
      dispatch({
        type: SUBMIT_GRADE_RESULT_RESET,
      });
    }
  }, [dispatch, isGraded, alert, gradeResult]);

  return (
    <Fragment>
      {rLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <section className="auth-dash-sec fullHt" style={{ padding: "0px" }}>
            <div className="left-navigations">
              <div className="user-exam">
                <div className="user-pros-exam">
                  <img
                    src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/user.png"}
                    alt="user"
                  />
                </div>
                <h4>
                  <b>Name</b>: {user.first_name} {user.last_name}
                </h4>
                <h4>
                  <i className="fas fa-calculator" /> : {assessmentTitle}
                </h4>
              </div>
              <div className="bot-examers">
                <h2>Subjects</h2>
                <div className="subject-tab">
                  <ul className="nav nav-tabs" role="tablist">
                    {result !== undefined &&
                      result.subjectResults !== undefined &&
                      result.subjectResults.length > 0 &&
                      result.subjectResults.map((subItem, i) => {
                        return (
                          <Fragment key={i}>
                            <li
                              role="presentation"
                              className={currentSubject === i ? "active" : ""}
                            >
                              <a
                                href="#"
                                data-id={i}
                                onClick={handleSubjectType}
                              >
                                {subItem.subject.title}
                              </a>
                            </li>
                          </Fragment>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="right-auth-landing edit-pros">
              <div className="main-view-content">
                <div className="all-ourt-style w-80-cust clearfix">
                  <div className="sel-exam-quest">
                    <h4 style={{ fontSize: 17 }}>Questions</h4>
                    <div className="quet-lister" style={{ marginTop: 0 }}>
                      {result !== undefined &&
                        result.subjectResults !== undefined &&
                        result.subjectResults.length > 0 &&
                        result.subjectResults[currentSubject].results !==
                        undefined &&
                        result.subjectResults[currentSubject].results.length >
                        0 &&
                        [
                          ...Array(
                            result.subjectResults[currentSubject].results.length
                          ),
                        ].map((e, i) => {
                          let className = "";
                          if (currentQuestion === i) {
                            className = "isActiveQuestion active";
                          }
                          return (
                            <Fragment key={i}>
                              <a
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  lineHeight: "27px",
                                }}
                                className={className}
                                href="#"
                                data-id={i}
                                onClick={handleQuestionTick}
                              >
                                {i + 1}
                              </a>{" "}
                            </Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
                {result !== undefined &&
                  result.subjectResults !== undefined &&
                  result.subjectResults.length > 0 &&
                  result.subjectResults[currentSubject].results !== undefined &&
                  result.subjectResults[currentSubject].results.length > 0 && (
                    <Fragment>
                      <div className="all-ourt-style w-80-cust work-info exam-set">
                        <div className="all-heads clearfix">
                          <h2>
                            {parseInt(currentQuestion) + 1}
                            {". "}
                            {
                              ReactHtmlParser(result.subjectResults[currentSubject].results[
                                currentQuestion
                              ]?.theory?.title)
                            }
                            {
                              ReactHtmlParser(result.subjectResults[currentSubject].results[
                                currentQuestion
                              ]?.theory?.question)
                            }

                            {result.subjectResults[currentSubject].results[
                              currentQuestion
                            ].theory.logo !== "" && <Fragment>
                                <img
                                  src={
                                    process.env.REACT_APP_PUBLIC_URL +
                                    "/assets/img/questions/" +
                                    result.subjectResults[currentSubject].results[
                                      currentQuestion
                                    ].theory.logo
                                  }
                                  alt="question-image"
                                  style={{ height: "200px" }}
                                />
                              </Fragment>}
                          </h2>
                        </div>
                        <div className="lister-opt-ans">
                          <div className="row">
                            <div className="col-sm-12">
                              <h3 style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 16 }}>Answered</span>:
                                {ReactHtmlParser(result !== undefined &&
                                  result.subjectResults !== undefined &&
                                  result.subjectResults.length > 0 &&
                                  result.subjectResults[currentSubject]
                                    .results !== undefined &&
                                  result.subjectResults[currentSubject].results
                                    .length > 0 &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].resultsDetail !== undefined &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].resultsDetail.theoryAnswered)}
                              </h3>
                            </div>
                            <div className="col-sm-12">
                              <h3 style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 16 }}>
                                  Correct Answer
                                </span>
                                :
                                {ReactHtmlParser(result !== undefined &&
                                  result.subjectResults !== undefined &&
                                  result.subjectResults.length > 0 &&
                                  result.subjectResults[currentSubject]
                                    .results !== undefined &&
                                  result.subjectResults[currentSubject].results
                                    .length > 0 &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].resultsDetail !== undefined &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].resultsDetail.theoryCorrectAnswer)}
                              </h3>
                            </div>
                            <div className="col-sm-12">
                              <h3 style={{ fontSize: 15 }}>
                                <span style={{ fontSize: 16 }}>
                                  Detailed Answer
                                </span>
                                :
                                {ReactHtmlParser(result !== undefined &&
                                  result.subjectResults !== undefined &&
                                  result.subjectResults.length > 0 &&
                                  result.subjectResults[currentSubject]
                                    .results !== undefined &&
                                  result.subjectResults[currentSubject].results
                                    .length > 0 &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].theory !== undefined &&
                                  result.subjectResults[currentSubject].results[
                                    currentQuestion
                                  ].theory.detailedAnswer)}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="all-ourt-style w-80-cust clearfix">
                        <div className="sel-exam-quest">
                          <h4 style={{ fontSize: 17 }}>Mark And Grade</h4>
                          <div className="mark-of-grade">
                            {/*<Slider
												value={grade}
												orientation="vertical"
												labels={verticalLabels}
												// handleLabel={grade}
												format={formatPc}
												onChange={handleChangeGrade}
											/>
                      <div className='value'>{formatPc(grade)}</div>*/}

                            <Slider
                              value={
                                result.subjectResults[currentSubject].results[
                                  currentQuestion
                                ].resultsDetail.resultGrade !== undefined
                                  ? result.subjectResults[currentSubject]
                                    .results[currentQuestion].resultsDetail
                                    .resultGrade
                                  : 0
                              }
                              orientation="vertical"
                              labels={verticalLabels}
                              // handleLabel={grade}
                              format={formatPc}
                            // onChange={handleChangeGrade}
                            />
                            <div className="value">
                              {formatPc(
                                result.subjectResults[currentSubject].results[
                                  currentQuestion
                                ].resultsDetail.resultGrade !== undefined
                                  ? result.subjectResults[currentSubject]
                                    .results[currentQuestion].resultsDetail
                                    .resultGrade
                                  : 0
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}

                <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
                  <div className="pull-left">
                    <Link
                      to={`/theories-results/${quizId}`}
                      className="btn view-ans-btn"
                    >
                      <i className="fas fa-angle-left"></i> Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default TheoriesResultView;
