import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import {
  getSubjects,
  deleteSubjectData,
} from "../../../actions/adminSubjectActions";
//import { deleteUserData } from '../../../actions/commonActions';

import Loader from "../../Loader";
// import Loader from "react-loader-spinner";

import CustomDataTable from "./../../Datatable/CustomDataTable";

const ListSubjects = ({ history }) => {
  // const [users, setUsersData] = useState({});
  const alert = useAlert();
  const dispatch = useDispatch();

  const handleDeleteSubject = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteSubjectData(id));
            // history.push(`/admin/subjects`);
            alert.success("Subject deleted successfully");
            // dispatch(getSubjects());
          },
        },
        {
          label: "No",
          onClick: () => alert.success("No data deleted"),
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(getSubjects());
  }, [dispatch]);

  const { subjects, user_loading } = useSelector((state) => state.subjects);

  const tableHeader = [
    {
      title: "Subject",
      prop: "subject",
      sortable: true,
      filterable: true,
    },
    {
      title: "Icon",
      prop: "icon",
      sortable: true,
      filterable: true,
    },
    {
      title: "Exam",
      prop: "exam",
      sortable: true,
      filterable: true,
    },
    {
      title: "University",
      prop: "university",
      sortable: true,
      filterable: true,
    },
    {
      title: "Status",
      prop: "status",
      sortable: true,
      filterable: true,
    },
    {
      title: "Action",
      prop: "action",
    },
    { title: "Topics", prop: "topics" },
  ];

  var tableRowData = [];
  if (subjects !== undefined && subjects.length > 0) {
    subjects.map((item, key) => {
      const university = item?.university && item?.university.length > 0 && item?.university !== null && item?.university[0] !== undefined ? item?.university[0]?.title : "--";
      
      let itemData = {
        subject: item.title,
        icon: (
          <img
            src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/subjects/" + item.logo}
          />
        ),
        exam:
          item.exam !== undefined && item.exam !== null
            ? item.exam.title
            : "--",
        university: university,
        status:
          item.status === true ? (
            <span className="badge bg-green">Active</span>
          ) : (
            <span className="badge bg-yellow">InActive</span>
          ),
        action: (
          <Fragment>
            <Link
              to={`/admin/subjects/edit/${item._id}`}
              className="btn btn-success btn-sm"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </Link>
            <a
              className="btn btn-danger btn-sm"
              title="Delete"
              onClick={() => handleDeleteSubject(item._id)}
              key={item._id}
            >
              <i className="fa fa-trash" />
            </a>
          </Fragment>
        ),
        topics: (
          <Link
            to={`/admin/topics/${item._id}`}
            className="btn btn-default btn-sm"
            title="View"
          >
            <i className="fa fa-eye" />
          </Link>
        ),
      };
      tableRowData.push(itemData);
    });
  }
  // console.log("subjects", subjects);
  const onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      //return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display",
    entries: "rows",
    noResults: "There are no data to be displayed",
  };

  return (
    <Fragment>
      {user_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Manage
                <small>Subjects</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard" /> Dashboard
                  </Link>
                </li>
                <li className="active">Subjects</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-xs-12">
                  <div className="box">
                    <div className="box-header">
                      <h3 className="box-title">Subjects</h3>
                      <div className="box-tools">
                        <Link
                          to={"/admin/subjects/add"}
                          className="btn btn-success btn-sm"
                        >
                          + Add Subject
                        </Link>
                      </div>
                    </div>
                    <div className="box-body table-responsive">
                      <div>
                        <CustomDataTable
                          tableHeaders={tableHeader}
                          tableBody={tableRowData}
                          rowsPerPage={5}
                          rowsPerPageOption={[5, 10, 15, 20]}
                          initialSort={{ prop: "username", isAscending: true }}
                          // onSort={onSortFunction}
                          labels={customLabels}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListSubjects;
