import React, { Fragment, useEffect, useState } from 'react'
import Loader from '../Loader'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from 'react-redux';
import { getSavedExamSubjects, saveExamSubjects } from '../../actions/admin/examSubjectAction';
import { getCourseSubjects } from '../../actions/subjectActions';
import { confirmAlert } from 'react-confirm-alert';
import { getUserSubscription } from '../../actions/subscriptionActions';
// import 'react-select/css/default.css';
// import 'react-select/dist/react-select.css';


const UpdateExamSubjects = ({ history }) => {
    const animatedComponents = makeAnimated();
    const [proceedSubjects, setProceedSubjects] = useState([]);
    const { savedSubjects } = useSelector((state) => state.examSubjects);
    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const [existFlag, setExistFlag] = useState(false);
    const userId = user._id;
    const dispatch = useDispatch();

    const [registeredSubject, setRegisteredSubject] = useState([]);

    const { courseSubjects, csloading } = useSelector((state) => state.courseSubjects);
    const { userSubscription, sloading } = useSelector((state) => state.examSubscriptions);

    const [subjects, setSubjectsList] = useState([]);
    const [UpdateSubjects, setUpdateSubjects] = useState([]);
    const courseId = localStorage.getItem("courseId");
    const userSubscriptionLocal = localStorage.getItem("userSubscription");
    const categoryId = localStorage.getItem("category");
    const examId = localStorage.getItem("examId");
    // const userId = localStorage.getItem("user");
    const subscriptionPlan = localStorage.getItem("subscriptionPlan");
    const [examIds, setExamIds] = useState("");



    useEffect(() => {
        if (courseId && examId) {
            dispatch(getCourseSubjects(courseId, examId));
        }

        if (userId) {
            dispatch(getSavedExamSubjects(userId));
        }
        if (userId &&  examId) {
            const id =  examId;
           
            dispatch(getUserSubscription(userId, id));
        }

        return () => {

        }
    }, [courseId, examId, userId, examId])


    useEffect(() => {
        let temp = [];
        let temp1 = [];
        if (savedSubjects && savedSubjects?.length > 0) {
            savedSubjects?.map((item) => {
                // setExamIds(item?.exam?._id)
                if (item?.exam?._id == examId) {
                    item?.subjectsProcessed?.map((item) => {
                        temp.push(item)
                    })
                    setProceedSubjects(temp)
                    item.subjects?.map((item) => {
                        temp1.push(item.subject)
                    })
                    setRegisteredSubject(temp1);
                }
            })



        }
        return () => {

        }
    }, [savedSubjects])

    useEffect(() => {
        if (courseSubjects !== undefined && courseSubjects?.length > 0) {
            const tmpArr = [];
            courseSubjects?.map((course) => {
                tmpArr?.push({ label: course?.title, value: course?._id })
            })
            setSubjectsList(tmpArr)
        }
    }, [courseSubjects])


    const handleChange = (newItem, item) => {
        if (proceedSubjects.includes(newItem.value)) {
            confirmAlert({
                title: 'Error',
                message: (
                    <div>
                        <p>
                            Subject already exists.
                        </p>

                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                        },
                    },
                ],
            });
        } else if (!proceedSubjects.includes(newItem.value)) {
            let tmp = [];
            tmp.push(newItem.value);
            setUpdateSubjects(tmp);
            setExistFlag(false);
            const updatedSubjects = proceedSubjects.map((subject) =>
                subject == item.id ? newItem.value : subject
            );

            setProceedSubjects(updatedSubjects)
            const temp = [...subjects];
            const tempArr = temp.filter((item) => item.value !== newItem.value)
            setSubjectsList(tempArr);
        }


    }
    const generateStyledSubjects = (subjectIds) => {
        return subjectIds.map((id, index) => {
            const subject = courseSubjects.find((c) => c?._id === id);
            const isMandatory = subject?.mandetory === true;

            return (
                <span key={id} style={{ fontWeight: isMandatory ? 'bold' : 'normal', color: isMandatory ? 'red' : 'black' }}>
                    <strong>({index + 1}) </strong>{subject ? subject.title : ''}{' '}
                </span>
            );
        });
    };


    const submitHandler = (e) => {

        if (!userSubscription || userSubscription?._id == null || userSubscription?._id == undefined) {
            confirmAlert({
                title: 'Error',
                message: (
                    <div>
                        <p>
                            Subscription id {userSubscription?._id} missing.
                        </p>

                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                        },
                    },
                ],
            });
        }
        e.preventDefault();
        if (UpdateSubjects?.length !== 0) {
            const selectedSubjectsInfo = generateStyledSubjects(proceedSubjects)
            
            confirmAlert({
                title: 'Confirmation',
                message: (
                    <div>
                        <p>
                            Are you sure you want to proceed these <strong className="highlighted" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}>{selectedSubjectsInfo}</strong> subjects?
                        </p>


                        <p>
                            Click "Yes" to confirm.
                        </p>
                        <p className="bg-danger" style={{ color: 'red' }}>
                            Note: You cannot change the selected subject after proceed.
                        </p>
                    </div>
                ),
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            
                            const payload = {
                                userId: user._id,
                                examId: examId,
                                categoryId: categoryId,
                                courseId: courseId,
                                userSubscription: userSubscription ? userSubscription?._id : userSubscriptionLocal,
                                subjects: [],
                                subjectsProcessed: [],
                                subjectsUpdate: [],
                                subjectsUpdate: proceedSubjects,
                                updateRequest: false
                            }
                            
                            dispatch(saveExamSubjects(
                                payload
                            ))
                            // history.push(`/saved-subjects`);
                            history.push(`/exam-type`);
                        },
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            // Handle user's cancellation
                        },
                    },
                ],
            });
        }
        else if (UpdateSubjects?.length == 0) {
            confirmAlert({
                title: 'Error',
                message: (
                    <div>
                        <p>
                            Please select at least one new subject to proceed.
                        </p>

                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                        },
                    },
                ],
            });
        }

    }

    // const user_loading = false;
    return (
        <Fragment>
            {csloading || sloading ? (
                <Loader />
            ) : (
                <Fragment>

                    <div className="right-auth-landing">
                        <div className="main-view-content">
                            <div className="all-ourt-style work-info">
                                <div className="all-heads">
                                    <h3>Saved Exams Subjects</h3>
                                </div>

                                <div className="box-header with-border">
                                    <h3 className="box-title">Update Registered Subjects</h3>
                                    <p className='text-danger'>Note :- Mandatory subjects can not be replaced</p>

                                </div>

                                <form
                                    id="quickForm"
                                    onSubmit={submitHandler}
                                    encType="multipart/form-data"
                                >
                                    <div className="box-body">
                                        {registeredSubject && registeredSubject.length > 0 && registeredSubject.map((item, index) => {
                                            return (
                                                <div className="form-group row" key={index}>
                                                    <div className="col-md-6">
                                                        Replace {" "}<label htmlFor="category"> {item.title}{" "}{item.mandetory&&"(Mandatory)"} </label> {" "}with
                                                        <Select
                                                            value={subjects.value}
                                                            onChange={(newItem) => handleChange(newItem, item)}
                                                            options={subjects}
                                                            isMulti={false}  // Set isMulti to false for single select
                                                            components={animatedComponents}
                                                            isSearchable={true}
                                                            isDisabled={item.mandetory}
                                                            
                                                        />

                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Update
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                </Fragment>
            )}
        </Fragment>
    )
}

export default UpdateExamSubjects