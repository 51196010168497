import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { useDispatch } from "react-redux";
import { loadUser } from "./actions/authActions";
import AppRouter from "./AppRouter";

import "./App.css";

function App() {
  const dispatch = useDispatch();
  //const { isUpdated, user_loading, error } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  // if (process.env.REACT_APP_MAINTENANCE_MODE !== false) {
  //   return <Maintenance />;
  // }

  if (process.env.REACT_APP_BASE_NAME === "beta") {
    return (
      <BrowserRouter basename="/beta">

        <AppRouter />
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    );
  }
}

export default App;
