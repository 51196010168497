import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import { addTheory, clearErrors } from "../../../actions/adminTheoryActions";
import { ADD_THEORY_RESET } from "./../../../constants/adminTheoryConstants";

import { Editor } from "@tinymce/tinymce-react";

import Loader from "../../Loader";

import "./Theory.css";
import { confirmAlert } from "react-confirm-alert";

const AddTheory = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    

    const [theoryData, setQuestionData] = useState({
        title: "",
        question: "",
        description: "",
        detailed_answer: "",
        logo: "",
        status: false,
        imageName: "",
    });
    const [errorData, setErrorData] = useState({});

    const {
        title,
        question,
        description,
        detailed_answer,
        status,
        logo,
        imageName
    } = theoryData;

    const subjectId = match.params.subjectId;
    const yearId = match.params.yearId;
    const sectionId = match.params.sectionId;

    const { isTheoryAdded, tloading, error, errors } = useSelector(
        (state) => state.theoryDetail
    );

    const changeEditorText = (name, value) => {

        let quesList = theoryData;
        quesList[name] = value;
        setQuestionData({ ...theoryData, quesList });
        // setQuestionData({
        //     ...theoryData,
        //     name: value,
        // });

    };

    const onStatusChnage = (e) => {
        let quesList = theoryData;
        quesList.status = !status;
        setQuestionData({ ...theoryData, quesList });
    }

    useEffect(() => {
        if (errors) {
            setErrorData(errors);
        }

        if (error) {
            clearErrors();
            return alert.error(error);
        }

        if (isTheoryAdded === true && isTheoryAdded != undefined) {
            alert.success("Theory successfully added.");
            history.push(`/admin/theories/${sectionId}/${subjectId}/${yearId}`);
            dispatch({
                type: ADD_THEORY_RESET,
            });
        }
    }, [dispatch, isTheoryAdded, alert, error, errors]);

    const onChange = (e) => {
        if (e.target.name === "logo") {
            let file = e.target.files[0];

            if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                setQuestionData({
                    ...theoryData,
                    logo: "",
                    imageName: "",
                });
                alert.error("Please select valid logo.");
                //remove preview picture if any
                const preview = document.getElementById("img-preview");
                preview.src = "";
                return false;
            }

            const reader = new FileReader();

            reader.onload = function (readerEvt) {
                // The file's text will be printed here
                //console.log(readerEvt.target.result);
                let binaryString = readerEvt.target.result;
                let base64String = btoa(binaryString);
                setQuestionData({
                    ...theoryData,
                    logo: base64String,
                    imageName: file.name,
                });

                //preview picture
                const preview = document.getElementById("img-preview");
                // console.log('banary image', base64String);
                preview.src = "data:image/png;base64," + base64String;
            };
            reader.readAsBinaryString(file);
        } else {
            setQuestionData({
                ...theoryData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const setCorrectAnswer = (answer) => {
        setQuestionData({
            ...theoryData,
            correct_answer: answer,
        });
    };

    const submitHandler = (e) => {
        e.preventDefault();

        // title,
        //     question,
        //     description,
        //     detailed_answer,
        //     status,
        //     logo,
        //     imageName

        if (!theoryData.description || !theoryData.detailed_answer || !theoryData.title || !theoryData.question) {

            let errorMessage = 'Please enter required fields:';

            if (!theoryData.title) {
                errorMessage += '\n- Title';
            }

            if (!theoryData.question) {
                errorMessage += '\n- Question';
            }
            if (!theoryData.description) {
                errorMessage += '\n- Answer';
            }

            if (!theoryData.detailed_answer) {
                errorMessage += '\n- Detailed Answer';
            }


            confirmAlert({
                title: 'Error',
                message: (
                    <div>
                        <p>
                            {errorMessage}
                        </p>

                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                        },
                    },
                ],
            });
        } else {

            dispatch(
                addTheory(
                    subjectId,
                    yearId,
                    sectionId,
                    title,
                    question,
                    description,
                    detailed_answer,
                    status,
                    logo,
                    imageName
                )
            );
        }



    };

    return (
        <Fragment>
            {tloading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Add
                                <small>Question</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard"></i> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={`/admin/theories/${sectionId}/${subjectId}/${yearId}`}
                                    // to={`/admin/questions/${sectionId}/${subjectId}/${yearId}/${123}`}
                                    >
                                        <i className="fa fa-users"></i> Questions
                                    </Link>
                                </li>
                                <li className="active">Add Question</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add Question</h3>

                                    {/*<div className="box-tools pull-right">

                                    </div>*/}
                                </div>

                                <form
                                    id="quickForm"
                                    onSubmit={submitHandler}
                                    encType="multipart/form-data"
                                >
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="title">Title <span className="text-danger"> *</span></label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="title"
                                                    placeholder="Enter Title"
                                                    value={title}
                                                    onChange={onChange}
                                                />
                                                {errorData.title ? (
                                                    <span className="text-danger">{errorData.title}</span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="question">Question <span className="text-danger"> *</span></label>
                                                <Editor
                                                    id="question"
                                                    apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"

                                                    init={{
                                                        height: 200,
                                                        menubar: false,
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                        },
                                                        plugins: [
                                                            "advlist autolink lists link image imagetools tiny_mce_wiris charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount",
                                                        ],
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
                                                        },
                                                        toolbar:
                                                            // "undo redo | formatselect | tiny_mce_wiris_formulaEditor " +
                                                            // "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            // "bullist numlist outdent indent | " +
                                                            // "removeformat | help",
                                                            "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                            "undo redo | formatselect " +
                                                            "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            "bullist numlist outdent indent | " +
                                                            "removeformat | help",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                    }}
                                                    value={question}
                                                    // initialValue={question}
                                                    onEditorChange={(value) =>
                                                        changeEditorText("question", value)
                                                    }

                                                />
                                                {errorData.question ? (
                                                    <span className="text-danger">
                                                        {errorData.question}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="logo">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="logo"
                                                    name="logo"
                                                    onChange={onChange}
                                                    accept={`.jpeg, .jpg, .png`}
                                                />
                                                {errorData.logo ? (
                                                    <span className="text-danger">{errorData.logo}</span>
                                                ) : (
                                                    ""
                                                )}
                                                <div>
                                                    <img id="img-preview" src="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="description">Answer <span className="text-danger">*</span></label>
                                                {/* <textarea
                                                    name="description"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter the answer"
                                                    value={description}
                                                    onChange={onChange}
                                                ></textarea> */}
                                                <Editor
                                                    id="description"
                                                    apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                    init={{
                                                        height: 200,
                                                        menubar: false,
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                        },
                                                        plugins: [
                                                            "advlist autolink lists link image imagetools tiny_mce_wiris charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount",
                                                        ],
                                                        toolbar:
                                                            // "undo redo | formatselect | tiny_mce_wiris_formulaEditor " +
                                                            // "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            // "bullist numlist outdent indent | " +
                                                            // "removeformat | help",
                                                            "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                            "undo redo | formatselect " +
                                                            "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            "bullist numlist outdent indent | " +
                                                            "removeformat | help",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                    }}
                                                    value={description}
                                                    // initialValue={question}
                                                    onEditorChange={(value) =>
                                                        changeEditorText("description", value)
                                                    }
                                                />

                                                {errorData.description ? (
                                                    <span className="text-danger">
                                                        {errorData.description}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="detailed_answer">Detailed Answer <span className="text-danger">*</span></label>
                                                {/* <textarea
                                                    name="detailed_answer"
                                                    className="form-control"
                                                    id="detailed_answer"
                                                    placeholder="Enter the answer Decription"
                                                    value={detailed_answer}
                                                    onChange={onChange}
                                                ></textarea> */}
                                                <Editor
                                                    id="detailed_answer"
                                                    apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                    init={{
                                                        height: 200,
                                                        menubar: false,
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                        },
                                                        plugins: [
                                                            "advlist autolink lists link image imagetools tiny_mce_wiris charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount",
                                                        ],
                                                        toolbar:
                                                            // "undo redo | formatselect | tiny_mce_wiris_formulaEditor " +
                                                            // "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            // "bullist numlist outdent indent | " +
                                                            // "removeformat | help",
                                                            "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                            "undo redo | formatselect " +
                                                            "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            "bullist numlist outdent indent | " +
                                                            "removeformat | help",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                    }}
                                                    value={detailed_answer}
                                                    // initialValue={question}
                                                    onEditorChange={(value) =>
                                                        changeEditorText("detailed_answer", value)
                                                    }
                                                />

                                                {errorData.detailed_answer ? (
                                                    <span className="text-danger">
                                                        {errorData.detailed_answer}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="status">Status</label>
                                                <input
                                                    style={{ marginRight: "5px" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="status"
                                                    name="status"
                                                    checked={status}
                                                    value={status}
                                                    onChange={onStatusChnage}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default AddTheory;
