import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../../../actions/commonActions';
import { getQuestionDetail, updateQuestion, clearErrors, getQuestions, getAllTopics, getTopics } from '../../../actions/adminQuestionActions';
import { UPDATE_QUESTION_RESET } from './../../../constants/adminQuestionConstants';
import makeAnimated from "react-select/animated";
import Loader from '../../Loader';
import { Editor } from '@tinymce/tinymce-react';
import './Question.css';
// import tinymce from '@tinymce/tinymce-react';
// import tinymce from 'tinymce';
import { getUniversityList } from '../../../actions/adminUniversityAction';
import { getTopicSections } from '../../../actions/adminTopicSectionActions';

const EditQuestion = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();

    const [questionId, setQuestionId] = useState('');
    const [questionName, setQuestionName] = useState('');
    const [title, setTitle] = useState('');
    const [topic, setTopic] = useState('');
    const [section, setSection] = useState('');
    const [passage_check, setPassageCheck] = useState(false);
    const [passage, setPassage] = useState('');
    const [option_1, setOption1] = useState('');
    const [option_2, setOption2] = useState('');
    const [option_3, setOption3] = useState('');
    const [option_4, setOption4] = useState('');
    const [option_5, setOption5] = useState('');
    const [correct_answer, setCorrectAnswer] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState(false);
    const [logo, setLogo] = useState("");
    const [imageName, setImageName] = useState("");
    const [oldImage, setOldImage] = useState("");
    const [imagePreview, setImagePreview] = useState("");
    const [update, setUpdate] = useState(false);
    const [currentAnswer, setCurrentAnswer] = useState('');

    const [optionItem, setOptionItem] = useState([]);

    const [errorData, setErrorData] = useState({});

    const { isUpdated, error, errors } = useSelector((state) => state.questions);

    const subject = localStorage.getItem('subjectName');
    const examSlug = localStorage.getItem("examSlug");
    // const currentSectionId = match.params.sectionId;
    const subjectId = match.params.subjectId;
    const yearId = match.params.yearId;
    const currentId = match.params.id;
    const topicId = match.params.topicId;
    const [university, setUniversity] = useState([]);
    const [universityLists, setuniversityLists] = useState([]);
    const slug = localStorage.getItem("examSlug");
    const subjectName = localStorage.getItem("subjectName");
    const { universityList } = useSelector((state) => state.university);
    const { allTopics } = useSelector((state) => state.questions);
    const { topicSections } = useSelector((state) => state.topicSections);

    useEffect(() => {
        dispatch(getUniversityList())
        dispatch(getAllCategories());
        dispatch(getQuestionDetail(subjectId, yearId, currentId));
        dispatch(getTopics(subjectId));
    }, [])

    useEffect(() => {
        if (topic != "") {
            dispatch(getTopicSections(topic));
        }
    }, [topic])



    useEffect(() => {
        if (universityList !== undefined && universityList?.length > 0 && universityList !== null && examSlug == 'post-utme') {
            const tmpArr = [];
            universityList?.map((course) => {
                tmpArr?.push({ label: course?.title, value: course?._id })
            })

            setuniversityLists(tmpArr)
        }
    }, [universityList])

    // const editorRef = useRef(null);

    // useEffect(() => {
    //     if (editorRef.current) {
    //         editorRef.current.setContent(questionName);
    //     }
    // }, [questionName]);
    const { question, user_loading } = useSelector((state) => state.questionDetail);
    const section_Id = question?.section;
    const topic_Id = question?.topic;
    const year_Id = question?.year;
    const subject_Id = question?.subject;

    const changePassageText = (data) => {
        setPassage(data);
    };
    const changeEditorText = (quesTxt) => {
        setQuestionName(quesTxt);
    };

    useEffect(
        () => {

            if (errors) {
                setErrorData(errors);
            }
            if (question !== undefined && Object.keys(question).length > 0) {
                setQuestionId(question._id);
                setQuestionName(question.question);
                setTitle(question.title);
                setPassageCheck(question.passageCheck);
                setPassage(question.passage);
                setCorrectAnswer(question.correctAns);
                // setUniversity(question.university);
                //setCurrentAnswer(question.correctAns);
                setDescription(question.description);


                if (question?.options != undefined && question?.options?.length > 0) {
                    for (let i = 0; i < question?.options?.length; i++) {
                        if (i == 0) {
                            setOption1(question.options[i].option);
                            if (question.correctAns == question.options[i]._id) {
                                setCurrentAnswer('option_1');
                            }
                        }
                        if (i == 1) {
                            setOption2(question.options[i].option);
                            if (question.correctAns == question.options[i]._id) {
                                setCurrentAnswer('option_2');
                            }
                        }
                        if (i == 2) {
                            setOption3(question.options[i].option);
                            if (question.correctAns == question.options[i]._id) {
                                setCurrentAnswer('option_3');
                            }
                        }
                        if (i == 3) {
                            setOption4(question.options[i].option);
                            if (question.correctAns == question.options[i]._id) {
                                setCurrentAnswer('option_4');
                            }
                        }
                        if (i == 4) {
                            setOption5(question.options[i].option);
                            if (question.correctAns == question.options[i]._id) {
                                setCurrentAnswer('option_5');
                            }
                        }
                    }
                }

                setStatus(question.status);
                setLogo(question.logo);
                setOldImage(question.logo);
                setImagePreview(process.env.REACT_APP_PUBLIC_URL + "/assets/img/questions/" + question.logo);
            }
            if (error) {
                alert.error(error);
                dispatch(clearErrors());
            }
            if (isUpdated) {
                alert.success('Question updated successfully');
                // dispatch(getQuestionDetail(question._id));
                // dispatch(getQuestions(subject_Id, year_Id, topic_Id, section_Id));
                //const currentSectionId = question.section;
                history.push(`/admin/questions/${section_Id}/${subject_Id}/${year_Id}/${topic_Id}`);

                dispatch({
                    type: UPDATE_QUESTION_RESET
                });
            }
        },
        [dispatch, isUpdated, question]
    );

    useEffect(() => {
        if (question && question?.university && question?.university?.length > 0 && universityList && universityList?.length > 0 && examSlug == 'post-utme') {
            const tmpArr = [];
            question?.university?.map((id) => {
                if (universityList?.some((c) => c._id === id)) {
                    const course = universityList?.find((c) => c?._id === id)
                    tmpArr?.push({ label: course?.title, value: course?._id })
                }
            })
            setUniversity(tmpArr)
        }
    }, [universityList, question])



    const onChange = (e) => {
        let file = e.target.files[0];

        if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            setLogo("");
            setImageName("");
            alert.error("Please select valid image.");
            //remove preview picture if any
            const preview = document.getElementById("img-preview");
            preview.src = "";
            return false;
        }

        const reader = new FileReader();

        reader.onload = function (readerEvt) {
            // The file's text will be printed here
            //console.log(readerEvt.target.result);
            let binaryString = readerEvt.target.result;
            let base64String = btoa(binaryString);
            setLogo(base64String);
            setImageName(file.name);

            //preview picture
            const preview = document.getElementById("img-preview");
            // console.log('banary image', base64String);
            preview.src = "data:image/png;base64," + base64String;
        };
        reader.readAsBinaryString(file);
    };

    const handleAnswerOnChange = (e) => {
        setCorrectAnswer(e.target.value);
        setCurrentAnswer(e.target.dataset.ans);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            updateQuestion(
                questionId,
                title,
                topic,
                section,
                questionName,
                passage_check,
                passage,
                option_1,
                option_2,
                option_3,
                option_4,
                option_5,
                correct_answer,
                currentAnswer,
                description,
                status,
                oldImage,
                logo,
                imageName
            )
        );
    };

    return (
        <Fragment>
            {user_loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Edit
                                <small>Question</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard" /> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/admin/questions/${section_Id}/${subject_Id}/${year_Id}/${topic_Id}`}>
                                        <i className="fa fa-users" /> Questions
                                    </Link>
                                </li>
                                <li className="active">Edit Question [Exam:- {slug}, Subject:- {subjectName}]</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Edit Question</h3>


                                </div>

                                <form id="quickForm" onSubmit={submitHandler} encType="multipart/form-data">
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="title">Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="title"
                                                    placeholder="Enter Title"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                                {errorData.title ? (
                                                    <span className="text-danger">{errorData.title}</span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="topic_id">
                                                    Topic
                                                </label>
                                                <select
                                                    className="form-control"
                                                    name="topic_id"
                                                    value={topic}
                                                    onChange={(e) => setTopic(e.target.value)}
                                                >
                                                    <option value="">Select</option>
                                                    {allTopics.map((val, key) => (
                                                        <option key={key} value={val._id}>
                                                            {val.title}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errorData.topic_id ? (
                                                    <span className="text-danger">{errorData.topic_id}</span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="topic_section_id">
                                                    Topic Section
                                                </label>
                                                <select
                                                    className="form-control"
                                                    name="topic_section_id"
                                                    value={section}
                                                    onChange={(e) => setSection(e.target.value)}
                                                >
                                                    <option value="">Select</option>
                                                    {topicSections.map((val, key) => (
                                                        <option key={key} value={val._id}>{val.section_name}</option>
                                                    ))}
                                                </select>
                                                {errorData.topic_section_id ? (
                                                    <span className="text-danger">{errorData.topic_section_id}</span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>

                                        {examSlug == 'post-utme' && <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="title">Select University</label>

                                                <Select
                                                    value={university}
                                                    onChange={setUniversity}
                                                    options={universityLists}
                                                    isMulti
                                                    components={animatedComponents}
                                                    isSearchable={true}
                                                />

                                            </div>
                                        </div>}


                                        <Fragment>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label htmlFor="passage_check"> {subject === "english" ? "Common Passage / Linked Question" : "Linked Question"}</label>
                                                    <input
                                                        style={{ marginRight: '5px' }}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="passage_check"
                                                        name="passage_check"
                                                        checked={passage_check}
                                                        value={passage_check}
                                                        onChange={(e) => setPassageCheck(!passage_check)}
                                                    />
                                                </div>
                                            </div>

                                            {passage_check == true && (
                                                <div className="form-group row">
                                                    <div className="col-md-12">
                                                        <label htmlFor="Passage_Image">{subject !== "english" ? "Question Information" : "Passage / Question Information"}</label>
                                                        <Editor
                                                            key={currentId}
                                                            apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                            init={{
                                                                height: 200,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image imagetools charmap print preview anchor',
                                                                    'searchreplace visualblocks code fullscreen',
                                                                    'insertdatetime media table paste code help wordcount'
                                                                ],
                                                                external_plugins: {
                                                                    tiny_mce_wiris:
                                                                        "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                                    //'http://www.onetimesuccess.com/node_modules/@wiris/mathtype-tinymce5/plugin.min.js'
                                                                },
                                                                toolbar:
                                                                    // 'undo redo | formatselect ' +
                                                                    // 'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
                                                                    // 'bullist numlist outdent indent | ' +
                                                                    // 'removeformat | help',

                                                                    "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                                    "undo redo | formatselect " +
                                                                    "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                                                    "bullist numlist outdent indent | " +
                                                                    "removeformat | help",
                                                                htmlAllowedTags: ['.*'],
                                                                htmlAllowedAttrs: ['.*'],
                                                                extended_valid_elements: '*[.*]',
                                                                paste_data_images: true,
                                                                file_picker_types: 'image',
                                                                // plugins: 'paste', // Include paste plugin
                                                                // paste_as_text: false, // Allow pasting as HTML
                                                                paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

                                                                content_style:
                                                                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                            onInit={(evt, editor) => {
                                                                setUpdate(!update)
                                                            }}

                                                            value={passage}
                                                            // initialValue={passage}
                                                            onEditorChange={(newText) => changePassageText(newText)}
                                                        />
                                                        {errorData.passage ? (
                                                            <span className="text-danger">{errorData.passage}</span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Fragment>


                                        {/* {subject !== 'english'&& ( */}
                                        {true && (
                                            <div className="form-group row">
                                                <div className="col-md-12">
                                                    <label htmlFor="question">Question</label>
                                                    <Editor
                                                        scriptLoading={{ async: true, delay: 500 }}
                                                        key={currentId}
                                                        apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                        init={{
                                                            height: 200,
                                                            menubar: false,
                                                            external_plugins: {
                                                                tiny_mce_wiris:
                                                                    'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
                                                            },
                                                            plugins: [
                                                                'advlist autolink lists link image imagetools tiny_mce_wiris charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            toolbar:
                                                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                                "undo redo | formatselect " +
                                                                "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                                                "bullist numlist outdent indent | " +
                                                                "removeformat | help",
                                                            htmlAllowedTags: ['.*'],
                                                            htmlAllowedAttrs: ['.*'],
                                                            extended_valid_elements: '*[.*]',
                                                            paste_data_images: true,
                                                            file_picker_types: 'image',
                                                            // plugins: 'paste', // Include paste plugin
                                                            // paste_as_text: false, // Allow pasting as HTML
                                                            paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

                                                            // images_upload_url: 'postAcceptor.php',
                                                            content_style:
                                                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                        }}
                                                        value={questionName}
                                                        // initialValue={questionName || question?.question}
                                                        onEditorChange={(newText) => {
                                                            changeEditorText(newText)
                                                        }}
                                                        onInit={(evt, editor) => {
                                                            setUpdate(!update)
                                                        }}


                                                    // onMouseDown={(event, editor) => handleMouseDown(event, editor)}
                                                    // ref={editorRef}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {/* {subject === 'english' && (
                                            <div className="form-group row">
                                                <div className="col-md-12">
                                                    <label htmlFor="questionTitle">Question</label>

                                                    <input
                                                        type="text"
                                                        name="questionTitle"
                                                        className="form-control"
                                                        id="questionTitle"
                                                        placeholder="Enter question"
                                                        onChange={(e) => setQuestionName(e.target.value)}
                                                        value={questionName}
                                                    />
                                                </div>
                                            </div>
                                        )} */}

                                        <div className="form-group row">
                                            {question !== undefined &&
                                                question.options !== undefined &&
                                                question.options.map((val, idx) => {
                                                    const optionIndex = `option_${idx + 1}`;
                                                    const catId = `option_${idx}`;
                                                    // let optionFunc = "";
                                                    // if (optionIndex === "option_1") {
                                                    //   optionFunc = setOption1();
                                                    // }
                                                    // if (optionIndex === "option_2") {
                                                    //   optionFunc = setOption2();
                                                    // }
                                                    // if (optionIndex === "option_3") {
                                                    //   optionFunc = setOption3();
                                                    // }
                                                    // if (optionIndex === "option_4") {
                                                    //   optionFunc = setOption4();
                                                    // }
                                                    return (
                                                        <div key={`cat-${idx}`} className="col-md-6">
                                                            {optionIndex === 'option_1' && (
                                                                <Fragment>
                                                                    <label htmlFor={optionIndex}>{`Option #${idx +
                                                                        1}`}</label>
                                                                    {/* <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name={optionIndex}
                                                                        data-idx={val._id}
                                                                        id={optionIndex}
                                                                        // value={val.option}
                                                                        // onChange={handleCatChange}
                                                                        value={option_1}
                                                                        onChange={(e) => setOption1(e.target.value)}
                                                                    /> */}
                                                                    <Editor
                                                                        key={currentId}
                                                                        apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                                        init={{
                                                                            height: 200,
                                                                            menubar: false,
                                                                            plugins: [
                                                                                'advlist autolink lists link image imagetools charmap print preview anchor',
                                                                                'searchreplace visualblocks code fullscreen',
                                                                                'insertdatetime media table paste code help wordcount'
                                                                            ],
                                                                            external_plugins: {
                                                                                tiny_mce_wiris:
                                                                                    "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                                                //'http://www.onetimesuccess.com/node_modules/@wiris/mathtype-tinymce5/plugin.min.js'
                                                                            },
                                                                            toolbar:
                                                                                // 'undo redo | formatselect ' +
                                                                                // 'bold italic backcolor | alignleft aligncenter | MathType' +
                                                                                // 'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                                // 'removeformat | help',

                                                                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                                                "undo redo | formatselect " +
                                                                                "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                                                                "bullist numlist outdent indent | " +
                                                                                "removeformat | help",
                                                                            htmlAllowedTags: ['.*'],
                                                                            htmlAllowedAttrs: ['.*'],
                                                                            extended_valid_elements: '*[.*]',
                                                                            paste_data_images: true,
                                                                            file_picker_types: 'image',
                                                                            // plugins: 'paste', // Include paste plugin
                                                                            // paste_as_text: false, // Allow pasting as HTML
                                                                            paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

                                                                            content_style:
                                                                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                        }}
                                                                        onInit={(evt, editor) => {
                                                                            setUpdate(!update)
                                                                        }}

                                                                        value={option_1}
                                                                        // initialValue={option_1 || question?.options[0]?.option}
                                                                        // initialValue={passage}
                                                                        onEditorChange={(newText) => setOption1(newText)}
                                                                    />
                                                                </Fragment>
                                                            )}
                                                            {optionIndex === 'option_2' && (
                                                                <Fragment>
                                                                    <label htmlFor={optionIndex}>{`Option #${idx +
                                                                        1}`}</label>
                                                                    {/* <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name={optionIndex}
                                                                        data-idx={val._id}
                                                                        id={optionIndex}
                                                                        // value={val.option}
                                                                        // onChange={handleCatChange}
                                                                        value={option_2}
                                                                        onChange={(e) => setOption2(e.target.value)}
                                                                    /> */}
                                                                    <Editor
                                                                        key={currentId}
                                                                        apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                                        init={{
                                                                            height: 200,
                                                                            menubar: false,
                                                                            plugins: [
                                                                                'advlist autolink lists link image imagetools charmap print preview anchor',
                                                                                'searchreplace visualblocks code fullscreen',
                                                                                'insertdatetime media table paste code help wordcount'
                                                                            ],
                                                                            external_plugins: {
                                                                                tiny_mce_wiris:
                                                                                    "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                                                //'http://www.onetimesuccess.com/node_modules/@wiris/mathtype-tinymce5/plugin.min.js'
                                                                            },
                                                                            toolbar:
                                                                                // 'undo redo | formatselect ' +
                                                                                // 'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
                                                                                // 'bullist numlist outdent indent | ' +
                                                                                // 'removeformat | help',

                                                                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                                                "undo redo | formatselect " +
                                                                                "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                                                                "bullist numlist outdent indent | " +
                                                                                "removeformat | help",
                                                                            htmlAllowedTags: ['.*'],
                                                                            htmlAllowedAttrs: ['.*'],
                                                                            extended_valid_elements: '*[.*]',
                                                                            paste_data_images: true,
                                                                            file_picker_types: 'image',
                                                                            // plugins: 'paste', // Include paste plugin
                                                                            // paste_as_text: false, // Allow pasting as HTML
                                                                            paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

                                                                            content_style:
                                                                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                        }}
                                                                        onInit={(evt, editor) => {
                                                                            setUpdate(!update)
                                                                        }}

                                                                        value={option_2}
                                                                        // initialValue={option_2 || question?.options[1]?.option}
                                                                        // initialValue={passage}
                                                                        onEditorChange={(newText) => setOption2(newText)}
                                                                    />
                                                                </Fragment>
                                                            )}
                                                            {optionIndex === 'option_3' && (
                                                                <Fragment>
                                                                    <label htmlFor={optionIndex}>{`Option #${idx +
                                                                        1}`}</label>
                                                                    {/* <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name={optionIndex}
                                                                        data-idx={val._id}
                                                                        id={optionIndex}
                                                                        // value={val.option}
                                                                        // onChange={handleCatChange}
                                                                        value={option_3}
                                                                        onChange={(e) => setOption3(e.target.value)}
                                                                    /> */}
                                                                    <Editor
                                                                        key={currentId}

                                                                        apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                                        init={{
                                                                            height: 200,
                                                                            menubar: false,
                                                                            plugins: [
                                                                                'advlist autolink lists link image imagetools charmap print preview anchor',
                                                                                'searchreplace visualblocks code fullscreen',
                                                                                'insertdatetime media table paste code help wordcount'
                                                                            ],
                                                                            external_plugins: {
                                                                                tiny_mce_wiris:
                                                                                    "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                                                //'http://www.onetimesuccess.com/node_modules/@wiris/mathtype-tinymce5/plugin.min.js'
                                                                            },
                                                                            toolbar:
                                                                                // 'undo redo | formatselect ' +
                                                                                // 'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
                                                                                // 'bullist numlist outdent indent | ' +
                                                                                // 'removeformat | help',

                                                                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                                                "undo redo | formatselect " +
                                                                                "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                                                                "bullist numlist outdent indent | " +
                                                                                "removeformat | help",
                                                                            htmlAllowedTags: ['.*'],
                                                                            htmlAllowedAttrs: ['.*'],
                                                                            extended_valid_elements: '*[.*]',
                                                                            paste_data_images: true,
                                                                            file_picker_types: 'image',
                                                                            // plugins: 'paste', // Include paste plugin
                                                                            // paste_as_text: false, // Allow pasting as HTML
                                                                            paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

                                                                            content_style:
                                                                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                        }}
                                                                        onInit={(evt, editor) => {
                                                                            setUpdate(!update)
                                                                        }}

                                                                        value={option_3}
                                                                        // initialValue={option_3 || question?.options[2]?.option}
                                                                        // initialValue={passage}
                                                                        onEditorChange={(newText) => setOption3(newText)}
                                                                    />
                                                                </Fragment>
                                                            )}
                                                            {optionIndex === 'option_4' && (
                                                                <Fragment>
                                                                    <label htmlFor={optionIndex}>{`Option #${idx +
                                                                        1}`}</label>
                                                                    {/* <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name={optionIndex}
                                                                        data-idx={val._id}
                                                                        id={optionIndex}
                                                                        // value={val.option}
                                                                        // onChange={handleCatChange}
                                                                        value={option_4}
                                                                        onChange={(e) => setOption4(e.target.value)}
                                                                    /> */}
                                                                    <Editor
                                                                        key={currentId}
                                                                        apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                                        init={{
                                                                            height: 200,
                                                                            menubar: false,
                                                                            plugins: [
                                                                                'advlist autolink lists link image imagetools charmap print preview anchor',
                                                                                'searchreplace visualblocks code fullscreen',
                                                                                'insertdatetime media table paste code help wordcount'
                                                                            ],
                                                                            external_plugins: {
                                                                                tiny_mce_wiris:
                                                                                    "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                                                //'http://www.onetimesuccess.com/node_modules/@wiris/mathtype-tinymce5/plugin.min.js'
                                                                            },
                                                                            toolbar:
                                                                                // 'undo redo | formatselect ' +
                                                                                // 'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
                                                                                // 'bullist numlist outdent indent | ' +
                                                                                // 'removeformat | help',

                                                                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                                                "undo redo | formatselect " +
                                                                                "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                                                                "bullist numlist outdent indent | " +
                                                                                "removeformat | help",
                                                                            htmlAllowedTags: ['.*'],
                                                                            htmlAllowedAttrs: ['.*'],
                                                                            extended_valid_elements: '*[.*]',
                                                                            paste_data_images: true,
                                                                            file_picker_types: 'image',
                                                                            // plugins: 'paste', // Include paste plugin
                                                                            // paste_as_text: false, // Allow pasting as HTML
                                                                            paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

                                                                            content_style:
                                                                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                        }}
                                                                        onInit={(evt, editor) => {
                                                                            setUpdate(!update)
                                                                        }}

                                                                        value={option_4}
                                                                        // initialValue={option_4 || question?.options[3]?.option}
                                                                        // initialValue={passage}
                                                                        onEditorChange={(newText) => setOption4(newText)}
                                                                    />
                                                                </Fragment>
                                                            )}

                                                            {optionIndex === 'option_5' && (
                                                                <Fragment>
                                                                    <label htmlFor={optionIndex}>{`Option #${idx +
                                                                        1}`}</label>
                                                                    {/* <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name={optionIndex}
                                                                        data-idx={val._id}
                                                                        id={optionIndex}
                                                                        // value={val.option}
                                                                        // onChange={handleCatChange}
                                                                        value={option_5}
                                                                        onChange={(e) => setOption5(e.target.value)}
                                                                    /> */}
                                                                    <Editor
                                                                        key={currentId}
                                                                        apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                                        init={{
                                                                            height: 200,
                                                                            menubar: false,
                                                                            plugins: [
                                                                                'advlist autolink lists link image imagetools charmap print preview anchor',
                                                                                'searchreplace visualblocks code fullscreen',
                                                                                'insertdatetime media table paste code help wordcount'
                                                                            ],
                                                                            external_plugins: {
                                                                                tiny_mce_wiris:
                                                                                    "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                                                //'http://www.onetimesuccess.com/node_modules/@wiris/mathtype-tinymce5/plugin.min.js'
                                                                            },
                                                                            toolbar:
                                                                                // 'undo redo | formatselect ' +
                                                                                // 'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
                                                                                // 'bullist numlist outdent indent | ' +
                                                                                // 'removeformat | help',

                                                                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                                                "undo redo | formatselect " +
                                                                                "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                                                                "bullist numlist outdent indent | " +
                                                                                "removeformat | help",
                                                                            htmlAllowedTags: ['.*'],
                                                                            htmlAllowedAttrs: ['.*'],
                                                                            extended_valid_elements: '*[.*]',
                                                                            paste_data_images: true,
                                                                            file_picker_types: 'image',
                                                                            // plugins: 'paste', // Include paste plugin
                                                                            // paste_as_text: false, // Allow pasting as HTML
                                                                            paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

                                                                            content_style:
                                                                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                        }}
                                                                        onInit={(evt, editor) => {
                                                                            setUpdate(!update)
                                                                        }}

                                                                        value={option_5}
                                                                        // initialValue={option_5 || question?.options[4]?.option}
                                                                        // initialValue={passage}
                                                                        onEditorChange={(newText) => setOption5(newText)}
                                                                    />
                                                                </Fragment>
                                                            )}

                                                            <input
                                                                type="checkbox"
                                                                name="correct_answer"
                                                                data-ans={optionIndex}
                                                                value={val._id}
                                                                checked={correct_answer === val._id}
                                                                onChange={handleAnswerOnChange}
                                                            />
                                                            <label htmlFor="option_1_answer"> &nbsp;Correct Answer</label>
                                                        </div>
                                                    );
                                                })}
                                        </div>


                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="logo">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="logo"
                                                    name="logo"
                                                    onChange={onChange}
                                                    accept={`.jpeg, .jpg, .png`}
                                                // required

                                                />
                                                {errorData.logo ? (
                                                    <span className="text-danger">{errorData.logo}</span>
                                                ) : (
                                                    ""
                                                )}
                                                <div>
                                                    <img id="img-preview" src={imagePreview} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="description">Description</label>
                                                {/* <textarea
                                                    type="text"
                                                    name="description"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter the answer Decription"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                /> */}
                                                <Editor
                                                    key={currentId}
                                                    apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                    init={{
                                                        height: 200,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image imagetools charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                            //'http://www.onetimesuccess.com/node_modules/@wiris/mathtype-tinymce5/plugin.min.js'
                                                        },
                                                        toolbar:
                                                            // 'undo redo | formatselect ' +
                                                            // 'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
                                                            // 'bullist numlist outdent indent | ' +
                                                            // 'removeformat | help',

                                                            "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                            "undo redo | formatselect " +
                                                            "bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType" +
                                                            "bullist numlist outdent indent | " +
                                                            "removeformat | help",
                                                        htmlAllowedTags: ['.*'],
                                                        htmlAllowedAttrs: ['.*'],
                                                        extended_valid_elements: '*[.*]',
                                                        paste_data_images: true,
                                                        file_picker_types: 'image',
                                                        // plugins: 'paste', // Include paste plugin
                                                        // paste_as_text: false, // Allow pasting as HTML
                                                        paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li',

                                                        content_style:
                                                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                    }}
                                                    onInit={(evt, editor) => {
                                                        setUpdate(!update)
                                                    }}

                                                    value={description}
                                                    // initialValue={description || question?.description}
                                                    // initialValue={passage}
                                                    onEditorChange={(newText) => setDescription(newText)}
                                                />

                                                {errorData.description ? (
                                                    <span className="text-danger">{errorData.description}</span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="status">Status </label>
                                                <input
                                                    style={{ marginRight: '5px' }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="status"
                                                    checked={status}
                                                    value={status}
                                                    onChange={(e) => setStatus(!status)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default EditQuestion;
