import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";
import { Editor } from "@tinymce/tinymce-react";

import { useDispatch, useSelector } from "react-redux";
import {
    getTheoryDetail,
    updateTheory,
    clearErrors,
} from "../../../actions/adminTheoryActions";
import { UPDATE_THEORY_RESET } from "./../../../constants/adminTheoryConstants";

import Loader from "../../Loader";

import "./Theory.css";

const EditTheory = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [theoryId, setTheoryId] = useState("");
    const [question, setQuestion] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [detailed_answer, setDetailedAnswer] = useState("");
    const [status, setStatus] = useState(false);
    const [logo, setLogo] = useState("");
    const [imageName, setImageName] = useState("");
    const [oldImage, setOldImage] = useState("");
    const [imagePreview, setImagePreview] = useState("");

    const [errorData, setErrorData] = useState({});

    const { isUpdated, error, errors } = useSelector(
        (state) => state.theoryList
    );

    // const currentSectionId = match.params.sectionId;
    const subjectId = match.params.subjectId;
    const yearId = match.params.yearId;
    const currentId = match.params.id;
    useEffect(() => {
        dispatch(getTheoryDetail(subjectId, yearId, currentId));
    }, [dispatch]);

    const { theory, tloading } = useSelector((state) => state.theoryDetail);
    const currentSectionId = theory?.section;

    const changeEditorText = (data) => {
        setQuestion(data)
        // setQuestion({
        //     question: data,
        // });
    };

    const changeEditorDescText = (data) => {
        setDescription(data);
        // setQuestion({
        //     question: data,
        // });
    };


    const changeEditorAnsText = (data) => {
        setDetailedAnswer(data)
        // setQuestion({
        //     question: data,
        // });
    };


    // const changeEditorText = (name, value) => {



    //     let quesList = question;
    //     quesList[name] = value;
    //     setQuestion(quesList);
    //     // setQuestionData({
    //     //     ...theoryData,
    //     //     name: value,
    //     // });

    // };

    useEffect(() => {
        if (errors) {
            setErrorData(errors);
        }

        if (theory && Object.keys(theory).length > 0) {

            setTheoryId(theory._id);
            setQuestion(theory.question);
            setTitle(theory.title);
            setDescription(theory.answer);
            setDetailedAnswer(theory.detailedAnswer);
            setStatus(theory.status);
            setLogo(theory.logo);
            setOldImage(theory.logo);
            setImagePreview(process.env.REACT_APP_PUBLIC_URL + "/assets/img/questions/" + theory.logo);
        }
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (isUpdated) {
            alert.success("Theory updated successfully");
            dispatch(getTheoryDetail(theory._id));
            history.push(
                `/admin/theories/${currentSectionId}/${subjectId}/${yearId}`
            );
            dispatch({
                type: UPDATE_THEORY_RESET,
            });
        }
    }, [dispatch, theory, isUpdated]);

    const onChange = (e) => {
        let file = e.target.files[0];

        if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            setLogo("");
            setImageName("");
            alert.error("Please select valid image.");
            //remove preview picture if any
            const preview = document.getElementById("img-preview");
            preview.src = "";
            return false;
        }

        const reader = new FileReader();

        reader.onload = function (readerEvt) {
            // The file's text will be printed here
            //console.log(readerEvt.target.result);
            let binaryString = readerEvt.target.result;
            let base64String = btoa(binaryString);
            setLogo(base64String);
            setImageName(file.name);

            //preview picture
            const preview = document.getElementById("img-preview");
            // console.log('banary image', base64String);
            preview.src = "data:image/png;base64," + base64String;
        };
        reader.readAsBinaryString(file);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            updateTheory(
                theoryId,
                title,
                question,
                description,
                detailed_answer,
                status,
                oldImage,
                logo,
                imageName
            )
        );
    };



    return (
        <Fragment>
            {tloading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Edit
                                <small>Theory</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard"></i> Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={`/admin/theories/${currentSectionId}/${subjectId}/${yearId}`}
                                    >
                                        <i className="fa fa-users"></i> Theories
                                    </Link>
                                </li>
                                <li className="active">Edit Theory</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Edit Theory</h3>

                                    {/*<div className="box-tools pull-right">

                                    </div>*/}
                                </div>

                                <form
                                    id="quickForm"
                                    onSubmit={submitHandler}
                                    encType="multipart/form-data"
                                >
                                    <div className="box-body">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="title">Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    id="title"
                                                    placeholder="Enter Title"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                                {errorData.title ? (
                                                    <span className="text-danger">{errorData.title}</span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="theory">Theory</label>
                                                {question !== undefined && (
                                                    <Editor
                                                        apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                        init={{
                                                            height: 200,
                                                            menubar: false,

                                                            plugins: [
                                                                "advlist autolink lists link image imagetools tiny_mce_wiris charmap print preview anchor",
                                                                "searchreplace visualblocks code fullscreen",
                                                                "insertdatetime media table paste code help wordcount",
                                                            ],
                                                            external_plugins: {
                                                                tiny_mce_wiris:
                                                                    'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
                                                            },
                                                            toolbar:
                                                                // "undo redo | formatselect | tiny_mce_wiris_formulaEditor " +
                                                                // "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                                // "bullist numlist outdent indent | " +
                                                                // "removeformat | help",
                                                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                                "undo redo | formatselect " +
                                                                "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                                "bullist numlist outdent indent | " +
                                                                "removeformat | help",
                                                            content_style:
                                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                        }}
                                                        // initialValue={question}
                                                        value={question}
                                                        onEditorChange={(newText) =>
                                                            changeEditorText(newText)
                                                        }
                                                    />
                                                )}
                                                {errorData.question ? (
                                                    <span className="text-danger">
                                                        {errorData.question}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="logo">Image</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="logo"
                                                    name="logo"
                                                    onChange={onChange}
                                                    accept={`.jpeg, .jpg, .png`}
                                                />
                                                {errorData.logo ? (
                                                    <span className="text-danger">{errorData.logo}</span>
                                                ) : (
                                                    ""
                                                )}
                                                <div>
                                                    <img id="img-preview" src={imagePreview} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="description">Description</label>
                                                {/* <textarea
                                                    type="text"
                                                    name="description"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter the answer Decription"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                ></textarea> */}
                                                <Editor
                                                    apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                    init={{
                                                        height: 200,
                                                        menubar: false,

                                                        plugins: [
                                                            "advlist autolink lists link image imagetools tiny_mce_wiris charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount",
                                                        ],
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
                                                        },
                                                        toolbar:
                                                            // "undo redo | formatselect | tiny_mce_wiris_formulaEditor " +
                                                            // "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            // "bullist numlist outdent indent | " +
                                                            // "removeformat | help",
                                                            "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                            "undo redo | formatselect " +
                                                            "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            "bullist numlist outdent indent | " +
                                                            "removeformat | help",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                    }}
                                                    // initialValue={question}
                                                    value={description}
                                                    onEditorChange={(newText) =>
                                                        changeEditorDescText(newText)
                                                    }
                                                />

                                                {errorData.description ? (
                                                    <span className="text-danger">
                                                        {errorData.description}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="detailed_answer">Detailed Answer</label>
                                                {/* <textarea
                                                    type="text"
                                                    name="detailed_answer"
                                                    className="form-control"
                                                    id="detailed_answer"
                                                    placeholder="Enter the answer Decription"
                                                    value={detailed_answer}
                                                    onChange={(e) => setDetailedAnswer(e.target.value)}
                                                ></textarea> */}
                                                <Editor
                                                    apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
                                                    init={{
                                                        height: 200,
                                                        menubar: false,

                                                        plugins: [
                                                            "advlist autolink lists link image imagetools tiny_mce_wiris charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount",
                                                        ],
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js'
                                                        },
                                                        toolbar:
                                                            // "undo redo | formatselect | tiny_mce_wiris_formulaEditor " +
                                                            // "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            // "bullist numlist outdent indent | " +
                                                            // "removeformat | help",
                                                            "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
                                                            "undo redo | formatselect " +
                                                            "bold italic backcolor | alignleft aligncenter alignright alignjustify |  MathType" +
                                                            "bullist numlist outdent indent | " +
                                                            "removeformat | help",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                    }}
                                                    // initialValue={question}
                                                    value={detailed_answer}
                                                    onEditorChange={(newText) =>
                                                        changeEditorAnsText(newText)
                                                    }
                                                />

                                                {errorData.detailed_answer ? (
                                                    <span className="text-danger">
                                                        {errorData.detailed_answer}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label htmlFor="status">Status </label>
                                                <input
                                                    style={{ marginRight: "5px" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="status"
                                                    checked={status}
                                                    value={status}
                                                    onChange={(e) => setStatus(!status)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default EditTheory;
