import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import './mock.css';
import { getExamInstruction } from '../../actions/mockActions';

const Instruction = ({ history }) => {
	const alert = useAlert();
	const dispatch = useDispatch();

	const [checked, setChecked] = useState(false);
	const [examId, setExamId] = useState('');
	const [examName, setExamName] = useState('');
	const [timeDuration, setTimeDuration] = useState(15);

	const { user } = useSelector((state) => state.auth);
	const { instruction } = useSelector((state) => state.mockList);

	useEffect(() => {
		const storedExamId = localStorage.getItem('examId');
		const storedExamName = localStorage.getItem('examName');
		setExamId(storedExamId);
		setExamName(storedExamName);

		if (storedExamId) {
			dispatch(getExamInstruction(storedExamId));
		}
	}, [alert, dispatch, user]);

	useEffect(() => {
		if (instruction) {
			let duration = 15;
			if (instruction.timeHour !== undefined) {
				duration = Math.floor(instruction.timeHour * 60);
			}
			if (instruction.time !== undefined && instruction.time !== '00') {
				duration = parseInt(duration) + parseInt(instruction.time);
			}
			setTimeDuration(duration);
			localStorage.setItem("timeDuration", duration);
		}
	}, [instruction]);

	const goToPreviousPage = (e) => {
		e.preventDefault();
		history.push('/mode-of-assessment');
	};

	const handleCheck = () => {
		setChecked(!checked);
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (!checked) {
			alert.error("Please agree to the terms");
			return false;
		}
		history.push(`/start-mock/${examId}`);
	};

	return (
		<Fragment>
			<section
				className="page-banner-sec text-center"
				style={{
					backgroundImage: 'url("../assets/frontend/img/about-banner.jpg")'
				}}
			>
				<div className="container">
					<ul className="clearfix paginations-cust">
						<li>
							<Link to="/">Home</Link>
						</li>
						<li>
							<Link to="/mode-of-assessment">Mode of Assessment</Link>
						</li>
					</ul>
					<h1>Instruction for Candidates</h1>
				</div>
			</section>

			<section className="subscription-page">
				<div className="container">
					<div className="end-plan">
						<div className="row">
							<div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
								<div className="profiles-large wow fadeInRightBig">
									<div className="left-details" style={{ width: '70%' }}>
										<div className="title-profiles wow fadeInDown">
											<ul>
												<li>Name</li>
												<li>Exam</li>
												<li>Duration</li>
											</ul>
										</div>
										<div className="colns wow fadeInDown">
											<ul>
												<li>:</li>
												<li>:</li>
												<li>:</li>
											</ul>
										</div>
										<div className="originals-profiles wow fadeInDown">
											<ul>
												<li>{user.first_name} {user.last_name}</li>
												<li>{examName}</li>
												<li>{timeDuration} Minutes</li>
											</ul>
										</div>
									</div>
									<div className="right-details wow fadeInRight" style={{ width: '30%' }}>
										<div className="right-prof-imgs">
											<img
												src={process.env.REACT_APP_PUBLIC_URL + "/assets/img/user.png"}
												className="img-responsive"
												alt="User"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="end-plan instruction">
						<p dangerouslySetInnerHTML={{ __html: instruction?.examInstructions }} />
					</div>

					<div className="">
						<div className="boxes" style={{ display: "inline-block" }}>
							<input
								type="checkbox"
								id="exam-instructions"
								onClick={handleCheck}
								checked={checked}
								value={1}
							/>{' '}
							<label htmlFor="exam-instructions">
								Click here to indicate that you have read and agree to the terms presented in the{' '}
							</label>
						</div>{' '}
						<a target="_blank" href="/pages/terms-conditions" rel="noopener noreferrer">
							Terms and Conditions agreement
						</a>
					</div>

					<div className="next-prev-steps text-center">
						<button
							type="submit"
							className="chose-more"
							onClick={submitHandler}
						>
							Start Mock <i className="fas fa-arrow-right" />
						</button>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default Instruction;
