import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const RootRouter = ({ component: Component, layout: Layout, protectedUrl = false, ...rest }) => {
  const { isAuthenticated, loading } = useSelector(({ auth }) => auth);

  if (loading) {
    return null; // Optional: Replace with a loading spinner if required.
  }

  const pathname = window.location.pathname.split("/")[1];

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === false && protectedUrl === true ? (
          <Redirect to={pathname === "admin" ? "/admin/login" : "/login"} />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

export default RootRouter;
