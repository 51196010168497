import React from 'react'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useAlert } from "react-alert";
import { useEffect, useState } from 'react';
import { getQuestionTopicSections } from '../../actions/adminQuestionActions';
import Loader from '../Loader';

const SelectSubjectTopic = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [objectIdArray, setObjectIdArray] = useState([]);
    const [subjectId, setSubjectId] = useState("");
    const [yearId, setYearId] = useState("");

    const { qTopicSections, loading } = useSelector((state) => state.questions);

    // Initialize states from localStorage and route params
    useEffect(() => {
        const checkedSubject = localStorage.getItem("checkedSubjects") || "";
        setObjectIdArray(checkedSubject.split(",").map((id) => id.trim()));
        setSubjectId(match.params.selectedsubjectId);
        setYearId(match.params.id);
    }, [match.params.selectedsubjectId, match.params.id]);

    // Dispatch action to fetch question topic sections
    useEffect(() => {
        if (objectIdArray.length > 0) {
            dispatch(getQuestionTopicSections(objectIdArray));
        }
    }, [dispatch, objectIdArray]);

    // Initialize selectedTopics with all topics by default
    useEffect(() => {
        if (qTopicSections?.length > 0) {
            const defaultSelectedTopics = qTopicSections.flatMap((item) =>
                item.topics.map((topic) => topic._id)
            );
            setSelectedTopics(defaultSelectedTopics);
        }
    }, [qTopicSections]);

    // Scroll to top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleChange = (e) => {
        const topicId = e.target.value;
        setSelectedTopics((prevSelectedTopics) =>
            prevSelectedTopics.includes(topicId)
                ? prevSelectedTopics.filter((id) => id !== topicId)
                : [...prevSelectedTopics, topicId]
        );
    };

    const submitHandler = () => {
        if (selectedTopics.length < 1) {
            alert.error("Please select at least one topic to proceed.");
        } else {
            localStorage.setItem("selectedTopics", selectedTopics);
            history.push(`/start-exam`);
        }
    };

    const goToPreviousPage = () => {
        history.push(`/registered-subjects`);
    };


    return (
        <>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <ul className="clearfix paginations-cust">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Select Topics</li>
            {qTopicSections.map((item, index) => (
              <li key={index}>{item.subject.title}</li>
            ))}
          </ul>
        </div>
      </section>

      {loading ? (
        <Loader />
      ) : (
        <div className="TopicSection">
          {qTopicSections?.map((section, index) => (
            <div key={index}>
              <div className="end-plan" style={{ marginTop: "20px" }}>
                <h1 className="text-center">{section?.subject?.title}</h1>
              </div>
              <ul className="grid">
                {section?.topics?.map((topic) => (
                  <li className="card" key={topic._id}>
                    <div className="card__content">
                      <div className="Topic_title">
                        <span>Topic: </span>
                        <p>{topic.title}</p>
                      </div>
                      <div className="Topic_title">
                        <span>Topic Section: </span>
                        <ul>
                          {topic?.topicSections.map((topicSection) => (
                            <li key={topicSection._id}>{topicSection.section_name}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <label className="checkbox-control">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectedTopics.includes(topic._id)}
                        value={topic._id}
                        onChange={handleChange}
                      />
                      <span className="checkbox-control__target">Card Label</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <div className="next-prev-steps text-center">
            <button
              type="button"
              className="chose-more"
              onClick={goToPreviousPage}
            >
              <i className="fas fa-arrow-left" />
              &nbsp;Previous
            </button>
            &nbsp;
            <button type="button" className="chose-more" onClick={submitHandler}>
              Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      )}
    </>

    )
}

export default SelectSubjectTopic