import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import moment from 'moment';
import Loader from '../Loader';
import CustomDataTable from '../Datatable/CustomDataTable';
import './subject.css';
import { getSavedExamSubjects, saveExamSubjects } from '../../actions/admin/examSubjectAction';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getUserSubscription } from '../../actions/subscriptionActions';

const SavedExamSubjects = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { userSubscription, sloading } = useSelector((state) => state.examSubscriptions);
    const { savedSubjects, csloading } = useSelector((state) => state.examSubjects);

    const [currSubjects, setCurrSubjects] = useState([]);
    const [proceedSubjects, setProceedSubjects] = useState([]);
    const [registeredSubject, setRegisteredSubject] = useState([]);
    const [updateRequest, setUpdateRequest] = useState([]);
    const [maxSubjects, setMaxSubjects] = useState("");

    const userId = user?._id;
    const router = useHistory();

    useEffect(() => {
        if (userId) {
            dispatch(getSavedExamSubjects(userId));
        }
    }, [dispatch, userId]);


    useEffect(() => {
        if (savedSubjects && savedSubjects.length > 0) {
            const tempSub = savedSubjects.map(item => ({
                itemId: item._id,
                examSlug: item.exam.slug,
                subjects: []
            }));
            setCurrSubjects(tempSub);

            const max = savedSubjects[0].exam.maximumSubjects;
            setMaxSubjects(max);

            const temp = savedSubjects.flatMap(item => item.subjectsProcessed.map(sub => sub));
            setProceedSubjects(temp);

            const temp1 = savedSubjects.flatMap(item => item.subjects.map(sub => sub.subject._id));
            setRegisteredSubject(temp1);

            const update = savedSubjects[0].subjectsUpdate || [];
            setUpdateRequest(update);
        }
    }, [savedSubjects]);

    useEffect(() => {        
        if (userSubscription !== null && userSubscription !== undefined) {
            if (userSubscription || userSubscription?._id) {
                localStorage.setItem("userSubscription", userSubscription._id);
            } else {
                confirmAlert({
                    title: 'Error',
                    message: <div><p>Subscription id {userSubscription?._id} missing.</p></div>,
                    buttons: [{ label: 'OK', onClick: () => { } }],
                });
                return;
            }
        }
    }, [userSubscription]);


    const handleCheck = (e, subject) => {
        const CurrentSubjectIndex = e.target.dataset.index;
        const temp = [...currSubjects];
        const updatedArr = temp.map(item => {
            if (item.itemId === CurrentSubjectIndex) {
                let checkArray = [...item.subjects, e.target.value];
                if (item.subjects.includes(e.target.value)) {
                    checkArray = checkArray.filter(sub => sub !== e.target.value);
                }
                return { ...item, subjects: checkArray };
            }
            return item;
        });
        setCurrSubjects(updatedArr);
    };

    const generateStyledSubjects = (subjectIds, examId) => {
        return savedSubjects
            .filter(element => element.exam._id === examId)
            .map(element => {
                return subjectIds.map((id, index) => {
                    const subject = element.subjects.find(c => c.subject._id === id);
                    const isMandatory = subject?.mandetory === true;
                    return (
                        <span key={id} style={{ fontWeight: isMandatory ? 'bold' : 'normal', color: isMandatory ? 'red' : 'black' }}>
                            <strong>({index + 1}) </strong>{subject ? subject.subject.title : ''}{' '}
                        </span>
                    );
                });
            });
    };


    const handleCustomize = (item, action) => {
        
        localStorage.setItem("examId", item?.exam?._id);
        localStorage.setItem("examSlug", item?.exam?.slug);
        localStorage.setItem("examName", item?.exam?.title);
        localStorage.setItem("examType", item?.exam?.examType);
        localStorage.setItem("examinationType", item?.exam?.examType);
        localStorage.setItem("categoryId", item?.category?._id);
        localStorage.setItem("courseId", item?.course?._id);
        localStorage.setItem("maximumSubjects", item?.exam?.maximumSubjects);
        localStorage.setItem("userSubscription", item?.subscriptionPlan?._id);

        const temp = item.subjects.map(sub => sub.subject._id);
        
        
        localStorage.setItem("processedSubjects", temp.join(','));
        localStorage.setItem("subjects", temp.join(','));

        if (action === 'go_to_exam') {
            history.push(`/exam-type`);
        } else if (item.subjectsProcessed.length === item.exam.maximumSubjects) {
            history.push(`/exam-type`);
        } else {
            router.push(`/course-subjects`);
        }
    };

    const handleSubmit = (e, item) => {
        e.preventDefault();
        dispatch(getUserSubscription(userId, item.exam._id, item?.subscriptionPlan?.university?._id));
        const CurrentSubjectIndex = e.target.dataset.id;
        const CurrentSubj = currSubjects.find(item => item.itemId === CurrentSubjectIndex);

        if (CurrentSubj && CurrentSubj.subjects.length > 0) {
            const selectedSubjectsInfo = generateStyledSubjects(CurrentSubj?.subjects, item.exam._id);
            confirmAlert({
                title: 'Confirmation',
                message: (
                    <div>
                        <p>Are you sure you want to register these <strong className="highlighted" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}>{selectedSubjectsInfo}</strong> subjects?</p>
                        <p>Click "Yes" to confirm.</p>
                        <p className="bg-danger" style={{ color: 'red' }}>Note: You cannot change the selected subject after proceed.</p>
                    </div>
                ),
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const checkArray = CurrentSubj?.subjects;
                            
                            let proceedArray = [...item?.subjectsProcessed, ...checkArray.filter(item => !item?.subjectsProcessed?.includes(item))];
                           
                            
                            setProceedSubjects(proceedArray);

                            const payload = {
                                userId: user?._id,
                                examId: item?.exam?._id,
                                categoryId: item?.category?._id,
                                courseId: item?.course?._id,
                                userSubscription: item?.subscriptionPlan?._id,
                                subjects: [],
                                subjectsProcessed: proceedArray,
                                subjectsUpdate: [],
                                updateRequest: false
                            };

                            dispatch(saveExamSubjects(payload));

                            if (item.subscriptionPlan.planName === 'Trial') {
                                localStorage.setItem("questionCount", item.subscriptionPlan.numOfQuestions);
                            } else {
                                localStorage.removeItem("questionCount");
                            }

                            localStorage.setItem("maximumSubjects", item?.exam?.maximumSubjects);
                            localStorage.setItem("examId", item?.exam?._id);
                            localStorage.setItem("examSlug", item?.exam?.slug);
                            localStorage.setItem("examName", item?.exam?.title);
                            localStorage.setItem("examinationType", item?.exam?.examType);
                            localStorage.setItem("categoryId", item?.category?._id);
                            localStorage.setItem("courseId", item?.course?._id);
                            localStorage.setItem("userSubscription", item?.subscriptionPlan?._id);
                            localStorage.setItem("subjects", proceedArray);
                            history.push(`/exam-type`);
                        },
                    },
                    { label: 'No', onClick: () => { } },
                ],
            });
        } else if (registeredSubject.length === 0) {
            confirmAlert({
                title: 'Error',
                message: <div><p>Please select at least one subject to proceed.</p></div>,
                buttons: [{ label: 'OK', onClick: () => { } }],
            });
        }
    };

    const tableHeader = [
        { title: 'ID', prop: 'id', sortable: true, filterable: true },
        { title: 'Exam', prop: 'exam', sortable: true, filterable: true },
        { title: 'University', prop: 'university', sortable: true, filterable: true },
        { title: 'Category', prop: 'category', sortable: true, filterable: true },
        { title: 'Course', prop: 'course', sortable: true, filterable: true },
        { title: 'Subscription Plan', prop: 'subscriptionPlan', sortable: true, filterable: true },
        { title: 'Subjects', prop: 'subjects', sortable: true, filterable: true },
        { title: 'Date', prop: 'date', sortable: true, filterable: true },
        { title: 'Action', prop: 'action' }
    ];


    const tableRowData = savedSubjects.map((item, key) => ({
        id: key + 1,
        exam: item?.exam?.title,
        university: item?.subscriptionPlan?.university?.title,
        category: item?.category?.title,
        course: item?.course?.title,
        subscriptionPlan: <span>{item?.subscriptionPlan?.planName} {item?.subscriptionPlan?.subscriptionType}</span>,
        subjects: (
            <ul className="subject-list">
                {item.subjects.map((subject, iKey) => (
                    <li key={iKey}>
                        {!item?.subjectsProcessed?.includes(subject?.subject?.id) && (
                            <input
                                type="checkbox"
                                name="subject"
                                id={`subject-${subject?.subject?.id}-${key}`}
                                onClick={(e) => handleCheck(e, subject)}
                                value={subject?.subject?.id}
                                data-index={item?._id}
                                data-exam={item?.exam?.slug}
                            />
                        )}
                        <label htmlFor={`subject-${subject?.subject?.id}-${key}`}>
                            {subject.subject.title} {item?.subjectsProcessed?.includes(subject?.subject?.id) && <i className="fa fa-check" style={{ color: "green" }} />}
                        </label>
                    </li>
                ))}
            </ul>
        ),
        date: moment(item.createdAt).format('YYYY-MM-DD'),
        action: (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <button
                    className="btn btn-primary"
                    onClick={(e) => handleSubmit(e, item)}
                    data-id={item._id}
                    data-subjects={item.subjects}
                    data-idx={key}
                    data-exam={item.exam.slug}
                    disabled={item.subjectsProcessed.length === item.exam.maximumSubjects}
                >
                    {item.subjectsProcessed.length !== item.exam.maximumSubjects ? "Register" : "Subjects Registered"}
                </button>
                {item.exam.slug === 'post-utme' && item.subjectsProcessed.length > 0 && item.subjectsProcessed.length < item.exam.maximumSubjects && (
                    <button
                        className="btn btn-warning"
                        style={{ marginTop: "2rem" }}
                        onClick={() => handleCustomize(item, 'go_to_exam')}
                        data-id={item._id}
                        data-subjects={item.subjects}
                        data-idx={key}
                    >
                        Go To Exam
                    </button>
                )}
                <button
                    className="btn btn-warning"
                    style={{ marginTop: "2rem" }}
                    onClick={() => handleCustomize(item)}
                    data-id={item._id}
                    data-subjects={item.subjects}
                    data-idx={key}
                >
                    {item.subjects.length !== item.exam.maximumSubjects ? "Choose Remaining Subjects" :
                        item.subjectsProcessed.length < item.exam.maximumSubjects ?
                            "Exchange Selected Subjects" : "Go To Exam"}
                </button>
                <Link
                    to={'/saved-subjects-update'}
                    className="btn btn-warning"
                    onClick={() => {
                        localStorage.setItem("maximumSubjects", item.exam.maximumSubjects);
                        localStorage.setItem("courseId", item.course._id);
                        localStorage.setItem("examId", item.exam._id);
                        localStorage.setItem("examSlug", item.exam.slug);
                        localStorage.setItem("examName", item.exam.title);
                        localStorage.setItem("examinationType", item.exam.examType);
                        localStorage.setItem("categoryId", item.category._id);
                        localStorage.setItem("userSubscription", item.subscriptionPlan._id);
                    }}
                    disabled={item.updateRequest ? true : item.subjectsProcessed.length !== item.exam.maximumSubjects}
                    style={{ marginTop: "2rem" }}
                >
                    {item.updateRequest ? "Subjects Already Changed" : "Request For Subject Change"}
                </Link>
            </div>
        )
    }));

    const onSortFunction = {
        date(columnValue) {
            return moment(columnValue, 'Do MMMM YYYY').valueOf();
        }
    };

    const customLabels = {
        first: '<<',
        last: '>>',
        prev: '<',
        next: '>',
        show: 'Display',
        entries: 'rows',
        noResults: 'There are no data to be displayed'
    };

    return (
        <Fragment>
            {csloading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="right-auth-landing">
                        <div className="main-view-content">
                            <div className="all-ourt-style work-info">
                                <div className="all-heads">
                                    <h3>Saved Exams Subjects</h3>
                                </div>
                                <CustomDataTable
                                    tableHeaders={tableHeader}
                                    tableBody={tableRowData}
                                    rowsPerPage={10}
                                    rowsPerPageOption={[10, 20, 30, 40]}
                                    initialSort={{ prop: 'course', isAscending: true }}
                                    onSort={onSortFunction}
                                    labels={customLabels}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default SavedExamSubjects;
