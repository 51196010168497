import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { useDispatch, useSelector } from "react-redux";
import {
  getPageDetail,
  updatePage,
  clearErrors,
} from "../../../actions/adminCmsActions";
import { UPDATE_CMS_RESET } from "../../../constants/cmsConstants";

import Loader from "../../Loader";

import { Editor } from '@tinymce/tinymce-react';

const EditCms = ({ history, match }) => {
  const [pageId, setPageId] = useState("");
  const [page_title, setPageTitle] = useState("");
  const [content, setContent] = useState("");
  const [status, setStatus] = useState(false);

  const alert = useAlert();
  const dispatch = useDispatch();

  const changeEditorText = (contentData) => {
		setContent(contentData);
	};

  const { isPageUpdated, page_loading, error } = useSelector(
    (state) => state.cmsList
  );

  const currentUserId = match.params.id;
  useEffect(() => {
    dispatch(getPageDetail(currentUserId));
  }, [dispatch]);

  const { page } = useSelector((state) => state.cmsDetail);

  useEffect(() => {
    //console.log("page.content ", page.content);
    if (page) {
      setPageId(page._id);
      setPageTitle(page.page_title);
      setContent(page.content);
      setStatus(false);
      if (page.status === "Active") {
        setStatus(true);
      }
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isPageUpdated) {
      alert.success("Page updated successfully");
      dispatch(getPageDetail(page._id));

      history.push(`/admin/pages`);

      dispatch({
        type: UPDATE_CMS_RESET,
      });
    }
  }, [dispatch, page, alert, error, history, isPageUpdated]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updatePage(pageId, page_title, content, status));
  };

  return (
    <Fragment>
      {page_loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="content-wrapper">
            <section className="content-header">
              <h1>
                Edit
                <small>Page</small>
              </h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/admin">
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to="/admin/pages">
                    <i className="fa fa-users"></i> Pages
                  </Link>
                </li>
                <li className="active">Edit Page</li>
              </ol>
            </section>

            <section className="content">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Edit Page</h3>

                  {/*<div className="box-tools pull-right">

                                    </div>*/}
                </div>
                <form id="quickForm" onSubmit={submitHandler}>
                  <div className="box-body">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="page_title">Title</label>
                        <input
                          type="text"
                          name="page_title"
                          className="form-control"
                          id="page_title"
                          placeholder="Enter Title"
                          value={page_title}
                          onChange={(e) => setPageTitle(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="content">Description</label>

                        <Editor
													apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
													init={{
														height: 200,
														menubar: false,
														external_plugins: {},
														plugins: [
															'advlist autolink lists link image imagetools charmap print preview anchor',
															'searchreplace visualblocks code fullscreen',
															'insertdatetime media table paste code help wordcount'
														],
														toolbar:
															'undo redo | link image | formatselect ' +
															'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
															'bullist numlist outdent indent | ' +
															'removeformat | code | help',
														content_style:
															'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
														file_picker_types: 'image'
													}}
													value={content}
													// initialValue={question}
													onEditorChange={(newText) => changeEditorText(newText)}
												/>
                        
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <div className="col-md-6 row">
                        <div className="form-check">
                          <input
                            style={{ marginRight: "5px" }}
                            type="checkbox"
                            className="form-check-input"
                            id="status"
                            checked={status}
                            value={status}
                            onChange={(e) => setStatus(!status)}
                          />
                          <label className="form-check-label" htmlFor="status">
                            {" "}
                            Active
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditCms;
