import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCourseSubjects } from './../../actions/subjectActions';
import Loader from '../Loader';
import { getSavedExamSubjects, saveExamSubjects } from '../../actions/admin/examSubjectAction';
import { confirmAlert } from 'react-confirm-alert';
import { getUserSubscription } from '../../actions/subscriptionActions';

const CourseSubjects = ({ history, match }) => {
    const dispatch = useDispatch();

    // State variables for localStorage items
    const [examId, setExamId] = useState('');
    const [examName, setExamName] = useState('');
    const [examSlug, setExamSlug] = useState('');
    const [courseId, setCourseId] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [ifRegSubjects, setIfRegSubjects] = useState('');
    const [maximumSubjects, setMaximumSubjects] = useState('');
    const [universityId, setUniversityId] = useState('');
    const [subscriptionId, setSubscriptionId] = useState('');

    // Other state variables
    const [disabled, setDisabled] = useState(true);
    const [slugs, setSlugs] = useState([]);
    const [isChecked, setIsChecked] = useState([]);
    const [regSubjects, setRegSubjects] = useState([]);
    const [checkedSubjects, setCheckedSubjects] = useState([]);
    const [proceedSubjects, setProceedSubjects] = useState([]);
    const [maxSubjects, setMaxSubjects] = useState([]);
    const [filteredSubject, setFilteredSubject] = useState([]);

    // Redux state
    const { user } = useSelector((state) => state.auth);
    const { savedSubjects, isExamSubjects } = useSelector((state) => state.examSubjects);
    const { courseSubjects, csloading, isCourseSubject } = useSelector((state) => state.courseSubjects);
    const { sloading } = useSelector((state) => state.examSubscriptions);

    const userId = user?._id;

    // Fetch localStorage items and set them in state
    useEffect(() => {
        setExamId(localStorage.getItem('examId'));
        setExamName(localStorage.getItem('examName'));
        setExamSlug(localStorage.getItem('examSlug'));
        setCourseId(localStorage.getItem('courseId'));
        setCategoryId(localStorage.getItem('categoryId'));
        setIfRegSubjects(localStorage.getItem('RegSubjects'));
        setMaximumSubjects(localStorage.getItem('maximumSubjects'));
        setUniversityId(localStorage.getItem('universityId'));
        setSubscriptionId(localStorage.getItem('userSubscription'));
    }, []);

    // Fetch localStorage items and dispatch actions
    useEffect(() => {
        if (examId) {
            dispatch(getCourseSubjects(courseId, examId, universityId));
        }
        if (examId && userId && universityId) {
            dispatch(getUserSubscription(userId, examId, universityId));
        }
    }, [userId, dispatch, examId, courseId, universityId]);

    // Fetch saved exam subjects
    useEffect(() => {
        dispatch(getSavedExamSubjects(userId));
    }, [isCourseSubject, userId, dispatch]);

    // Scroll to the top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Process saved exam subjects
    useEffect(() => {
        if (savedSubjects && savedSubjects.length > 0) {
            savedSubjects.forEach((item) => {
                if (item?.exam?._id === examId) {
                    setProceedSubjects(item?.subjectsProcessed?.map((subject) => subject) || []);
                    if (universityId) {
                        const filteredSubjects = item?.subjects
                            ?.filter((subject) => subject?.subject?.university[0] === universityId)
                            ?.map((subject) => subject?.subject?._id);
                        setRegSubjects(filteredSubjects || []);
                    } else {
                        setRegSubjects(item?.subjects?.map((subject) => subject?.subject?._id) || []);
                    }
                }
            });
        }
    }, [isExamSubjects, examId, universityId]);

    // Process course subjects and set state
    useEffect(() => {
        let subjects = [];
        if (courseSubjects && courseSubjects.length > 0) {
            if (universityId) {
                const filteredCourseSubjects = courseSubjects.filter(cs =>
                    cs.university.includes(universityId)
                );
                subjects = filteredCourseSubjects;
                setFilteredSubject(filteredCourseSubjects);
            } else {
                subjects = courseSubjects;
                setFilteredSubject(courseSubjects);
            }
        }

        if (subjects && subjects.length > 0) {
            setMaxSubjects(subjects[0]?.exam?.maximumSubjects);
            const mandatorySubjects = subjects
                .filter(cs => cs.mandetory === true)
                .map(cs => cs._id);

            const uncheckedSubjects = regSubjects.filter(item => !item?.mandetory && !isChecked.includes(item));
            const mergedSubSet = new Set([...mandatorySubjects, ...uncheckedSubjects, ...isChecked]);
            const mergedSub = Array.from(mergedSubSet);

            setIsChecked(mergedSub);
            const SubjectsWithStyled = generateStyledSubjects(mergedSub);
            setCheckedSubjects(SubjectsWithStyled);
        }
    }, [isCourseSubject, regSubjects, universityId, courseSubjects]);


    // Generate styled subjects for display
    const generateStyledSubjects = (subjectIds) => {
        return subjectIds.map((id, index) => {
            const subject = filteredSubject.find((c) => c?._id === id);
            const isMandatory = subject?.mandetory === true;
            return (
                <span key={id} style={{ fontWeight: isMandatory ? 'bold' : 'normal', color: isMandatory ? 'red' : 'black' }}>
                    <strong>({index + 1}) </strong>{subject ? subject.title : ''}{' '}
                </span>
            );
        });
    };

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        const currentChecked = isChecked;
        if (currentChecked.length >= maxSubjects && !currentChecked.includes(event.target.value)) {
            const selectedSubjectsInfo = generateStyledSubjects(currentChecked);
            setCheckedSubjects(selectedSubjectsInfo);
            confirmAlert({
                title: 'Error',
                message: (
                    <div>
                        <p>
                            You have reached the maximum limit (<span style={{ fontWeight: 'bold', color: 'black' }}>{maximumSubjects}</span>) of selected subjects.
                        </p>
                        <p>
                            Selected Subjects: {selectedSubjectsInfo}
                        </p>
                        <p>
                            If you want to select another subject, please uncheck the selected subject(s) and choose another subject(s).
                        </p>
                        <p className="bg-danger" style={{ color: 'red' }}>
                            Note: Mandatory subjects cannot be deselected.
                        </p>
                    </div>
                ),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {},
                    },
                ],
            });
            return;
        }

        let newArray = [...isChecked, event.target.value];
        if (isChecked.includes(event.target.value) && filteredSubject?.find((c) => c?._id === event.target.value)?.mandetory === false) {
            newArray = newArray.filter((day) => day !== event.target.value);
        }

        setIsChecked(newArray);
        const selectedSubjectsInfo = generateStyledSubjects(newArray);
        setCheckedSubjects(selectedSubjectsInfo);
        if (event.target.checked === true) {
            setDisabled(false);
        }

        let newArraySlug = [...slugs, event.target.dataset.slug];
        if (slugs.includes(event.target.dataset.slug)) {
            newArraySlug = newArraySlug.filter((day) => day !== event.target.dataset.slug);
        }
        setSlugs(newArraySlug);
    };

    // Handle form submission
    const submitHandler = (e) => {
        e.preventDefault();
        const remainingSub = maximumSubjects - isChecked?.length;

        if (proceedSubjects?.length !== maximumSubjects) {
            confirmAlert({
                title: 'Confirmation',
                message: (
                    <div>
                        <p>
                            Are you sure you want to select these <strong className="highlighted" style={{ fontWeight: 'bold', backgroundColor: 'yellow' }}>{checkedSubjects}</strong> subjects?
                        </p>
                        {remainingSub > 0 && <p>
                            You can choose another {remainingSub} more subjects.
                        </p>}
                        {proceedSubjects?.length === 0 && <>
                            <p>You have not registered any subject yet, go for register</p>
                            <p>
                                Click "Yes" to confirm.
                            </p>
                        </>}
                        {proceedSubjects?.length !== 0 && proceedSubjects?.length !== maximumSubjects &&
                            <>
                                <p>
                                    Click "Yes" to go to register.
                                </p>
                                <p>
                                    Click "No" to go to exam.
                                </p>
                            </>
                        }
                    </div>
                ),
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            const payload = {
                                userId: user._id,
                                examId: examId,
                                categoryId: categoryId ? categoryId : null,
                                courseId: courseId ? courseId : null,
                                userSubscription: subscriptionId,
                                subjects: isChecked,
                                subjectsProcessed: proceedSubjects,
                                subjectsUpdate: [],
                                updateRequest: false
                            };
                            dispatch(saveExamSubjects(payload));
                            dispatch(getSavedExamSubjects(userId));
                            history.push('/saved-subjects');
                        },
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            if (proceedSubjects?.length !== 0) {
                                history.push('/exam-type');
                            }
                        },
                    },
                ],
            });
        } else {
            history.push('/exam-type');
        }

        localStorage.setItem('subjects', isChecked);
        localStorage.setItem('subjectMsg', 'Subject registered successfully.');
    };

    // Handle navigation to the previous page
    const goToPreviousPage = (e) => {
        e.preventDefault();
        setIsChecked('');
        if (examName === "JSCE (BECE)") {
            history.push('/exams');
        } else {
            history.push('/category-courses');
        }
    };

    return (
        <Fragment>
            {/* Page Banner Section */}
            <section
                className="page-banner-sec text-center"
                style={{
                    backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`
                }}
            >
                <div className="container">
                    <ul className="clearfix paginations-cust">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/category-courses">Intended Courses Of Study</Link>
                        </li>
                    </ul>
                    <h1>Subject of Study</h1>
                    {examSlug === "jamb-utme" ? (
                        <h3>Subject registration - select any {maximumSubjects} subjects</h3>
                    ) : (
                        <h3>Select {maximumSubjects} Subjects of Choice Required for Intended Course of Study for University/Tertiary Education</h3>
                    )}
                </div>
            </section>

            {/* Subscription Page Section */}
            <section className="subscription-page">
                <div className="container">
                    <div className="end-plan">
                        <h1>Suggested {examName} Subject Combination</h1>
                    </div>
                    <div className="custom-radios-plan custom-check-box course-spaner">
                        {csloading || sloading ? (
                            <Loader />
                        ) : (
                            <div className="wrapper clearfix">
                                {filteredSubject !== undefined && filteredSubject.length > 0 ? (
                                    filteredSubject.map((item, key) => {
                                        const indexId = key + 1;
                                        return (
                                            <Fragment key={indexId}>
                                                <input
                                                    type="checkbox"
                                                    name="subjects"
                                                    id={`option-${indexId}`}
                                                    value={item._id}
                                                    checked={isChecked.includes(item._id)}
                                                    onChange={handleCheckboxChange}
                                                    data-slug={item.slug}
                                                    disabled={proceedSubjects.includes(item._id)}
                                                />
                                                <label
                                                    htmlFor={`option-${indexId}`}
                                                    className={`option option-${indexId}`}
                                                >
                                                    <div className="dot" />
                                                    <div className="extype">
                                                        <img
                                                            src={process.env.REACT_APP_PUBLIC_URL + '/assets/img/subjects/' + item.logo}
                                                            alt="subject logo"
                                                        />
                                                    </div>
                                                    <span>{item.title}{proceedSubjects.includes(item._id) || item?.mandetory && <span>Already Registered <h5 className='bg-red'>{item?.mandetory && "Mandatory"}</h5></span>}</span>
                                                </label>
                                            </Fragment>
                                        );
                                    })
                                ) : (
                                    <span className='text-center'>Subjects not found</span>
                                )}
                            </div>
                        )}
                    </div>
                    {ifRegSubjects !== undefined && ifRegSubjects !== null ?
                        <div className="next-prev-steps text-center">
                            <button className="chose-more" onClick={submitHandler}>
                                Update
                            </button>
                        </div>
                        :
                        <div className="next-prev-steps text-center">
                            <button onClick={goToPreviousPage} className="chose-more">
                                <i className="fas fa-arrow-left" /> Prev
                            </button>{' '}
                            <button
                                type="submit"
                                className="chose-more"
                                onClick={submitHandler}
                            >
                                Next <i className="fas fa-arrow-right" />
                            </button>
                        </div>}
                </div>
            </section>
        </Fragment>
    );
};

export default CourseSubjects;
