import axios from 'axios';
import {
	COURSE_SUBJECTS_REQUEST,
	COURSE_SUBJECTS_SUCCESS,
	COURSE_SUBJECTS_FAIL,
	REGISTERED_SUBJECTS_REQUEST,
	REGISTERED_SUBJECTS_SUCCESS,
	REGISTERED_SUBJECTS_FAIL,
	GET_SUBJECT_REQUEST,
	GET_SUBJECT_SUCCESS,
	GET_SUBJECT_FAIL,
	SAVE_SUBJECT_REQUEST,
	SAVE_SUBJECT_SUCCESS,
	SAVE_SUBJECT_FAIL,

	GET_SAVED_SUBJECTS_REQUEST,
	GET_SAVED_SUBJECTS_SUCCESS,
	GET_SAVED_SUBJECTS_FAIL,

	CLEAR_ERRORS
} from '../constants/subjectConstants';

const _api_path = '/api/v1';

//get all subjects
export const getCourseSubjects = (courseId, examId, universityId = null) => async (dispatch) => {
	try {
		dispatch({
			type: COURSE_SUBJECTS_REQUEST
		});

		const { data } = await axios.post(`${_api_path}/get-course-subjects/`, { courseId, examId, universityId });

		const examID = localStorage.getItem("examId");
		const filteredSubject = data?.data?.filter((item) => item.exam == examID)

		dispatch({
			type: COURSE_SUBJECTS_SUCCESS,
			payload: filteredSubject?.length > 0 ? filteredSubject : data?.data
		});
	} catch (error) {
		dispatch({
			type: COURSE_SUBJECTS_FAIL,
			payload: error
		});
	}
};

//get all selected subjects
export const getSelectedSubjects = (subjects) => async (dispatch) => {
	try {
		dispatch({
			type: REGISTERED_SUBJECTS_REQUEST
		});

		const { data } = await axios.post(`${_api_path}/get-registered-subjects`, {
			subjects: subjects
		});

		dispatch({
			type: REGISTERED_SUBJECTS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: REGISTERED_SUBJECTS_FAIL,
			payload: error
		});
	}
};

//get subject detail
export const getSubjectById = (id) => async (dispatch) => {
	try {
		dispatch({
			type: GET_SUBJECT_REQUEST
		});

		const { data } = await axios.get(`${_api_path}/get-subject/${id}`);
		// console.log("data ", data);
		// return;
		dispatch({
			type: GET_SUBJECT_SUCCESS,
			payload: data.subject
		});
	} catch (error) {
		// console.log("error.response ", error);
		// return;
		dispatch({
			type: GET_SUBJECT_FAIL,
			payload: error.response.data.errMessage
		});
	}
};

/**
 * Save subject request
 * @returns 
 */
export const saveSelectedSubjects = (userId, examId, examType, categoryId, courseId, assessmentId, subjects, regSubId) => async (
	dispatch
) => {
	try {
		dispatch({
			type: SAVE_SUBJECT_REQUEST
		});

		const { data } = await axios.post(`${_api_path}/save-registered-subjects`, {
			userId,
			examId,
			examType,
			categoryId,
			courseId,
			assessmentId,
			subjects,
			regSubId
		});

		dispatch({
			type: SAVE_SUBJECT_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: SAVE_SUBJECT_FAIL,
			payload: error
		});
	}
};

//get all selected subjects
export const getSavedSubjects = (userId) => async (dispatch) => {
	try {
		dispatch({
			type: GET_SAVED_SUBJECTS_REQUEST
		});

		const { data } = await axios.get(`${_api_path}/get-saved-subjects/${userId}`);
		// console.log("data", data); return false;
		dispatch({
			type: GET_SAVED_SUBJECTS_SUCCESS,
			payload: data
		});

	} catch (error) {
		dispatch({
			type: GET_SAVED_SUBJECTS_FAIL,
			payload: error
		});
	}
};

//clear errors
export const clearErrors = () => async (dispatch) => {
	dispatch({
		type: CLEAR_ERRORS
	});
};
