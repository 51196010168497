import React, { useState, useEffect } from "react";

const TypingAnimation = ({ text, typingSpeed = 50 }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, typingSpeed);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [text]);

  return (
    <div
      style={{
        padding: "10px",
        overflow: "hidden",
        fontSize: "19px",
        lineHeight: "1.5",
        wordWrap: "break-word",
      }}
    >
      {displayedText}
    </div>
  );
};

export default TypingAnimation;
