import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Moment from "moment";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import { getStudentSubscriptions } from "../../../actions/adminSubscriptionActions";

import Loader from "../../Loader";

const ListUserSubscriptions = ({ history }) => {
  // const [users, setUsersData] = useState({});
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudentSubscriptions());
  }, [dispatch]);

  const { studentSubscriptions, sloading } = useSelector(
    (state) => state.subscriptions
  );
  return (
    <Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Manage
            <small>Student Subscriptions</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/admin">
                <i className="fa fa-dashboard"></i> Dashboard
              </Link>
            </li>
            <li className="active">Student Subscriptions</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box">
                <div className="box-header">
                  <h3 className="box-title">Student Subscriptions</h3>
                  <div className="box-tools"></div>
                </div>
                <div className="box-body table-responsive no-padding">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Student Name</th>
                        <th>Exam Type</th>
                        <th>Subscription Type</th>
                        <th>Duration</th>
                        <th>Price</th>
                        <th>Subscribed At</th>
                        <th>Expiration</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sloading ? (
                        <Loader
                          type="Puff"
                          color="#00BFFF"
                          height={50}
                          width={50}
                        />
                      ) : studentSubscriptions !== undefined &&
                        studentSubscriptions?.length ? (
                        studentSubscriptions?.map((item) => (
                          <tr key={item._id}>
                            <td>
                              {item?.user?.first_name} {item?.user?.last_name}
                            </td>
                            <td>{item?.exam.title}</td>
                            <td>{item?.subscriptionType}</td>
                            <td>{item?.duration}</td>
                            <td>₦{item?.price}</td>
                            <td>
                              {Moment(item?.subscribedAt).format("YYYY-MM-DD")}
                            </td>
                            <td>
                              {Moment(item?.expireAt).format("YYYY-MM-DD")}
                            </td>
                            
                            <td>
                              {item?.status === true && (
                                <span className="badge bg-green">Active</span>
                              )}
                              {item?.status === false && (
                                <span className="badge bg-yellow">
                                  InActive
                                </span>
                              )}
                            </td>
                            
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default ListUserSubscriptions;
