import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import "../CourseSubjects/course-subjects.css"
import { getCategoryCourses } from "./../../actions/courseActions";
import { getCourseSubjects } from './../../actions/subjectActions';
import Loader from "../Loader";
import './course.css'

const CategoryCourses = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const [courseId, setCourseId] = useState("");
    const [examDetails, setExamDetails] = useState({
        examId: "",
        examSlug: "",
        categoryId: "",
        examName: "",
        logo: "",
    });
    const [courseName, setCourseName] = useState("");

    const { courses, cloading } = useSelector((state) => state.categoryCourses);
    const { courseSubjects, csloading } = useSelector((state) => state.courseSubjects);

    // Fetch localStorage items on mount
    useEffect(() => {
        const examId = localStorage.getItem("examId");
        const examSlug = localStorage.getItem("examSlug");
        const categoryId = localStorage.getItem("categoryId");
        const examName = localStorage.getItem("examName");
        const logo = localStorage.getItem("logo");
        const savedCourseId = localStorage.getItem("courseId");

        setExamDetails({ examId, examSlug, categoryId, examName, logo });
        setCourseId(savedCourseId || "");

        // Fetch courses for the category
        if (categoryId) {
            dispatch(getCategoryCourses(categoryId));
        }
        // Scroll to the top on component mount
        window.scrollTo(0, 0);
    }, [dispatch]);

    const handleCourseChange = (e, index, item) => {
        setCourseName(item.title);
        setCourseId(e.target.value);
        localStorage.setItem("courseId", e.target.value);

        // Fetch course subjects if examSlug is "post-utme"
        if (examDetails.examSlug === "post-utme") {
            dispatch(getCourseSubjects(e.target.value));
        }

        // Highlight the selected course
        courses.forEach((course, i) => {
            const indexId = i + 1;
            const label = document.getElementById(`option-${indexId}-label`);
            if (label) {
                label.classList.toggle("checked", parseInt(index) === indexId);
            }
        });
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (!courseId) {
            alert.error("Please select a course type to proceed.");
            return;
        }

        localStorage.setItem("courseId", courseId);

        if (examDetails.examSlug === "post-utme" && courseSubjects) {
            const subjectIds = courseSubjects.map((item) => item._id);
            localStorage.setItem("subjects", subjectIds);
            localStorage.setItem("utmeSubjects", JSON.stringify(courseSubjects));
        }

        history.push("/course-subjects");
    };

    return (
        <Fragment>
            <section
                className="page-banner-sec text-center"
                style={{
                    backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
                }}
            >
                <div className="container">
                    <ul className="clearfix paginations-cust">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to={`/categories/${examDetails.examId}`}>Intended Courses Types</Link>
                        </li>
                    </ul>
                    <h1>Course of Study</h1>
                </div>
            </section>

            <section className="subscription-page">
                <div className="container">
                    <div className="end-plan">
                        <h1>Select intended course of study at university</h1>
                    </div>
                    <div className="custom-radios-plan course-spaner">
                        {cloading ? (
                            <Loader />
                        ) : (
                            <div className="wrapper clearfix">
                                {courses?.map((item, key) => (
                                    <Fragment key={key + 1}>
                                        <div className="course_Card">
                                            <input
                                                type="radio"
                                                name="select"
                                                value={item._id}
                                                checked={item._id === courseId}
                                                id={`option-${key + 1}`}
                                                onChange={(e) => handleCourseChange(e, key + 1, item)}
                                            />
                                            <label
                                                htmlFor={`option-${key + 1}`}
                                                className={`option option-${key + 1}`}
                                                id={`option-${key + 1}-label`}
                                            >
                                                <div className="dot"></div>
                                                <span>{item.title}</span>
                                            </label>
                                        </div>
                                    </Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="next-prev-steps text-center">
                        <Link to={`/categories`} className="chose-more">
                            <i className="fas fa-arrow-left"></i> Prev
                        </Link>{" "}
                        <button
                            type="submit"
                            className="chose-more"
                            onClick={submitHandler}
                        >
                            Next <i className="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default CategoryCourses;
