import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";
import { PaystackConsumer } from 'react-paystack';

import {
    getExamSubscriptions,
    getSubscriptionById,
    userSubscribePlan,
} from "./../../actions/subscriptionActions";

import Loader from "../Loader";

const ExamSubscriptions = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [subscriptionType, setSubscriptionType] = useState("");
    const [subscriptionPrice, setSubscriptionPrice] = useState("");
    const [examId, setExamId] = useState(null);
    const [universityId, setUniversityId] = useState(null);
    const [examName, setExamName] = useState(null);

    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const { examSubscriptions, isPlanSubscribed, sloading, userSubscription } = useSelector(
        (state) => state.examSubscriptions
    );

    // Fetch data from localStorage on mount
    useEffect(() => {
        setExamId(localStorage.getItem("examId"));
        setUniversityId(localStorage.getItem("universityId"));
        setExamName(localStorage.getItem("examName"));
    }, []);

    // Fetch subscriptions when examId or userId changes
    useEffect(() => {
        if (examId && user?._id) {
            dispatch(getExamSubscriptions(examId, user._id));
        }
    }, [dispatch, examId, user?._id]);

    // Handle successful subscription
    useEffect(() => {
        if (isPlanSubscribed && userSubscription) {
            alert.success("Subscription plan subscribed successfully.");
            localStorage.setItem("userSubscription", userSubscription?._id);            
            const redirectPath = examName === "JSCE (BECE)" ? "/course-subjects" : "/categories";
            history.push(redirectPath);
        }
    }, [isPlanSubscribed, alert, examName, userSubscription]);


    const handleSubscriptionChange = (e) => {
        const price = e.target.dataset.price;
        setSubscriptionType(e.target.value);
        setSubscriptionPrice(price);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        // dispatch(userSubscribePlan(subscriptionType, user._id));
        const refId = Math.floor(Math.random() * 1000000);
        const paymentData = {
            currency: 'NGR',
            paymentMethod: 'Paystack',
            paymentMessage: "Successfull",
            refrenceId: refId,
            paymentTransaction: Math.floor(Math.random() * 100000),
            trxref: refId,
            paymentStatus: "success"
        }
        dispatch(userSubscribePlan(examId, universityId, subscriptionType, user._id, paymentData));
    };

    const validateSubscription = (e) => {
        e.preventDefault();

        if (!subscriptionType) {
            alert.error("Please choose a subscription plan.");
        }
    };

    //pk_test_c5f316c291b070ad3b3873412bbf76fc6bb57e29
    const config = {
        reference: new Date().getTime().toString(),
        email: user?.email,
        amount: subscriptionPrice * 100,
        publicKey: "pk_live_81018c0ededa75b06a011c2d65bccf4891630d40",
    };

    // you can call this function anything
    const handleSuccess = (reference) => {
        if (reference.message === 'Approved') {
            const paymentData = {
                currency: 'NGR',
                paymentMethod: 'Paystack',
                paymentMessage: reference.message,
                refrenceId: reference.reference,
                paymentTransaction: reference.transaction,
                trxref: reference.trxref,
                paymentStatus: reference.status
            }
            dispatch(userSubscribePlan(examId, universityId, subscriptionType, user._id, paymentData));
        } else {
            alert.success("Payment couldn't completed (" + reference.message + ").");
        }
    };

    // you can call this function anything
    const handleClose = () => {
        alert.error("You have cancelled the payment.");
      };

    const componentProps = {
    ...config,
    text: "Paystack Button Implementation",
    onSuccess: handleSuccess,
    onClose: handleClose,
  };

    // console.log("examSubj", examSubscriptions, userSubscription)

    return (
        <Fragment>
            <section
                className="page-banner-sec text-center"
                style={{
                    backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
                }}
            >
                <div className="container">
                    <ul className="clearfix paginations-cust">
                        <li>
                            <a href="#!">Home</a>
                        </li>
                        <li>
                            <a href="#!">Subscription</a>
                        </li>
                    </ul>
                    <h1>Subscription Plan</h1>
                </div>
            </section>

            <section className="subscription-page">
                <div className="container">
                    <div className="end-plan">
                        <h1>Your Trail Period Completed</h1>
                    </div>
                    <div className="custom-radios-plan">
                        {sloading ? (
                            <Loader />
                        ) : (
                            <div className="wrapper clearfix">
                                {examSubscriptions !== undefined && examSubscriptions.length > 0
                                    ? examSubscriptions.map((item, key) => {
                                        //   alert.success(item._id);
                                        const indexId = key + 1;
                                        return (
                                            <Fragment key={indexId}>
                                                <input
                                                    type="radio"
                                                    name="select"
                                                    id={`option-${indexId}`}
                                                    value={item._id}
                                                    data-price={item.price}
                                                    onClick={handleSubscriptionChange}
                                                />
                                                <label
                                                    htmlFor={`option-${indexId}`}
                                                    className={`option option-${indexId}`}
                                                >
                                                    <div className="dot"></div>
                                                    <span>
                                                        {item.title} {item.subscriptionType}{" "}
                                                        <b>{item.description}</b>
                                                    </span>
                                                    <div className="chose-more">
                                                        ₦ {item.price}{" "}
                                                        {/*<i className="fas fa-arrow-right"></i>*/}
                                                    </div>
                                                </label>
                                            </Fragment>
                                        );
                                    })
                                    : ""}
                            </div>
                        )}
                    </div>
                    <div className="next-prev-steps text-center">
                        <Link to="/exams" className="chose-more">
                            <i className="fas fa-arrow-left"></i> Prev
                        </Link>
                        {!subscriptionType ? (
                            <button
                                type="submit"
                                className="chose-more"
                                onClick={validateSubscription}
                            >
                                Next <i className="fas fa-arrow-right"></i>
                            </button>
                        ) : (parseInt(subscriptionPrice) === 0) ? (
                            <button
                                type="submit"
                                disabled={!subscriptionType}
                                className="chose-more"
                                onClick={submitHandler}
                            >
                                Next <i className="fas fa-arrow-right"></i>
                            </button>
                        ) : (
                            <PaystackConsumer {...componentProps} >
                                {({ initializePayment }) => <button className="chose-more" onClick={() => initializePayment(handleSuccess, handleClose)}>Next <i className="fas fa-arrow-right"></i></button>}
                            </PaystackConsumer>
                        )
                        }

                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default ExamSubscriptions;
