import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAlert } from 'react-alert';

import { useDispatch, useSelector } from 'react-redux';
import { addExam, clearErrors } from '../../../actions/adminExamActions';
import { ADD_EXAM_RESET } from './../../../constants/adminExamConstants';

import Loader from '../../Loader';
import './Exam.css';

import { Editor } from '@tinymce/tinymce-react';

const AddExam = ({ history, match }) => {
	const alert = useAlert();
	const dispatch = useDispatch();

	const [ exam, setExam ] = useState({
		title: '',
		logo: '',
		maximumSubjects: '',
		yearOfExamination: '',
		examType: '',
		timeHour: '',
		time: '',
		examInstructions: '',
		status: false,
		imageName: ''
	});
	const [ errorData, setErrorData ] = useState({});
	const {
		title,
		maximumSubjects,
		yearOfExamination,
		examType,
		timeHour,
		time,
		examInstructions,
		status,
		logo,
		imageName
	} = exam;

	const { isExamAdded, user_loading, errors, error } = useSelector((state) => state.examDetail);

	useEffect(
		() => {
			if (errors) {
				setErrorData(errors);
			}

			if (error) {
				clearErrors();
				return alert.error(error);
			}

			if (isExamAdded === true && isExamAdded != undefined) {
				alert.success('Exam successfully added.');
				history.push('/admin/exams');
				dispatch({
					type: ADD_EXAM_RESET
				});
			}
		},
		[ dispatch, isExamAdded, alert, errors, error ]
	);

	const onChange = (e) => {
		if (e.target.name === 'logo') {
			let file = e.target.files[0];

			if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
				setExam({
					...exam,
					logo: '',
					imageName: ''
				});
				alert.error('Please select valid logo.');
				//remove preview picture if any
				const preview = document.getElementById('img-preview');
				preview.src = '';
				return false;
			}

			const reader = new FileReader();

			reader.onload = function(readerEvt) {
				// The file's text will be printed here
				//console.log(readerEvt.target.result);
				let binaryString = readerEvt.target.result;
				let base64String = btoa(binaryString);
				setExam({
					...exam,
					logo: base64String,
					imageName: file.name
				});

				//preview picture
				const preview = document.getElementById('img-preview');
				// console.log('banary image', base64String);
				preview.src = 'data:image/png;base64,' + base64String;
			};
			reader.readAsBinaryString(file);
		} else {
			setExam({
				...exam,
				[e.target.name]: e.target.value
			});
		}
	};

	const changeEditorText = (data) => {
		setExam({
			...exam,
			examInstructions: data
		});
	};

	const submitHandler = (e) => {
		e.preventDefault();

		dispatch(
			addExam(
				title,
				maximumSubjects,
				yearOfExamination,
				examType,
				timeHour,
				time,
				examInstructions,
				status,
				logo,
				imageName
			)
		);
	};

	return (
		<Fragment>
			{user_loading ? (
				<Loader />
			) : (
				<Fragment>
					<div className="content-wrapper">
						<section className="content-header">
							<h1>
								Add
								<small>Exam</small>
							</h1>
							<ol className="breadcrumb">
								<li>
									<Link to="/admin">
										<i className="fa fa-dashboard" /> Dashboard
									</Link>
								</li>
								<li>
									<Link to="/admin/exams">
										<i className="fa fa-users" /> Exams
									</Link>
								</li>
								<li className="active">Add Exam</li>
							</ol>
						</section>

						<section className="content">
							<div className="box box-default">
								<div className="box-header with-border">
									<h3 className="box-title">Add Exam</h3>

									{/*<div className="box-tools pull-right">

                                    </div>*/}
								</div>

								<form id="quickForm" onSubmit={submitHandler} encType="multipart/form-data">
									<div className="box-body">
									
										<div className="form-group row">
											<div className="col-md-6">
												<label htmlFor="title">Title</label>
												<input
													type="text"
													name="title"
													className="form-control"
													id="title"
													placeholder="Enter Title"
													value={title}
													onChange={onChange}
												/>
												{errorData.title ? (
													<span className="text-danger">{errorData.title}</span>
												) : (
													''
												)}
											</div>
											<div className="col-md-6">
												<label htmlFor="logo">Image</label>
												<input
													type="file"
													className="form-control"
													id="logo"
													name="logo"
													onChange={onChange}
													accept={`.jpeg, .jpg, .png`}
												/>
												{errorData.logo ? (
													<span className="text-danger">{errorData.logo}</span>
												) : (
													''
												)}
												<div>
													<img id="img-preview" src="" />
												</div>
											</div>
										</div>

										<div className="form-group row">
											<div className="col-md-6">
												<label htmlFor="maximumSubjects">Maximum Subjects</label>
												<select
													className="form-control"
													name="maximumSubjects"
													value={maximumSubjects}
													onChange={onChange}
												>
													<option value="">Count</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
												</select>
												{errorData.maximumSubjects ? (
													<span className="text-danger">{errorData.maximumSubjects}</span>
												) : (
													''
												)}
											</div>

											<div className="col-md-6">
												<label htmlFor="yearOfExamination">Year Of Examination</label>
												<select
													className="form-control"
													name="yearOfExamination"
													value={yearOfExamination}
													onChange={onChange}
												>
													<option value="">Year</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
												</select>
												{errorData.yearOfExamination ? (
													<span className="text-danger">{errorData.yearOfExamination}</span>
												) : (
													''
												)}
											</div>
										</div>

										<div className="form-group row">
											<div className="col-md-6">
												<label htmlFor="examType">Exam Type</label>
												<select
													className="form-control"
													name="examType"
													value={examType}
													onChange={onChange}
												>
													<option value="">Type</option>
													<option value="objective">Only objective</option>
													<option value="theory">Only theory</option>
													<option value="both">Both theory & Objective</option>
												</select>
												{errorData.examType ? (
													<span className="text-danger">{errorData.examType}</span>
												) : (
													''
												)}
											</div>

											<div className="col-md-3">
												<label htmlFor="timeLimit">Time Limit</label>

												<select
													className="form-control"
													name="timeHour"
													value={timeHour}
													onChange={onChange}
												>
													<option value="">Hour</option>
													{[ ...Array(24) ].map((e, i) => <option value={i}>{i}</option>)}
												</select>
												{errorData.timeHour ? (
													<span className="text-danger">{errorData.timeHour}</span>
												) : (
													''
												)}
											</div>

											<div className="col-md-3">
												<label htmlFor="timeLimit">&nbsp;</label>
												<select
													className="form-control"
													name="time"
													value={time}
													onChange={onChange}
												>
													<option value="">Time</option>
													<option value="00">00</option>
													<option value="15">15</option>
													<option value="30">30</option>
													<option value="45">45</option>
												</select>
												{errorData.time ? (
													<span className="text-danger">{errorData.time}</span>
												) : (
													''
												)}
											</div>
										</div>

										<div className="form-group row">
											<div className="col-md-12">
												<label htmlFor="examInstructions">Exam Instructions</label>
												<Editor
													apiKey="xlap3u1uwwmudeyojnsbav9hlku8wzvyx2pt4t914mshowtz"
													init={{
														height: 200,
														menubar: false,
														external_plugins: {},
														plugins: [
															'advlist autolink lists link image imagetools charmap print preview anchor',
															'searchreplace visualblocks code fullscreen',
															'insertdatetime media table paste code help wordcount'
														],
														toolbar:
															'undo redo | link image | formatselect ' +
															'bold italic backcolor | alignleft aligncenter alignright alignjustify | MathType' +
															'bullist numlist outdent indent | ' +
															'removeformat | code | help',
														content_style:
															'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
														file_picker_types: 'image'
													}}
													value={examInstructions}
													// initialValue={question}
													onEditorChange={(newText) => changeEditorText(newText)}
												/>
												{errorData.examInstructions ? (
													<span className="text-danger">{errorData.examInstructions}</span>
												) : (
													''
												)}
											</div>
										</div>
										<div className="form-group row">
											<div className="col-md-6">
												<label htmlFor="status">Status</label>
												<select
													className="form-control"
													name="status"
													value={status}
													onChange={onChange}
												>
													<option value="">Select Status</option>
													<option value="Active">Active</option>
													<option value="InActive">In Active</option>
												</select>
											</div>
										</div>
									</div>
									<div className="box-footer">
										<button type="submit" className="btn btn-primary">
											Submit
										</button>
									</div>
								</form>
							</div>
						</section>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default AddExam;
