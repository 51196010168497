import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import Loader from "../Loader";

import { getResultData } from "./../../actions/resultActions";

import "./result.css";
import TypingAnimation from "./TypingAnimation";

const TheoriesResults = ({ history, match }) => {
  const adviceRef = useRef(null);
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.auth);
  const { rLoading, result } = useSelector((state) => state.results);
  const quizId = match.params.quizId;
  useEffect(() => {
    dispatch(getResultData(quizId));
  }, [dispatch, quizId, alert]);

  const {
    progress,
    totalQuestions,
    attendQuestion,
    accuracy,
    speed,
    advice,
    adviceDescription,
  } = useMemo(() => {
    if (result?.quiz) {
      const totalQuestions = result.quiz.questionCount;
      const attendQuestion = result.quiz.questionCount - result.quiz.unseenCount;
      const progress = ((attendQuestion * 100) / totalQuestions).toFixed(1);
      const accuracy = result.totalAvgGrade;
      const speed = result.quiz.usedtime;

      let advice = "";
      let adviceDescription = [];

      if (result.advices) {
        result.advices.some((item) => {
          if (accuracy >= item.min_percentage && accuracy <= item.max_percentage) {
            advice = item.title;
            adviceDescription = item.description;
            return true;
          }
          return false;
        });
      }

      return {
        progress,
        totalQuestions,
        attendQuestion,
        accuracy,
        speed,
        advice,
        adviceDescription,
      };
    }
    return {
      progress: 0,
      totalQuestions: 0,
      attendQuestion: 0,
      accuracy: 0,
      speed: 0,
      advice: "",
      adviceDescription: [],
    };
  }, [result]);

  useEffect(() => {
    const adjustFontSize = () => {
      const adviceElement = adviceRef.current;
      if (adviceElement) {
        let fontSize = 1.8; // Initial font size in vw
        adviceElement.style.fontSize = `${fontSize}vw`;

        while (adviceElement.scrollWidth > adviceElement.clientWidth && fontSize > 0.5) {
          fontSize -= 0.2;
          adviceElement.style.fontSize = `${fontSize}vw`;
        }
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, [advice]);


  if (loading || rLoading) return <Loader />;

  return (
    <Fragment>
      {result?.quiz && (
        <div className="right-auth-landing edit-pros">
          <div className="main-view-content">
            <div className="all-ourt-style w-80-cust clearfix">
              <div className="over-all-results-graph">
                <h3 className="over-al-head">Results</h3>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="graph-box">
                      <h3>Progress <span>{progress}% Completed</span></h3>
                      <div className="c100 p40 blue">
                        <span>{progress}%</span>
                        <div className="slice">
                          <div className="bar"></div>
                          <div className="fill"></div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr><th scope="row">Total Questions</th><td className="text-right">{totalQuestions}</td></tr>
                            <tr><th scope="row">Attend Questions</th><td className="text-right">{attendQuestion}</td></tr>
                            <tr><th scope="row">Skipped Questions</th><td className="text-right">{result.quiz.unseenCount}</td></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="graph-box">
                      <h3>Accuracy <span>{accuracy}% Completed</span></h3>
                      <div className="c100 p70 green">
                        <span>{accuracy}%</span>
                        <div className="slice">
                          <div className="bar"></div>
                          <div className="fill"></div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr><th scope="row">Total Questions</th><td className="text-right">{totalQuestions}</td></tr>
                            <tr><th scope="row">Attend Questions</th><td className="text-right">{attendQuestion}</td></tr>
                            <tr><th scope="row">Skipped Questions</th><td className="text-right">{result.quiz.unseenCount}</td></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="graph-box">
                      <h3>Speed <span>{speed}</span></h3>
                      <div className="c100 p85 orange">
                        <span>{speed}</span>
                        <div className="slice">
                          <div className="bar"></div>
                          <div className="fill"></div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr><th scope="row">Total Questions</th><td className="text-right">{totalQuestions}</td></tr>
                            <tr><th scope="row">Attend Questions</th><td className="text-right">{attendQuestion}</td></tr>
                            <tr><th scope="row">Skipped Questions</th><td className="text-right">{result.quiz.unseenCount}</td></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="all-ourt-style w-80-cust clearfix">
              <h3 className="over-al-head">Result <span>Completed</span></h3>
              <div className="row">
                <div className="col-sm-4">
                  <div className="result-boxers">
                    <div className="box-head">
                      <h1>Percentage</h1>
                    </div>
                    <div className="c100 p100 green">
                      <span>{accuracy}%</span>
                      <div className="slice">
                        <div className="bar"></div>
                        <div className="fill"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="result-boxers">
                    <div className="box-head">
                      <h1>Advice : </h1>
                      <h1><span>{advice}</span></h1>
                    </div>
                    <div className="c100 p100 pink text-20">
                      <span ref={adviceRef}>{advice}</span>
                      <div className="slice">
                        <div className="bar"></div>
                        <div className="fill"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="result-boxers">
                    <div className="box-head">
                      <h1></h1>
                    </div>
                    <TypingAnimation text={adviceDescription} typingSpeed={20} />
                  </div>
                </div>
              </div>
            </div>
            <div className="all-ourt-style w-80-cust next-prev-cust clearfix">
              <div className="pull-left">
                <Link to="/results" className="btn view-ans-btn">Back</Link>
              </div>
              <div className="pull-right">
                <Link to={{
                  pathname: `/theories-results-view/${result.quiz._id}`,
                  search: `?assessmentTitle=${encodeURIComponent(result.quiz.assessment.title)}`,
                }}
                  className="btn view-ans-btn">
                  View Corrections
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TheoriesResults;
