import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getResultMockAnalyticsData } from '../../actions/resultActions';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from "react-chartjs-2";
const MockScoreAnalytics = () => {
    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dispatch = useDispatch();
    const [labels, setLabels] = useState();
    const [quizzes, setQuizzes] = useState();
    const { rLoading, result } = useSelector((state) => state.results);
    const { user, loading, error } = useSelector((state) => state.auth);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );
    
      const options = {
        scales: {
            y: {
              beginAtZero: true,
              max: 400,
            },
          },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            font: {
                size: 20,
              },
            display: true,
            text: 'JAMB UTME MOCK PERFORMANCE',
          },
        },
      };

    useEffect(() => {
        if (user) {
            dispatch(getResultMockAnalyticsData(user?._id));
        }
    }, [dispatch]);

    useEffect(() => {
        if (result && result.quizzes?.length > 0) {
            setLabels([])
            setQuizzes([])
            let tmpArr = []
            let tmpOptionsArr = []
            result.quizzes.map((q) => {
                let date = new Date(q.createdAt);
                let hours = parseInt(date.getHours());
                if (hours != 12) {
                hours = hours % 12;
                }
                let dateString = date.getDate() + " " + month[date.getMonth()];
                let accuracyPercentage = ((q.correctCount / q.questionCount) * 100).toFixed(1)
                let score = (parseFloat(accuracyPercentage) * 400 ) / 100
                tmpArr.push(score)
                let timeString =
                hours + ":" + date.getMinutes() + ":" + date.getSeconds();
                let twelve_hours = date.getHours();
                let meridium = twelve_hours >= 12 ? "PM" : "AM";
                tmpOptionsArr.push(dateString + " " +  timeString + +" "+ meridium)
            })
            setLabels(tmpOptionsArr)
            setQuizzes(tmpArr)
        }
    }, [result])
  
    const jambMockData = {
    labels,
    datasets: [
      {
        label: '',
        data: quizzes?.map((item, index) => item),
        borderColor: 'rgb(20, 223, 125)',
        backgroundColor: 'rgb(20, 223, 125)',
      }
    ],
  };
  return (
    // <div className="right-auth-landing">
        <div className="main-view-content">
      <Line data={jambMockData} options={options}/>
        </div>
    // </div>
  )
}

export default MockScoreAnalytics
