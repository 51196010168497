import axios from 'axios';
import {
	GET_EXAM_INSTRUCTION_REQUEST,
	GET_EXAM_INSTRUCTION_SUCCESS,
	GET_EXAM_INSTRUCTION_FAIL,
	MOCK_EXAM_QUESTIONS_REQUEST,
	MOCK_EXAM_QUESTIONS_SUCCESS,
	MOCK_EXAM_QUESTIONS_FAIL,
	CLEAR_ERRORS
} from './../constants/mockConstants';

const _api_path = '/api/v1';

//get all advice
export const getExamInstruction = (id) => async (dispatch) => {
	try {
		dispatch({
			type: GET_EXAM_INSTRUCTION_REQUEST
		});

		const { data } = await axios.get(`${_api_path}/get-exam-instruction/${id}`);
		// console.log("data", data);
		// return;
		dispatch({
			type: GET_EXAM_INSTRUCTION_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: GET_EXAM_INSTRUCTION_FAIL,
			payload: error
		});
	}
};

//get mock exam questions
export const getMockExamQuestions = (examId, examType, subjects, university, questionCount = null, subscriptionId = null) => async (dispatch) => {
	try {
		dispatch({
			type: MOCK_EXAM_QUESTIONS_REQUEST
		});

		const { data } = await axios.post(`${_api_path}/get-mock-questions`, { examId, examType, subjects, university, questionCount, subscriptionId });
		// console.log("data", data);
		// return;
		dispatch({
			type: MOCK_EXAM_QUESTIONS_SUCCESS,
			payload: data,
			questionCount: data.totalQuestions,
			currentExam: data.exam
		});
	} catch (error) {
		dispatch({
			type: MOCK_EXAM_QUESTIONS_FAIL,
			payload: error
		});
	}
};
