import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useAlert } from "react-alert";

const _api_path = "/api/v1";

const ActivateAccount = ({ match, history }) => {
  const alert = useAlert();

  const [activated, setActivated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMounted, setIsMounted] = useState(true);

  const userId = match.params.id;

  useEffect(() => {
    let isCancelled = false;

    const activateAccount = async () => {
      try {
        await axios.get(_api_path + `/activate-account/${userId}`);
        if (!isCancelled && isMounted) {
          setActivated(true);
          setLoading(false);
        }
      } catch (err) {
        if (!isCancelled && isMounted) {
          setError(err.message || "An error occurred while activating your account.");
          setLoading(false);
        }
      }
    };

    activateAccount();

    return () => {
      isCancelled = true;
      setIsMounted(false);
    };
  }, [userId, isMounted]);

  useEffect(() => {
    if (activated) {
      alert.success("Account activated successfully");
      history.push(`/login`);
    }
  }, [activated, alert, history]);

  return (
    <Fragment>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <h1>Account Activation</h1>
        </div>
      </section>

      <section className="news-page-sec">
        <div className="container">
          {loading && <div className="text-center">Please wait, your account is being activated...</div>}
          {error && <div className="text-center text-danger">{error}</div>}
        </div>
      </section>
    </Fragment>
  );
};

export default ActivateAccount;
