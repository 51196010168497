import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAlert } from "react-alert";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useDispatch, useSelector } from "react-redux";
import {
    getTestimonials,
    deleteTestimonialData,
} from "../../../actions/adminTestimonialActions";

import Loader from "../../Loader";

const ListTestimonials = ({ history }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTestimonials());
    }, [dispatch]);

    const handleDeleteTestimonial = (id) => {
        confirmAlert({
            title: "Confirm to submit",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        dispatch(deleteTestimonialData(id));
                        // history.push(`/admin/testimonial-list`);
                        alert.success("Testimonial deleted successfully");
                        dispatch(getTestimonials());
                    },
                },
                {
                    label: "No",
                    onClick: () => alert.success("No data deleted"),
                },
            ],
        });
    };

    const { testimonials, aloading } = useSelector((state) => state.testimonialList);
    
    return (
        <Fragment>
            {aloading ? (
                <Loader />
            ) : (
                <Fragment>
                    <div className="content-wrapper">
                        <section className="content-header">
                            <h1>
                                Manage
                                <small>Testimonials</small>
                            </h1>
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/admin">
                                        <i className="fa fa-dashboard"></i> Dashboard
                                    </Link>
                                </li>
                                <li className="active">Testimonials</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="box">
                                        <div className="box-header">
                                            <h3 className="box-title">Testimonial</h3>
                                            <div className="box-tools">
                                                <Link
                                                    to={"/admin/testimonials/add"}
                                                    className="btn btn-success btn-sm"
                                                >
                                                    + Add Testimonial
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="box-body table-responsive no-padding">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Image</th>
                                                    <th>Content</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {testimonials !== undefined && testimonials.length ? (
                                                    testimonials.map((item) => (
                                                        <tr key={item._id} style={{height: "130px"}}>
                                                            <td>{item.name}</td>
                                                            <td>
                                                                <img
                                                                    style={{ width: "150px", height: "105px" }}
                                                                    src={
                                                                        process.env.REACT_APP_PUBLIC_URL +
                                                                        "/assets/img/testimonial/" +
                                                                        item.image
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <div style={{ width: "450px" }}>
                                                                    {item.description}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {item.status === true && (
                                                                    <span className="badge bg-green">
                                      Active
                                    </span>
                                                                )}
                                                                {item.status === false && (
                                                                    <span className="badge bg-yellow">
                                      InActive
                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    to={`/admin/testimonials/${item._id}/edit`}
                                                                    className="btn btn-success btn-sm"
                                                                    title="Edit"
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                </Link>
                                                                &nbsp;
                                                                <a
                                                                    className="btn btn-danger btn-sm"
                                                                    title="Delete"
                                                                    onClick={() => handleDeleteTestimonial(item._id)}
                                                                    key={item._id}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4">No records found</td>
                                                    </tr>
                                                )}
                                                </tbody>
                                                <tfoot></tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default ListTestimonials;
