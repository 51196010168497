import React, { Fragment } from "react";

const ExamLayout = (props) => {
  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
};

export default ExamLayout;
