import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getAllExamTypes } from "./../../actions/commonActions";
import Loader from "../Loader";

const ExamType = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [examTypeId, setExamTypeId] = useState("");
  const [examType, setExamType] = useState("");
  const [filteredExams, setFilteredExams] = useState([]);

  const { eloading, allExamTypes } = useSelector((state) => state.commonExams);

  const examTypes = [
    {
      id: 1,
      title: "Objective",
      uniqueTitle: "objective",
      logo: "multichoice-icon.png",
    },
    {
      id: 2,
      title: "Theory",
      uniqueTitle: "theory",
      logo: "theory-icon.png",
    },
  ];

  // Load initial values from localStorage and fetch exam types
  useEffect(() => {
    dispatch(getAllExamTypes());
    const storedExamType = localStorage.getItem("examType");
    const storedExamTypeId = localStorage.getItem("examId");

    if (storedExamType) setExamType(storedExamType);
    if (storedExamTypeId) setExamTypeId(storedExamTypeId);
  }, [dispatch]);

  // Filter exam types based on available data
  useEffect(() => {
    if (allExamTypes?.length > 0) {
      const examId = localStorage.getItem("examId");
      const selectedExam = allExamTypes.find((item) => item?._id === examId);
      const stringToFilter = selectedExam?.examType;

      const filtered = examTypes.filter(
        (exam) => stringToFilter === "both" || exam?.uniqueTitle === stringToFilter
      );
      setFilteredExams(filtered);
    }
  }, [allExamTypes]);

  const onChange = (e) => {
    setExamTypeId(e.target.value);
    setExamType(e.target.dataset.name);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (examType) {
      localStorage.setItem("examType", examType);
      history.push(`/mode-of-assessment`);
    } else {
      alert.error("Please select an exam type to proceed.");
    }
  };

  return (
    <Fragment>
      <section
        className="page-banner-sec text-center"
        style={{
          backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
        }}
      >
        <div className="container">
          <ul className="clearfix paginations-cust">
            <li>
              <a href="#!">Home</a>
            </li>
            <li>
              <a href="/exams">Exams</a>
            </li>
          </ul>
          <h1>Choose Exam Type</h1>
        </div>
      </section>

      <section className="subscription-page">
        <div className="container">
          <div className="end-plan">
            <h1>Choose Exam Type</h1>
          </div>
          <div className="custom-radios-plan examtype-spaner">
            {eloading ? (
              <Loader />
            ) : (
              <div className="wrapper clearfix">
                {filteredExams.length > 0 ? (
                  filteredExams.map((item, key) => (
                    <Fragment key={item.id}>
                      <input
                        type="radio"
                        name="select"
                        id={`option-${item.id}`}
                        value={item.id}
                        data-name={item.uniqueTitle}
                        checked={examTypeId === String(item.id)}
                        onChange={onChange}
                      />
                      <label
                        htmlFor={`option-${item.id}`}
                        className={`option option-${key + 1}`}
                      >
                        <div className="dot"></div>
                        <div className="extype">
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/${item.logo}`}
                            alt="exam-logo"
                          />
                        </div>
                        <span>{item.title}</span>
                      </label>
                    </Fragment>
                  ))
                ) : (
                  <p>No exam types available.</p>
                )}
              </div>
            )}
          </div>
          <div className="next-prev-steps text-center">
            <button
              type="submit"
              className="chose-more"
              onClick={submitHandler}
            >
              Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ExamType;
