import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { PaystackConsumer } from 'react-paystack';
import { getExamSubscriptions, userSubscribePlan } from "../../actions/subscriptionActions";
import Loader from "../Loader";
import axios from "axios";

const PlanSubscriptions = ({ history, match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const [subscriptionType, setSubscriptionType] = useState("");
    const [subscriptionPrice, setSubscriptionPrice] = useState("");
    const [userSubscription, setUserSubscription] = useState(null);

    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const examId = localStorage.getItem("examId");
    const universityId = localStorage.getItem("universityId");
    const userId = user._id;

    const fetchSubscription = async () => {
        try {
            let response;
            if (universityId) {
                response = await axios.get(`/api/v1/get-user-subscriptions/${userId}/${examId}/${universityId}`);
            } else {
                response = await axios.get(`/api/v1/get-user-subscriptions/${userId}/${examId}`);
            }
            setUserSubscription(response.data?.userSubscription);
        } catch (error) {
            console.error("Error fetching user subscription:", error);
            alert.error("Failed to fetch user subscription.");
        }
    };

    useEffect(() => {
        if (examId && userId) {
            dispatch(getExamSubscriptions(examId, userId));
            fetchSubscription();
        }
    }, [dispatch, examId, userId, universityId]);

    const { examSubscriptions, isPlanSubscribed, sloading } = useSelector(
        (state) => state.examSubscriptions
    );

    useEffect(() => {
        if (isPlanSubscribed) {
            alert.success("Subscription plan subscribed successfully.");
            history.push(`/my-subscriptions`);
        }
    }, [isPlanSubscribed, alert, history]);

    const setSubscriptionTypeHandle = (e) => {
        const price = e.target.dataset.price;
        setSubscriptionType(e.target.value);
        setSubscriptionPrice(price);
    };

    const validateSubscription = () => {
        if (!subscriptionType) {
            alert.error("Please choose a subscription plan.");
            return false;
        }

        if (userSubscription && userSubscription.price > subscriptionPrice) {
            alert.error("You have an existing higher subscription plan.");
            return false;
        }
        return true;
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (validateSubscription()) {
            const refId = Math.floor(Math.random() * 1000000);
            const paymentData = {
                currency: 'NGR',
                paymentMethod: 'Paystack',
                paymentMessage: "Successful",
                refrenceId: refId,
                paymentTransaction: Math.floor(Math.random() * 100000),
                trxref: refId,
                paymentStatus: "success"
            };
            dispatch(userSubscribePlan(examId, universityId, subscriptionType, user._id, paymentData));
        }
    };

    //'pk_test_c5f316c291b070ad3b3873412bbf76fc6bb57e29'
    const config = {
        reference: (new Date()).getTime(),
        email: user.email,
        amount: subscriptionPrice * 100,
        publicKey: 'pk_test_c5f316c291b070ad3b3873412bbf76fc6bb57e29',
        // publicKey: 'pk_live_81018c0ededa75b06a011c2d65bccf4891630d40',
    };

    const handleSuccess = (reference) => {
        if (reference.message === 'Approved') {
            const paymentData = {
                currency: 'NGR',
                paymentMethod: 'Paystack',
                paymentMessage: reference.message,
                refrenceId: reference.reference,
                paymentTransaction: reference.transaction,
                trxref: reference.trxref,
                paymentStatus: reference.status
            };
            dispatch(userSubscribePlan(examId, universityId, subscriptionType, user._id, paymentData));
        } else {
            alert.error("Payment couldn't be completed (" + reference.message + ").");
        }
    };

    const handleClose = () => {
        alert.error("You have cancelled the payment.");
    };

    const componentProps = {
        ...config,
        text: 'Paystack Button Implementation',
        onSuccess: handleSuccess,
        onClose: handleClose
    };

    const handlePaystack = (initializePayment) => {
        if (validateSubscription()) {
            initializePayment(handleSuccess, handleClose);
        }
    };

    return (
        <Fragment>
            <section
                className="page-banner-sec text-center"
                style={{
                    backgroundImage: `url("../assets/frontend/img/about-banner.jpg")`,
                }}
            >
                <div className="container">
                    <ul className="clearfix paginations-cust">
                        <li>
                            <a href="#!">Home</a>
                        </li>
                        <li>
                            <a href="#!">Subscription</a>
                        </li>
                    </ul>
                    <h1>Subscription Plan</h1>
                </div>
            </section>

            <section className="subscription-page">
                <div className="container">
                    <div className="end-plan">
                        <h1>Your Trial Period Completed</h1>
                    </div>
                    <div className="custom-radios-plan">
                        {sloading ? (
                            <Loader />
                        ) : (
                            <div className="wrapper clearfix">
                                {examSubscriptions && examSubscriptions.length > 0
                                    ? examSubscriptions.map((item, key) => {
                                        const indexId = key + 1;
                                        return (
                                            <Fragment key={indexId}>
                                                <input
                                                    type="radio"
                                                    name="select"
                                                    id={`option-${indexId}`}
                                                    value={item._id}
                                                    data-price={item.price}
                                                    onClick={setSubscriptionTypeHandle}
                                                />
                                                <label
                                                    htmlFor={`option-${indexId}`}
                                                    className={`option option-${indexId}`}
                                                >
                                                    <div className="dot"></div>
                                                    <span>
                                                        {item.title} {item.subscriptionType}{" "}
                                                        <b>{item.description}</b>
                                                    </span>
                                                    <div className="chose-more">
                                                        ₦ {item.price}
                                                    </div>
                                                </label>
                                            </Fragment>
                                        );
                                    })
                                    : <p>No subscription plans available.</p>}
                            </div>
                        )}
                    </div>
                    <div className="next-prev-steps text-center">
                        <Link to="/my-subscriptions" className="chose-more">
                            <i className="fas fa-arrow-left"></i> Prev
                        </Link>
                        {!subscriptionType ? (
                            <button
                                type="submit"
                                className="chose-more"
                                onClick={validateSubscription}
                            >
                                Next <i className="fas fa-arrow-right"></i>
                            </button>
                        ) : parseInt(subscriptionPrice) === 0 ? (
                            <button
                                type="submit"
                                className="chose-more"
                                onClick={submitHandler}
                            >
                                Next <i className="fas fa-arrow-right"></i>
                            </button>
                        ) : (
                            <PaystackConsumer {...componentProps}>
                                {({ initializePayment }) => (
                                    <button
                                        className="chose-more"
                                        onClick={() => handlePaystack(initializePayment)}
                                    >
                                        Next <i className="fas fa-arrow-right"></i>
                                    </button>
                                )}
                            </PaystackConsumer>
                        )}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default PlanSubscriptions;
